import { Color } from "./color";

export class Utils {

    private static colorPrimaryAge3 = new Color(247, 163, 213);
    private static colorPrimaryAge4 = new Color(255, 182, 18);
    private static colorPrimaryAge5 = new Color(99, 206, 202);
    private static colorPrimaryDarkAge3 = new Color(215, 157, 191);
    private static colorPrimaryDarkAge4 = new Color(231, 159, 70);
    private static colorPrimaryDarkAge5 = new Color(104, 178, 184);
    private static colorPrimaryLightAge3 = new Color(250, 210, 226);
    private static colorPrimaryLightAge4 = new Color(255, 255, 255);
    private static colorPrimaryLightAge5 = new Color(255, 255, 255);
    private static colorTitlesAge3 = new Color(0,135,124);
    private static colorTitlesAge4 = new Color(128,55,155);
    private static colorTitlesAge5 = new Color(222,56,49);

    static colorPrimaryByAge:Color[] = [Utils.colorPrimaryAge3, Utils.colorPrimaryAge4, Utils.colorPrimaryAge5];
    static colorPrimaryDarkByAge:Color[] = [Utils.colorPrimaryDarkAge3, Utils.colorPrimaryDarkAge4, Utils.colorPrimaryDarkAge5];
    static colorPrimaryLightByAge:Color[] = [Utils.colorPrimaryLightAge3, Utils.colorPrimaryLightAge4, Utils.colorPrimaryLightAge5];
    static colorTitleByAge:Color[] = [Utils.colorTitlesAge3, Utils.colorTitlesAge4, Utils.colorTitlesAge5];

    // public static getPrimaryColorForAge(age:number):Color
    // {   
    //     if(age < 3 || age > 5) return new Color(0, 0, 0);

    //     return Utils.colorPrimaryByAge[age % 3];
    // }

    // public static getDarkColorForAge(age:number):Color
    // {   
    //     if(age < 3 || age > 5) return new Color(0, 0, 0);

    //     return Utils.colorPrimaryDarkByAge[age % 3];
    // }

    /** Funcion que obtiene un aleatorio entre 0 y el numero pasado por parametro */
    public static getRandom(max: number):number{

        let random = Math.floor(Math.random() * max);
        //console.log('Random entre 0 y ' + max);
        //console.log('Random: ' + random);
        return random;
    }

    public static getRandomInt(min: number, max: number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /** Funcion que baraja un array */
    public static shuffleArray(array:any):any {
        var currentIndex = array.length, temporaryValue, randomIndex;
        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;
          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
        return array;
    }
}
