<div class="barContainer" [style.background-color]="getBackgroundColorForTemplate()">
    <div class="leftContainer"><!--Este div es para poner un fondo blanco con transparencia a este container-->
        <div class="btnAtrasContainer">
            <app-btn-atras [urlToNavigate]="getPreviousUrlForTemplate()"
            (eventBtnAtrasClicked) = "onBtnAtrasClicked()">
            </app-btn-atras>
        </div>
        <div class="trimestreContainer">
            <app-trimestre-banner 
                [idTrimestre]="getTrimestreForTemplate()"
                (onClickHomeButton)="onBtnHomeClicked()">
            </app-trimestre-banner>
        </div>
    </div>
    <div>
        <app-nav-bar
            [buttonsToShow]="getNavBarButtonsToShow()"
            (onBtnNavBarClicked)="onBtnNavBarClicked($event)"></app-nav-bar>
    </div>
    <div class="btnLogin">
        <app-btn-login></app-btn-login>
    </div>
</div>
