
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStateService } from '../../../shared/services/app-state.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { TTrimestre } from '../../../shared/classes/t-trimestre';
import { BtnTematicoThumbInfo } from '../../models/btn-tematico-thumb-info';
import { Utils } from '../../../shared/classes/utils';

const kPathToAssets = "./assets/cuadernos_tematicos/miniaturas/";

@Component({
  selector: 'app-btn-tematico-thumb',
  templateUrl: './btn-tematico-thumb.component.html',
  styleUrls: ['./btn-tematico-thumb.component.css']
})
export class BtnTematicoThumbComponent implements OnInit {

  @Input() info:BtnTematicoThumbInfo = new BtnTematicoThumbInfo();
  @Output() clickEvent = new EventEmitter<BtnTematicoThumbInfo>();

  showAsFavorito = false;

  private scaleForTemplate = 0;
  
  constructor(private appState:AppStateService, private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {

    this.showAsFavorito = this.info.favorite;
  }

  getBackgroundImage():string
  {

    let pathToThumbImage:string = kPathToAssets + this.info.urlThumb;
    return "url('" + pathToThumbImage + "')";
  }

  getBorderColor():string
  {

    return this.appState.getPrimaryColorForCurrentAge().rgbString;
  }

  onBtnClicked(){

    this.soundPlayer.playSoundClick();

    this.clickEvent.emit(this.info);
  }

  //#region template
  getFichaURL():string
  {
    return "/visor_tematicos/" + this.info.id;
  }

  getTransformForTemplate():string
  {
    return "scale(" + this.scaleForTemplate + ")";
  }

  getOpacityForTemplate(){
    return (this.info.enabled)? 1:0.3;
  }

  //#endregion

  //#region public methods
  public showThumb(){
    this.scaleForTemplate = 1;
  }
  //#endregion

}
