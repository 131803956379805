import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStateService } from '../../../shared/services/app-state.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { CuentoInfo } from '../../models/cuento-info';


@Component({
  selector: 'app-btn-cuento-thumb',
  templateUrl: './btn-cuento-thumb.component.html',
  styleUrls: ['./btn-cuento-thumb.component.css']
})
export class BtnCuentoThumbComponent implements OnInit {

  @Input() info!:CuentoInfo;
  @Output() eventOnClick = new EventEmitter<CuentoInfo>();

  private scaleForTemplate = 0;

  constructor(private soundPlayer:SoundPlayerService, private appState:AppStateService) { }

  ngOnInit(): void {
  }

  onBtnClicked(){

    this.soundPlayer.playSoundClick();

    this.eventOnClick.emit(this.info);
  }

  //#region public methods
  public showThumb(){
    this.scaleForTemplate = 1;
  }
  //#endregion

  //#region template
  getBackgroundImage():string{
    
    return "url('" + this.info.urlThumb + "')";
  }

  getBorderColor():string{
    //return this.appState.getPrimaryColorForCurrentAge().rgbString;
    return "rgb(128,55,155)";
  }

  getTransformForTemplate():string{
    return "scale(" + this.scaleForTemplate + ")";
  }

  getOpacityForTemplate():number{
    return (this.info.enabled)? 1:0.3;
  }
  //#endregion template

}
