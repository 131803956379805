<div class="wrapper">
    <header>
        <app-top-bar
            [style]="getTopBarStyleForTemplate()"
            [navigateBackUrl]="'selector'"
            (clickAtras) = "eventOnClickBack()"
            (clickNavBar) = "eventOnClickNavBar($event)"
            (clickHome) = "eventOnClickHomeButton()">
        </app-top-bar>
    </header>
    <div class="main">
        <div class="leftContainer">
            <!-- Botones de actividades -->
            <ng-container *ngFor="let button of arrayButtonsActivityInfoForTemplate;">
                <app-btn-trimestre-activity
                [info] = "button"
                (clickEvent) = "onBtnTrimestreActivityClicked($event)">
                </app-btn-trimestre-activity>
            </ng-container>
        </div>
        <div class="rightContainer" [style.background-image] = "getBackgroundImage()">
            <!-- Boton de play, fondo y animaciones-->
            <button (click)="onBtnJuegosClicked()" [style.background-image]="getBtnPlayImage()">
                <p>Juegos</p>
            </button>
            
            <div class="personajesContainer">
                <div class="linaContainer">
                    <app-lisa-animada></app-lisa-animada>
                </div>
                <div class="maxContainer">
                    <app-max-animado></app-max-animado>
                </div><div class="teoContainer">
                    <app-teo-animado></app-teo-animado>
                </div>
            </div>
        </div>
    </div>
</div>
<app-flotante-ficha-interactiva></app-flotante-ficha-interactiva>



