import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { Color } from '../../classes/color';
import { TPainterBrushSize } from '../../classes/t-painter-brush-size';
import { SoundPlayerService } from '../../services/sound-player.service';
import { BtnPainterBarColorComponent } from '../btn-painter-bar-color/btn-painter-bar-color.component';

const kColorYellow = new Color(255,222,0);
const kColorRed = new Color(255,51,0);
const kColorBrown = new Color(204,85,34);
const kColorBlue = new Color(35,174,229);
const kColorGreen = new Color(34,176,65);
const kColorBlack = new Color(0,0,0);

@Component({
  selector: 'app-painter-bar',
  templateUrl: './painter-bar.component.html',
  styleUrls: ['./painter-bar.component.css']
})
export class PainterBarComponent implements OnInit {

  @Output() onClickColorItem = new EventEmitter<Color>();
  @Output() onClickBrushSize = new EventEmitter<TPainterBrushSize>();
  @Output() onClickEraser = new EventEmitter();
  @Output() onClickTextTool = new EventEmitter<boolean>();
  @Output() onClickTrash = new EventEmitter();
  @ViewChildren(BtnPainterBarColorComponent) buttonsColorsController!: QueryList<BtnPainterBarColorComponent>;

  arrayColorForTemplate:Color[] = [kColorYellow, kColorRed, kColorBrown, kColorBlue, kColorGreen, kColorBlack];

  private toolTrazoFinoSelected = true;
  private toolTrazoGruesoSelected = false;
  private toolEraserSelected = false;
  private toolTextSelected = false;
  private toolTextOpacity = 1;
  private toolTrashSelected = false;
  private selectedColor!:BtnPainterBarColorComponent;

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.enableBlackColorByDefault();
    }, 50);
  }

  enableBlackColorByDefault(){

    this.buttonsColorsController.forEach(btnColor => {
      if(btnColor.color == kColorBlack){
        btnColor.enable();
        this.selectedColor = btnColor;
      }
    });
  }

  disableSelectedColorButton(){

    this.buttonsColorsController.forEach(btnColor => {
      btnColor.disable();
    });
  }

  //#region  metodos publicos
  public disableTextToolButton(){
    this.toolTextSelected = false;
  }

  //#endregion

  //#region toques
  eventOnClickButtonColor(btnColor:BtnPainterBarColorComponent)
  {
    this.onClickColorItem.emit(btnColor.color);

    //deseleccionamos el anterior boton seleccionado si lo hubiera
    this.buttonsColorsController.forEach(button => {

      this.selectedColor = btnColor;

      this.toolEraserSelected = false;

      if(button.enabled && button!=btnColor){
        button.disable();
      }
    });
  }
 
 
  clickBrushSmall(){

    this.soundPlayer.playSoundClick();
    
    this.toolTrazoFinoSelected = true;
    this.toolTrazoGruesoSelected = false;
    
    this.onClickBrushSize.emit(TPainterBrushSize.SMALL);
  }

  clickBrushBig(){
    
    this.soundPlayer.playSoundClick();

    this.toolTrazoFinoSelected = false;
    this.toolTrazoGruesoSelected = true;
    
    this.onClickBrushSize.emit(TPainterBrushSize.BIG);
  }

  clickEraser(){

    this.soundPlayer.playSoundClick();

    this.toolEraserSelected = true;

    this.disableSelectedColorButton();

    this.onClickEraser.emit();
  }

  clickTextTool(){

    this.soundPlayer.playSoundClick();

    this.toolTextSelected = !this.toolTextSelected;

    this.onClickTextTool.emit(this.toolTextSelected);
  }

  clickTrash(){

    this.soundPlayer.playSoundClick();
    this.onClickTrash.emit();
  }

  downTrash(){
    this.toolTrashSelected = true;
  }

  upTrash(){
    this.toolTrashSelected = false;
  }

  //#endregion

  //#region template
  isSelectedTrazoFino():boolean{
    return this.toolTrazoFinoSelected;
  }

  isSelectedTrazoGrueso():boolean{
    return this.toolTrazoGruesoSelected;
  }

  isSelectedEraser():boolean{
    return this.toolEraserSelected;
  }

  isSelectedTextTool():boolean{
    return this.toolTextSelected
  }

  isDisabledTextTool():boolean{
    return !this.toolTextSelected;
  }

  isSelectedTrash():boolean{
    return this.toolTrashSelected;
  }

  geTextToolOpacity():number{
    return this.toolTextOpacity;
  }

  //#endregion template

}
