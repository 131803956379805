import { Component, ElementRef, HostListener, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GamesBaseViewComponent } from '../../../../shared-games/views/games-base-view/games-base-view.component';
import { SoundPlayerService } from '../../../../shared/services/sound-player.service';
import { GamesManagerService } from '../../../../shared-games/services/games-manager.service';
import { ContainerInfo } from '../../../../shared-games/models/container-info';
import { DraggableInfo } from '../../../../shared-games/models/draggable-info';
import { AppStateService } from '../../../../shared/services/app-state.service';
import { Utils } from '../../../../shared/classes/utils';
import { DraggableComponent } from '../../../../shared-games/components/draggable/draggable.component';
import { ContainerSeriesComponent } from '../../components/container-series/container-series.component';
import { SoundDefinition } from '../../../../shared/classes/sound-definition';
import { PatronComponent } from '../../components/patron/patron.component';
import { Router } from '@angular/router';
import { TGamesId } from '../../../../shared-games/classes/t-games-id';
import { StorageManagerService } from '../../../../shared/services/storage-manager.service';
import { NavigationManagerService } from '../../../../shared/services/navigation-manager.service';
import { HtmlItemInfo } from '../../../../shared/models/html-item-info';

const kTitle = "Series Lógicas";
const kInfo = "Arrastra los elementos para completar la serie.";
const kPathToNarratedInfo = "./assets/locuciones/loc_juego_series.mp3";

//3 Años Primer Trimestre
const kPathToAssets = "./assets/games/series/3_1/";
const kImageNamesAvailablesForSession1 = ["3_1_1.png", "3_1_2.png", "3_1_3.png", "3_1_4.png", "3_1_5.png", "3_1_6.png"];
const kImageNamesAvailablesForSession2 = ["3_2_1.png", "3_2_2.png", "3_2_3.png", "3_2_4.png", "3_2_5.png", "3_2_6.png"];
const kImageNamesAvailablesForSession3 = ["3_3_1.png", "3_3_2.png", "3_3_3.png", "3_3_4.png", "3_3_5.png", "3_3_6.png"];
const kImageNamesBySession:string[][] = [kImageNamesAvailablesForSession1, kImageNamesAvailablesForSession2, kImageNamesAvailablesForSession3];
const kItemsInSeriesByRoundForSession1 = [2,2,2];
const kItemsToSolveByRoundForSession1 = [6,6,6];
const kItemsInSeriesByRoundForSession2 = [2,2,2];
const kItemsToSolveByRoundForSession2 = [6,6,6];
const kItemsInSeriesByRoundForSession3 = [2,2,2];
const kItemsToSolveByRoundForSession3 = [6,6,6];
const kItemInSeriesByRoundAndSession = [kItemsInSeriesByRoundForSession1, kItemsInSeriesByRoundForSession2,kItemsInSeriesByRoundForSession3];
const kitemsToSolveByRoundAndSession = [kItemsToSolveByRoundForSession1, kItemsToSolveByRoundForSession2, kItemsToSolveByRoundForSession3];
const kWidthItems = 142;
const kHeightItems = 142;

@Component({
  selector: 'app-series-view-tres-primer',
  templateUrl: './series-view-tres-primer.component.html',
  styleUrls: ['./series-view-tres-primer.component.css']
})
export class SeriesViewTresPrimerComponent extends GamesBaseViewComponent implements OnInit {

  @ViewChildren(ContainerSeriesComponent) containersControllers!: QueryList<ContainerSeriesComponent>;
  @ViewChildren(DraggableComponent) draggablesControllers!: QueryList<DraggableComponent>;
  @ViewChild(PatronComponent,  {static: true}) patronController!: PatronComponent;
  
  arrayContainersForTemplate:ContainerInfo[] = [];
  arrayDraggablesForTemplate:DraggableInfo[] = [];
  backgroundColorForTemplate:string = "";
  arrayUrlImagesPatron:string[] = [];
  backgroundColorForPatron = "";

  //imagenes disponibles para las distintas rondas del juego
  private arrayImagesAvailable:string[] = [];


  constructor(private soundPlayer:SoundPlayerService, 
    gameManager:GamesManagerService, 
    storageManager:StorageManagerService,
    private appState:AppStateService, 
    protected navigationManager:NavigationManagerService,
    router:Router) { 
    
    super(gameManager, router, storageManager, navigationManager);
  }

  ngOnInit(){

    //info
    this.info = kInfo;
    this.infoNarrationUrl = kPathToNarratedInfo;

    //game id
    this.gameId = TGamesId.SERIES;

    //title
    this.title = kTitle;

    super.ngOnInit();

    //fondo
    this.backgroundColorForTemplate = this.appState.getLightColorForCurrentAge().rgbString;

    //imagenes disponibles para la sesion clonando las imagenes disponibles para todas las rondas de esta sesion
    console.log("Current Session: " + this.gameManager.getCurrentSession());
    this.arrayImagesAvailable =  Object.assign([], Utils.shuffleArray(kImageNamesBySession[this.gameManager.getCurrentSession()-1])); 

    this.setupRound();
  }

  setupRound(){

    //imagenes disponibles para la ronda
    //Sino hay bastantes imagenes disponibles para la serie, reiniciamos el array con todas las imagenes disponibles
    //console.log("Session: " + this.gameManager.getCurrentSession());
    //console.log("Round: " + this.gameManager.getCurrentRound());
    let numItemsForRound = kItemInSeriesByRoundAndSession[this.gameManager.getCurrentSession()-1][this.gameManager.getCurrentRound()-1];
    if(this.arrayImagesAvailable.length <  kItemsInSeriesByRoundForSession1[this.gameManager.getCurrentRound()-1])
    {
      this.arrayImagesAvailable = Utils.shuffleArray(kImageNamesBySession[this.gameManager.getCurrentSession()-1]);
    }

    //generamos containers y draggables
    this.setContainers();
    this.setDraggables();
    
    //imagenes del patron
    this.setPatron();

    //los mostramos
    setTimeout(() => {
      this.showContainers();
      this.showDraggables();
    }, 150);
  }

  clearRound(){
    
    this.hideContainers();
    this.hideDraggables();

    //vaciamos array de imagenes del patron
    this.arrayUrlImagesPatron = [];

    //vaciamos arrays para el template
    setTimeout(() => {
      this.arrayContainersForTemplate.splice(0, this.arrayContainersForTemplate.length);
      this.arrayDraggablesForTemplate.splice(0, this.arrayDraggablesForTemplate.length);
    }, 200);
    
  }

  nextRound(){
    
    super.nextRound();

    if(!this.isGameOver())
    {

      setTimeout(() => {
        this.clearRound();
      }, 500);

      setTimeout(() => {
        this.setupRound();
      }, 1200);
    }
    else{
      this.gameOver();
    }
  }

  roundCompleted(){

    //mostramos estrella
    setTimeout(() => {
      this.showNextStar();
    }, 300);
   

    //preparamos siguiente ronda
    setTimeout(() => {
      this.nextRound();
    }, 1000);
  }

  gameOver(){

    //incrementamos numero de lingotes de este juego
    this.gameManager.arrayLingotesByGameId[TGamesId.SERIES] = this.gameManager.arrayLingotesByGameId[TGamesId.SERIES]+1;
    console.log("Lingotes: " + this.gameManager.arrayLingotesByGameId[TGamesId.SERIES]);
  }
  //#region container y draggables
  setContainers(){

    //indice basado en la ronda actual para acceder a cuantos items hay en la serie
    let indexRound = this.gameManager.getCurrentRound()-1;

    //array con los items de muestra
    let arrayContainersMuestra:ContainerInfo[] = [];
    for (let i = 0; i < kItemsInSeriesByRoundForSession1[indexRound]; i++) {

      let id = "container_" + i;
      let idDraggable = "draggable_" + i;
      let imgUrl = kPathToAssets + this.arrayImagesAvailable[i];
      let containerInfo = new ContainerInfo(id, idDraggable, imgUrl, "", kWidthItems, kHeightItems, 0, true);
      arrayContainersMuestra.push(containerInfo);
      this.arrayContainersForTemplate.push(containerInfo);
    }

    //quitamos las imagenes usadas en los containers para no repetirlas en sucesivas rondas
    this.arrayImagesAvailable.splice(0, arrayContainersMuestra.length);

    //veces que hemos de repetir la muestra, no contamos la muestra
    let itemsToSolve = kItemsToSolveByRoundForSession1[this.gameManager.getCurrentRound()-1];
    itemsToSolve = itemsToSolve - arrayContainersMuestra.length; 
    let repeats = itemsToSolve / arrayContainersMuestra.length;

    //rellenamos el array de containers con los que hay que resolver
    for (let i = 0; i < repeats; i++) {
      for (let j = 0; j < arrayContainersMuestra.length; j++) {
        let currentContainerInfo = arrayContainersMuestra[j];
        let clonedContainerInfo = new ContainerInfo(currentContainerInfo.id, currentContainerInfo.idDraggable, currentContainerInfo.urlImage, currentContainerInfo.urlImageWhenSolved, currentContainerInfo.width, currentContainerInfo.height);
        this.arrayContainersForTemplate.push(clonedContainerInfo);
      }
    }
  }

  setDraggables(){

    //Generamos la info de los draggables accediendo al array de containers y clonando los de la muestra
    let currentRound = this.gameManager.getCurrentRound();
    let itemsInMuestra = kItemsInSeriesByRoundForSession1[currentRound-1];

    for (let i = 0; i < itemsInMuestra; i++) {
      let id = this.arrayContainersForTemplate[i].idDraggable;
      let idContainer = this.arrayContainersForTemplate[i].id;
      let draggableInfo = new DraggableInfo(id, idContainer, this.arrayContainersForTemplate[i].urlImage, this.arrayContainersForTemplate[i].urlImageWhenSolved, kWidthItems, kHeightItems, 0);
      this.arrayDraggablesForTemplate.push(draggableInfo);
    }

    //random del array
    Utils.shuffleArray(this.arrayDraggablesForTemplate);
  }

  setPatron(){
    for (let containerInfo of this.arrayContainersForTemplate) {
      
      this.arrayUrlImagesPatron.push(containerInfo.urlImage);
    }

    //Color de fondo para la cartela
    this.backgroundColorForPatron = this.appState.getLightColorForCurrentAge().rgbString;
  }

  showContainers(){
    
    let delay = 300;
    let counter = 0;
    
    for (let container of this.containersControllers) {
      
      if(container.isSolved())
      {
        setTimeout(() => {
          this.soundPlayer.playSoundFromDefinition(SoundDefinition.FLIPCARD);
          container.showWithScale(0.2);
        }, delay*counter);
      }
      counter++;
    }
  }

  showDraggables(){
    
    for (let draggable of this.draggablesControllers) {
      draggable.showWithScale(0.2);
      setTimeout(() => {
        draggable.setTransition("none");
      }, 200);
      
    }
  }

  hideContainers(){

    for (let container of this.containersControllers) {
     
      container.hideWithScale(0.2);
    }
  }

  hideDraggables(){

    for (let draggable of this.draggablesControllers) {
      draggable.hideWithScale(0.2);
      setTimeout(() => {
        draggable.setTransition("none");
      }, 200);
    }
  }
  //#endregion containers y draggables


  //#region toques
  onClickVerSeries()
  {

    this.soundPlayer.playSoundClick();

    this.patronController.show();
  }

  //#endregion toques

  //#region  control arrastables y containers
  onDraggabledEndDrag(draggable:DraggableComponent){
    
    // console.log("Hemos arrastrado un draggable...");
    let movedToRightContainer = false;
    
    //comprobamos si hemos arrastrado a algun container
    for (let container of this.containersControllers) {
      if(!container.isSolved() && container.checkIntersection(draggable.myDraggable.nativeElement.getBoundingClientRect())){  //hemos dejado el arrastrable en uno de los containers
        
        //miramos si es el container asociado a este draggable
        if(container.info.id == draggable.info.idContainer){
          
          this.soundPlayer.playSoundAcierto();

          movedToRightContainer = true;
          
          this.MovedToRightContainer(container, draggable);

          //comprobamos fin de ronda
          if(this.allContainersCompleted()) //hemos completado la ronda
          {
            
            this.roundCompleted();
          }
          break;
        }
        else{   //movido a container equivocado

          this.soundPlayer.playSoundError();
        }
      }
    }

    if(!movedToRightContainer) draggable.moveToInitPosition();
  } 

  private MovedToRightContainer(container:ContainerSeriesComponent, draggable:DraggableComponent){

    container.setAsSolved();
    container.showWithScale(0.2);
    draggable.moveToInitPosition();
  }

  private allContainersCompleted():boolean{
    
    for (let container of this.containersControllers){
      if(!container.isSolved()) return false;
    }

    return true;
  }

  //#endregion
}

