import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AppStateService } from '../../../shared/services/app-state.service';
import { ContainerInfo } from '../../models/container-info';
import { GameItemComponent } from '../game-item/game-item.component';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css']
})
export class ContainerComponent extends GameItemComponent implements OnInit {

  @Input() info!:ContainerInfo;
  @ViewChild('nativeContainer') myContainer!: ElementRef;
  
  constructor() { 
    super();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.urlImageBackground = this.info.urlImage;
      this.width = this.info.width;
      this.height = this.info.height;
      this.scale = this.info.scale;
      // this.scale = (this.info.solved) ? 1:0;
    }, 50);
  }

  public checkIntersection(boundingRect:any):boolean
  {
    
    var containerBoundingRect = this.myContainer.nativeElement.getBoundingClientRect();

    if(boundingRect.top + boundingRect.height > containerBoundingRect.top
      && boundingRect.left + boundingRect.width > containerBoundingRect.left
      && boundingRect.bottom - boundingRect.height < containerBoundingRect.bottom
      && boundingRect.right - boundingRect.width < containerBoundingRect.right) 
    {
      return true;
    }

    return false;
  }


  public setAsSolved(){
    this.info.solved = true;
  }

  public isSolved(){
    return this.info.solved;
  }
}
