<div class="pageWrapper">
    <header>
        <app-top-bar
            [style]="getTopBarStyleForTemplate()"
            [navigateBackUrl]="'menu'"
            (clickAtras) = "eventOnClickBack()"
            (clickNavBar) = "eventOnClickNavBar($event)"
            (clickHome) = "eventOnClickHomeButton()">
        </app-top-bar>
    </header>
    <div class="titleBar">
        <p>MATERIAL COMPLEMENTARIO</p>
    </div>
    <div class="middleContainer">
        <div class="menuContainer">
            <app-menu-pestanyas
                (onClickBtnPestanya)="eventOnClickBtnPestanya($event)">
            </app-menu-pestanyas>
        </div>
        <div class="contentContainer">
            <app-frame-for-bits></app-frame-for-bits>
            <app-frame-for-cuentos></app-frame-for-cuentos>
        </div>
    </div>
</div>



