import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { SelectorTrimestreComponent } from './views/selector-trimestre/selector-trimestre.component';
import { MenuTrimestreComponent } from './views/menu-trimestre/menu-trimestre.component';
import { TematicosMiniturasComponent } from './modules/cuadernos-tematicos/views/tematicos-minituras/tematicos-minituras.component'
import { TematicosVisorComponent } from './modules/cuadernos-tematicos/views/tematicos-visor/tematicos-visor.component'
import { AdaptacionMiniaturasComponent } from './modules/cuadernos-adaptacion/views/adaptacion-miniaturas/adaptacion-miniaturas.component';
import { AdaptacionVisorComponent } from './modules/cuadernos-adaptacion/views/adaptacion-visor/adaptacion-visor.component';
import { EntrenamientoMiniaturasComponent } from './modules/cuadernos-entrenamiento/views/entrenamiento-miniaturas/entrenamiento-miniaturas.component';
import { EntrenamientoVisorComponent } from './modules/cuadernos-entrenamiento/views/entrenamiento-visor/entrenamiento-visor.component';
import { FrisoComponent } from './modules/friso/views/friso/friso.component';
import { LibroComponent } from './modules/libro/views/libro/libro.component';
import { DistribuidorJuegosComponent } from './modules/juegos/views/distribuidor-juegos/distribuidor-juegos.component';
import { KaraokeComponent } from './modules/karaoke/views/karaoke/karaoke.component'
import { SeriesViewTresPrimerComponent} from './modules/games/series-3-1/views/series-view-tres-primer/series-view-tres-primer.component';
import { MaterialComplementarioViewComponent} from './modules/material-complementario/views/material-complementario-view/material-complementario-view.component';

const routes: Routes = [
  {path: '', redirectTo:'/selector', pathMatch: 'full'},
  {path: 'login', component: LoginComponent},
  {path: 'selector', component: SelectorTrimestreComponent},
  {path: 'menu', component: MenuTrimestreComponent},
  {path: 'menu_tematicos', component: TematicosMiniturasComponent},
  {path: 'visor_tematicos/:id', component: TematicosVisorComponent},
  {path: 'menu_adaptacion', component: AdaptacionMiniaturasComponent},
  {path: 'visor_adaptacion/:id', component: AdaptacionVisorComponent},
  {path: 'menu_entrenamiento', component: EntrenamientoMiniaturasComponent},
  {path: 'visor_entrenamiento/:id', component: EntrenamientoVisorComponent},
  {path: 'friso', component: FrisoComponent},
  {path: 'libro', component: LibroComponent},
  {path: 'karaoke', component: KaraokeComponent},
  {path: 'juegos', component: DistribuidorJuegosComponent},
  {path: 'series', component: SeriesViewTresPrimerComponent},
  {path: 'complementario', component: MaterialComplementarioViewComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
