import { Injectable } from '@angular/core';
import { Color } from '../classes/color';
import { TLanguage } from '../classes/t-language';
import { TTrimestre } from '../classes/t-trimestre';
import { Utils } from '../classes/utils';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  private currentTrimestre:TTrimestre = TTrimestre['3_1'];
  private currentLanguage:TLanguage = TLanguage.ESP;
  private lastUrlNavigated:string = "login";
  private screenScale:number = 1;
  private version="1.3_27/08";

  constructor() {}

  public getLanguage():TLanguage{
    return this.currentLanguage;
  }

  public setLanguage(language:TLanguage){
    this.currentLanguage = language;
  }

  public setTrimestre(trimestre:TTrimestre)
  {
    this.currentTrimestre = trimestre;
  }

  public getTrimestre():TTrimestre{
    return this.currentTrimestre;
  }

  public getAge():number{
    switch(this.currentTrimestre)
    {
      case TTrimestre['3_1']:
      case TTrimestre['3_2']:
      case TTrimestre['3_3']:
        return 3;
      case TTrimestre['4_1']:
      case TTrimestre['4_2']:
      case TTrimestre['4_3']:
        return 4;
      case TTrimestre['5_1']:
      case TTrimestre['5_2']:
      case TTrimestre['5_3']:
        return 5;
    }
  }

  public getNumberOfTrimestre():number{
    switch(this.currentTrimestre)
    {
      case TTrimestre['3_1']:
      case TTrimestre['4_1']:
      case TTrimestre['5_1']:
        return 1;
      case TTrimestre['3_2']:
      case TTrimestre['4_2']:
      case TTrimestre['5_2']:
        return 2;
      case TTrimestre['3_3']:
      case TTrimestre['4_3']:
      case TTrimestre['5_3']:
        return 3;
    }
  }

  public getPrimaryColorForCurrentAge():Color
    {   
        return Utils.colorPrimaryByAge[this.getAge() % 3];
    }

  public getDarkColorForCurrentAge():Color
  {   

      return Utils.colorPrimaryDarkByAge[this.getAge() % 3];
  }

  public getLightColorForCurrentAge():Color
  {   

      return Utils.colorPrimaryLightByAge[this.getAge() % 3];
  }

  public getTitleColorForCurrentAge():Color{
    return Utils.colorTitleByAge[this.getAge() % 3];
  }

  public setLastNavigatedUrl(url:string)
  {
    this.lastUrlNavigated = url;
  }

  public getLastNavigatedUrl():string{
    return this.lastUrlNavigated;
  }

  public getVersion():string{
    return "v" + this.version;
  }
}
