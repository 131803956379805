import { CdkDrag, CdkDragEnd, DragRef, Point } from '@angular/cdk/drag-drop';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import {take} from 'rxjs/operators';

@Component({
  selector: 'app-draggable-text',
  templateUrl: './draggable-text.component.html',
  styleUrls: ['./draggable-text.component.css']
})
export class DraggableTextComponent implements OnInit {

  @ViewChild('autosize') autosize!: CdkTextareaAutosize;
  
  private screenScale:number = 1;
  private resizeObservable$: Observable<Event>;
  private resizeSubscription$: Subscription;
  private visible = false;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable.pipe(take(1))
        .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  constructor(private _ngZone: NgZone) { 
     
    //Observamos el cambio de tamaño de pantalla para saber como se esta escalando el documento.
    //Hace falta para que el arrastre funcione bien con la app escalada.
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      this.setScreenScale();
      //console.log("scale: " + this.screenScale);
    })

    this.setScreenScale();
  }

  private setScreenScale(){

    this.screenScale = (<HTMLElement>document.querySelector('#diaporamaContainer')).getBoundingClientRect().width / (<HTMLElement>document.querySelector('#diaporamaContainer')).offsetWidth;
  }

  ngOnInit(): void {
  }

  /*Ajuste de la posicion al arrastrar teniendo en cuenta que podemos tener la venta escalada */
  dragConstrainPoint = (point:Point, dragRef:DragRef) => {
    
    let zoomMoveXDifference = 0; 
    let zoomMoveYDifference = 0;
    //console.log("freeDragPosition dragRef: " + Math.round(dragRef.getFreeDragPosition().x) + " / " + Math.round(dragRef.getFreeDragPosition().y) + ")");
    
    if (this.screenScale != 1) {
     zoomMoveXDifference = (1 - this.screenScale) * dragRef.getFreeDragPosition().x;
     zoomMoveYDifference = (1 - this.screenScale) * dragRef.getFreeDragPosition().y;
    }
    return {
      x: point.x + zoomMoveXDifference,
      y: point.y + zoomMoveYDifference
    };
  }

  /* Ajuste de posicion cuando soltamos en draggable fuera de un container */
  endDragging(event:CdkDragEnd) {
    const elementMoving = event.source.getRootElement();
    let elementMovingParent = elementMoving.parentElement;
    if(elementMovingParent == null)
    {
      elementMovingParent = elementMoving;
    }
    const elementMovingRect: ClientRect = elementMoving.getBoundingClientRect();
    // let div = document.getElementById("draggableText");
    let xpos =(elementMovingRect.left - elementMovingParent.getBoundingClientRect().left) / this.screenScale;
    elementMoving.style.left = xpos + "px";
    let ypos =(elementMovingRect.top - elementMovingParent.getBoundingClientRect().top) /this.screenScale;
    elementMoving.style.top = ypos + "px";
    const cdkDrag = event.source as CdkDrag;
    cdkDrag.reset();
  }

  //#region metodos publicos
  public show(){
    this.visible = true;
  }

  public hide(){
    this.visible = false;
  }
  //#endregion metodos publicos

  //#region  template
  public canShow():boolean{
    return this.visible;
  }

  //#endregion

}
