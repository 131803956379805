import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { TCuadernosMenuItem } from '../../classes/t-cuadernos-menu-item';
import { BtnCuadernosRecursoItemInfo } from '../../models/btn-cuadernos-recurso-item-info';
import { SoundPlayerService } from '../../services/sound-player.service';
import { CuadernosFichaInfo } from '../../models/cuadernos-ficha-info';
import { TCuadernoFicha } from '../../classes/t-cuaderno-ficha';
import { FichaTematicaInfo } from '../../../cuadernos-tematicos/models/ficha-tematica-info';
import { TButtonStatus } from '../../classes/t-button-status';
import { BtnCuadernosMenuInfo } from '../../models/btn-cuadernos-menu-info';
import { BtnCuadernosMenuComponent } from '../btn-cuadernos-menu/btn-cuadernos-menu.component';
import { FichaEntrenamientoInfo } from '../../../cuadernos-entrenamiento/models/ficha-entrenamiento-info';

@Component({
  selector: 'app-cuadernos-menu-bar',
  templateUrl: './cuadernos-menu-bar.component.html',
  styleUrls: ['./cuadernos-menu-bar.component.css']
})
export class CuadernosMenuBarComponent implements OnInit {

  // @Input() fichaInfo: CuadernosFichaInfo = new CuadernosFichaInfo();
  @Input() fichaType: TCuadernoFicha = TCuadernoFicha.ADAPTACION;
  @Output() clickMenuItem = new EventEmitter<BtnCuadernosMenuInfo>();
  @Output() clickRecursoItem = new EventEmitter<BtnCuadernosRecursoItemInfo>();
  @ViewChildren(BtnCuadernosMenuComponent) menuButtonsControllers!: QueryList<BtnCuadernosMenuComponent>;

  arrayMenuButtons:BtnCuadernosMenuInfo[] = [];

  opacityRecursosDesplegableForTemplate = 0;
  visibilityRecursosDesplegableForTemplate = "hidden";
  disabledInteractiva = false;
  disabledEntrenamiento = false;
  disabledRecursos = false;
  disabledCurricular = false;
  disabledTrazos = false;
  opacityInteractiva = 1;

  private showInteractiva = false;
  private showEntrenamiento = false;
  private showRecursos = false;
  private showCurricular = false;
  private showTrazos = false;
  
  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    //generamos botonera para este tipo de cuaderno
    setTimeout(() => {
      this.generateMenuForCuaderno(this.fichaType);
    }, 10);
    
  }

  private generateMenuForCuaderno(cuaderno:TCuadernoFicha){

    switch(cuaderno){
      case TCuadernoFicha.ADAPTACION:
        this.generateMenuForCuadernoAdaptacion();
        break;
      case TCuadernoFicha.ENTRENAMIENTO:
        this.generateMenuForCuadernoEntrenamiento();
        break;
      case TCuadernoFicha.TEMATICO:
        this.generateMenuForCuadernoTematico();
        break;
      default:
        break;
    }
  }

  private generateMenuForCuadernoTematico(){

    let btnInteractiva = new BtnCuadernosMenuInfo(TCuadernosMenuItem.INTERACTIVA, TButtonStatus.DISABLED);
    let btnEntramiento = new BtnCuadernosMenuInfo(TCuadernosMenuItem.ENTRENAMIENTO, TButtonStatus.DISABLED);
    let btnRecursos = new BtnCuadernosMenuInfo(TCuadernosMenuItem.RECURSOS, TButtonStatus.DISABLED);
    let btnCurricular = new BtnCuadernosMenuInfo(TCuadernosMenuItem.CURRICULAR, TButtonStatus.DISABLED);
    
    this.arrayMenuButtons.push(btnInteractiva, btnEntramiento, btnRecursos, btnCurricular);
  }

  private generateMenuForCuadernoAdaptacion(){

    let btnInteractiva = new BtnCuadernosMenuInfo(TCuadernosMenuItem.INTERACTIVA, TButtonStatus.DISABLED);

    this.arrayMenuButtons.push(btnInteractiva);
  }

  private generateMenuForCuadernoEntrenamiento(){

    let btnTrazo = new BtnCuadernosMenuInfo(TCuadernosMenuItem.TRAZOS, TButtonStatus.ENABLED);

    this.arrayMenuButtons.push(btnTrazo);
  }

  public setMenuForFicha(info:CuadernosFichaInfo)
  {
  
    switch(this.fichaType){
      case TCuadernoFicha.ADAPTACION:
        let adaptacionFicha = info as CuadernosFichaInfo;
        this.setMenuForCuadernosAdaptacion(adaptacionFicha);
        break;
      case TCuadernoFicha.ENTRENAMIENTO:
        let entrenamientoFicha = info as FichaEntrenamientoInfo;
        this.setMenuForCuadernosEntrenamiento(entrenamientoFicha);
        break;
      case TCuadernoFicha.TEMATICO:
        let tematicoInfo  = info as FichaTematicaInfo;
        this.setMenuForCuadernosTematicos(tematicoInfo);
        break;
      default:
        break;
    }
    
  }

  private setMenuForCuadernosTematicos(info:FichaTematicaInfo){
    
    let status = TButtonStatus.ENABLED;
    let urlAsociada = "";
    
    //ficha interactiva
    status = (info.urlFicha != "") ? TButtonStatus.ENABLED : TButtonStatus.DISABLED;
    urlAsociada = info.urlFicha;
    this.udpateMenuButton(TCuadernosMenuItem.INTERACTIVA, status, urlAsociada);
    
    //fichas entrenamiento
    status = (info.urlsCuadernosEntrenamiento.length > 0) ? TButtonStatus.ENABLED : TButtonStatus.DISABLED;
    this.udpateMenuButton(TCuadernosMenuItem.ENTRENAMIENTO, status, "", [], info.urlsCuadernosEntrenamiento);

    //recursos
    status = (info.recursos.length > 0) ? TButtonStatus.ENABLED : TButtonStatus.DISABLED;
    this.udpateMenuButton(TCuadernosMenuItem.RECURSOS, status, "", info.recursos);

    //adaptacion curricular
    status = (info.urlCurricular.length > 0) ? TButtonStatus.ENABLED : TButtonStatus.DISABLED; 
    urlAsociada = info.urlCurricular;
    this.udpateMenuButton(TCuadernosMenuItem.CURRICULAR, status, urlAsociada);
  }

  private setMenuForCuadernosEntrenamiento(info:FichaEntrenamientoInfo){

    //trazos
    let status = (info.urlTrazos != "") ? TButtonStatus.ENABLED : TButtonStatus.DISABLED;
    let urlAsociada = info.urlTrazos;
    this.udpateMenuButton(TCuadernosMenuItem.TRAZOS, status, urlAsociada);
  }

  private setMenuForCuadernosAdaptacion(info:CuadernosFichaInfo){

    //ficha interactiva
    let status = (info.urlFicha != "") ? TButtonStatus.ENABLED : TButtonStatus.DISABLED;
    let urlAsociada = info.urlFicha;
    this.udpateMenuButton(TCuadernosMenuItem.INTERACTIVA, status, urlAsociada);

  }

  private udpateMenuButton(btnType:TCuadernosMenuItem, status:TButtonStatus, url:string = "", recursos:BtnCuadernosRecursoItemInfo[] = [], entrenamiento:BtnCuadernosRecursoItemInfo[] = [], trazos:BtnCuadernosRecursoItemInfo[] = []){

    this.menuButtonsControllers.forEach(menuButton => {
      if(menuButton.info.type == btnType){
        menuButton.setStatus(status);
        if(url != "")
        {
          menuButton.setUrlAsociada(url);
        }
        
        if(recursos.length > 0)
        {
          menuButton.setRecursos(recursos);
        }

        if(entrenamiento.length > 0)
        {
          menuButton.setEntrenamiento(entrenamiento);
        }

        if(trazos.length > 0)
        {
          menuButton.setTrazos(trazos);
        }

        //ocultamos desplegables
        menuButton.hideDesplegables();
      }
    });
  }

  //#region toques
  onClickMenuItem(buttonInfo:BtnCuadernosMenuInfo)
  {
    //console.log("Me he enterado de que nos han pulsado un boton de menu: " + buttonInfo.type);

    //vemos si hay que ocultar desplegables
    this.menuButtonsControllers.forEach(menuButton => {
      
      if(buttonInfo.type != TCuadernosMenuItem.RECURSOS)
      {
        if(menuButton.info.type == TCuadernosMenuItem.RECURSOS){
          menuButton.hideDesplegables();
        }
      }
      if(buttonInfo.type != TCuadernosMenuItem.ENTRENAMIENTO)
      {
        if(menuButton.info.type == TCuadernosMenuItem.ENTRENAMIENTO){
          menuButton.hideDesplegables();
        }
      }
    });

    //emitimos evento
    this.clickMenuItem.emit(buttonInfo);
  }

  onClickMenuItemDesplegable(btnInfo:BtnCuadernosRecursoItemInfo){

    //console.log("Hola soy la barra de menu y me he enterado de que hemos pulsado en un boton de desplegable: " + btnInfo.url);
    //emitimos evento
    this.clickRecursoItem.emit(btnInfo);
  }

  showDesplegableRecursos()
  {
    this.opacityRecursosDesplegableForTemplate = 1;
    this.visibilityRecursosDesplegableForTemplate = "visible";
  }

  hideDesplegableRecursos()
  {
    this.opacityRecursosDesplegableForTemplate = 0;
    this.visibilityRecursosDesplegableForTemplate = "hidden";
  }
  //#endregion

  //#region acceso desde template

  canShowInteractivaOption():boolean{
  
    return this.showInteractiva;
  }

  canShowEntrenamientoOption():boolean{


    return this.showEntrenamiento;

    // switch(this.fichaType){
    //   case TCuadernoFicha.ADAPTACION:
    //     return false;
    //   case TCuadernoFicha.ENTRENAMIENTO:
    //     return false;
    //   case TCuadernoFicha.TEMATICO:
    //     return true;
    //   default:
    //     return false;
    // }
  }

  canShowRecursosOption():boolean{
    
    return this.showRecursos;
    // switch(this.fichaType){
    //   case TCuadernoFicha.ADAPTACION:
    //     return false;
    //   case TCuadernoFicha.ENTRENAMIENTO:
    //     return false;
    //   case TCuadernoFicha.TEMATICO:
    //     return true;
    //   default:
    //     return false;
    // }
  }

  canShowCurricularOption():boolean{
    
    return this.showCurricular;
    // switch(this.fichaType){
    //   case TCuadernoFicha.ADAPTACION:
    //     return false;
    //   case TCuadernoFicha.ENTRENAMIENTO:
    //     return false;
    //   case TCuadernoFicha.TEMATICO:
    //     return true;
    //   default:
    //     return false;
    // }
  }

  canShowTrazosOption():boolean{
    return this.showTrazos;
  }

  getRecursos():BtnCuadernosRecursoItemInfo[]{
    
    // if(this.fichaType == TCuadernoFicha.TEMATICO){
    //   let tematicoInfo  = this.info as FichaTematicaInfo;
    //   return tematicoInfo.recursos;
    // }

    return [];
  }

  //#endregion

}
