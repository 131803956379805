<div class="pageWrapper">
    <div class="topContainer">
        <div class="containerTitle">
            <div class="title"></div>
        </div>
        <div class="containerAnillo">
            <div class="containerImageAnillo">
                <div class="anillo"></div>
            </div>
            
        </div>
    </div>
    <div class="bottomContainer">
        <div class="personajesContainer">
            <div class="linaContainer">
                <app-lisa-animada></app-lisa-animada>
            </div>
            <div class="maxContainer">
                <app-max-animado></app-max-animado>
            </div>
            <div class="teoContainer">
                <app-teo-animado></app-teo-animado>
            </div>
            <div class="btnDemoContainer">
                <button (click)="onBtnDemoClicked()"></button>
            </div>
        </div>
    </div>
    <footer>
        <div class="logo"></div>
        <div class="version">{{getVersionForTemplate()}}</div>
    </footer>
</div>
