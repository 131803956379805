import { Component, Input, OnInit } from '@angular/core';
import { SoundDefinition } from '../../../shared/classes/sound-definition';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';

const kOffsetForLingoteEnabled = -160;
const kStyleClassForLingoteAnimated = "lingoteAnimado";

@Component({
  selector: 'app-lingote',
  templateUrl: './lingote.component.html',
  styleUrls: ['./lingote.component.css']
})
export class LingoteComponent implements OnInit {

  @Input() enabled:boolean = false;

  scaleForTemplate = 1;
  animationStyleClass = "none";

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  public enableLingote(withEffect:boolean = false){

    if(withEffect)
    {
      setTimeout(() => {
        this.enabled=true;
        this.enableLingoteWithEffect();
      }, 1500);
    }
    else{
      this.enabled =  true;
    }
  }

  public disableLingote(){
    this.enabled = false;
  }

  private enableLingoteWithEffect(){

    this.soundPlayer.playSoundFromDefinition(SoundDefinition.BRILLO);
    this.animationStyleClass = kStyleClassForLingoteAnimated;
  }

  //#region template
  getBackgroundPositionForTemplate():string{
    let position = (this.enabled) ? kOffsetForLingoteEnabled + "px center" : "left center";
    return position;
  }

  //#endregion template

}
