import { Injectable } from '@angular/core';
import { Sound } from '../classes/sound';
import { SoundDefinition } from '../classes/sound-definition';

@Injectable({
  providedIn: 'root'
})

export class SoundPlayerService {

  private audiosURL = "./assets/sounds/";
  private audiosPlaying:HTMLAudioElement[] = [];

  constructor() { }

  playSoundFromFileName(fileName:string, loop:boolean = false, volume = 1){
    
    let sound = new Sound(fileName, loop, volume);

    this.play(this.setupAudioFromSound(sound));
  }

  playSoundFromDefinition(soundDefinition:SoundDefinition, loop = false, volume = 1, delayInSeconds:number = 0)
  {

    let sound = new Sound(soundDefinition, loop, volume);

    if(delayInSeconds > 0)
    {
      setTimeout(() => {
        this.play(this.setupAudioFromSound(sound));
      }, delayInSeconds*1000);
    }
    else{
      this.play(this.setupAudioFromSound(sound));
    }
    
  }

  playSoundFromUrl(url:string, loop:boolean = false, volume:number =1){

    let audio = new Audio();
    audio.src = url;
    audio.load();
    audio.loop = loop;
    audio.volume = volume;
    audio.id = url;

    this.play(audio);
  }

  setupAudioFromSound(sound:Sound):HTMLAudioElement{

    let audio = new Audio();
    audio.src = this.audiosURL + sound.getSoundFileName();
    audio.load();
    audio.loop = sound.hasLoop();
    audio.volume = sound.getSoundVolume();
    audio.id = sound.getSoundFileName();

    return audio;
  }

  private play(audio:HTMLAudioElement)
  {
    
    audio.play();

    this.audiosPlaying.push(audio);
    
    //escuchamos fin de audio
    audio.addEventListener('ended', (event) => {  
      
      //sacamos el audio de la lista de audios
      this.audiosPlaying.splice(this.audiosPlaying.indexOf(audio), 1);

      audio.remove;
    });
    
  }

  stopSoundFromDefinition(soundDefinition:SoundDefinition)
  {
    
    let audioToStop = null;
    this.audiosPlaying.forEach(audio => {
      if(audio.id == soundDefinition)
      {
       this.stop(audio);
        audioToStop = audio;
      }
    });

    if(audioToStop) 
    {
      this.removeAudio(audioToStop);
    }
  }

  stopSoundFromFileName(fileName:string)
  {

    let audioToStop = null;
    this.audiosPlaying.forEach(audio => {
      if(audio.id == fileName)
      {
        this.stop(audio);
        audioToStop = audio;
      }
    });

    if(audioToStop) 
    {
      this.removeAudio(audioToStop);
    }
  }

  stopAllSounds()
  {
    this.audiosPlaying.forEach(audio => {
      //console.log("Parando audio: " + audio.id);
      this.stop(audio);
      audio.remove;
    });

    this.audiosPlaying.splice(0, this.audiosPlaying.length);
  }

  private removeAudio(audio:HTMLAudioElement)
  {
    this.audiosPlaying.splice(this.audiosPlaying.indexOf(audio), 1);
    audio.remove;
  }

  private stop(audio:HTMLAudioElement)
  {
    audio.pause();
    audio.currentTime = 0;
  }

  //#region Accesos directos a sonidos habituales
  playSoundBackground()
  {
    this.playSoundFromDefinition(SoundDefinition.BACKGROUND, true, 0.5);
  }

  playSoundClick()
  {
    this.playSoundFromDefinition(SoundDefinition.CLICK);
  }

  playSoundAcierto()
  {
    this.playSoundFromDefinition(SoundDefinition.ACIERTO);
  }

  playSoundError()
  {
    this.playSoundFromDefinition(SoundDefinition.ERROR);
  }
  //#endregion

}

