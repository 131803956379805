<div class="pageWrapper" (click)="onClick()"
    [style.opacity]="getOpacityForTemplate()"
    [style.visibility]="getVisibilityForTemplate()">
    <div class="cartela" [style.background-color]="backgroundColor">
        <p>PATRÓN</p>
        <div class="containerPatron">
            <ng-container *ngFor="let url of urlImagesPatron;">
                <div class="patronItem" [style.background-image]="'url(' + url + ')'"></div>
            </ng-container>
        </div>
    </div>
</div>
