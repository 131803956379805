<button class="btnTrimestre"
    (click)="onClick()" 
    [disabled]="isDisabled()" 
    [ngStyle]="{'opacity':isDisabled() === true ? '0.4' : '1'}">
    <div class="btnContainer">
        <div class="containerBtnAnimation">
            <div class="btnIcon"
                [ngClass]="getClassForBackgroundImage()">
            </div>
        </div>
        <div class="btnTexto"
            [style.background-image]="'url(' + getBtnTitleImage() + ')'">
         </div>
    </div>
    <div class="lockContainer" *ngIf="isDisabled()">
        <div class="lock"></div>
    </div>
</button>
