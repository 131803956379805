import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStateService } from '../../../shared/services/app-state.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { BtnAdaptacionThumbInfo } from '../../models/btn-adaptacion-thumb-info';

const kPathToAssets = "./assets/cuadernos_adaptacion/miniaturas/";

@Component({
  selector: 'app-btn-adaptacion-thumb',
  templateUrl: './btn-adaptacion-thumb.component.html',
  styleUrls: ['./btn-adaptacion-thumb.component.css']
})
export class BtnAdaptacionThumbComponent implements OnInit {

  @Input() info:BtnAdaptacionThumbInfo = new BtnAdaptacionThumbInfo();
  @Output() clickEvent = new EventEmitter<BtnAdaptacionThumbInfo>();

  showAsFavorito = false;

  private scaleForTemplate = 0;

  constructor(private appState:AppStateService, private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {

    this.showAsFavorito = this.info.favorite;
  }

  getBackgroundImage():string
  {

    let pathToThumbImage:string = kPathToAssets + this.info.urlThumb;
    return "url('" + pathToThumbImage + "')";
  }

  getBorderColor():string
  {
  
    return this.appState.getPrimaryColorForCurrentAge().rgbString;
  }

  onBtnClicked(){

    this.soundPlayer.playSoundClick();

    this.clickEvent.emit(this.info);
  }

  //#region template
  getFichaURL():string
  {
    return "/visor_adaptacion/" + this.info.id;
  }

  getTransformForTemplate():string
  {
    return "scale(" + this.scaleForTemplate + ")";
  }

  getOpacityForTemplate(){
    return (this.info.enabled)? 1:0.3;
  }


  //#endregion

  //#region public methods
  public showThumb(){
    this.scaleForTemplate = 1;
  }
  //#endregion

}
