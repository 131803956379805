export enum SoundDefinition
{
    BACKGROUND = "audioGame.mp3",
    TICTAC = "tictac.mp3",
    MINISTAR = "exito.mp3",
    ACIERTO = "acierto.mp3",
    ERROR = "error.mp3",
    CLICK = "click.mp3",
    REWARD = "Premio_fx.mp3",
    CHEERS = "cheers.mp3",
    AHA = "fx_voz_aha.mp3",
    FLIPCARD  = "flipCard.mp3",
    BRILLO = "brillo.mp3",
    MAGIA = "fx_magia.mp3", 
    BOING = "fx_boing.mp3",
    TACHAN = "tachan.mp3", 
    ARPEGIO = "fx_arpegio.mp3", 
    TIMER_OVER = "fx_timer_over.mp3",
}
