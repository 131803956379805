import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { BtnJuegoInfo } from '../../models/btn-juego-info';
import { AppStateService } from '../../../shared/services/app-state.service';
import { BtnJuegoComponent } from '../../components/btn-juego/btn-juego.component';
import { FlotanteVideoComponent } from '../../../shared/components/flotante-video/flotante-video.component';
import { FlotanteCartelaComponent } from '../../../shared/components/flotante-cartela/flotante-cartela.component';
import { FlotanteWithButtonsComponent } from '../../../shared/components/flotante-with-buttons/flotante-with-buttons.component';
import { CustomButtonInfo } from '../../../shared/models/custom-button-info';
import { Color } from '../../../shared/classes/color';
import { TGamesId } from '../../../shared-games/classes/t-games-id';
import { GamesManagerService } from '../../../shared-games/services/games-manager.service';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { GameSaveInfo } from '../../../shared/classes/game-save-info';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { FlotanteDeleteLogrosComponent } from '../../components/flotante-delete-logros/flotante-delete-logros.component';

const kPathToAssets = "./assets/juegos/";
const kArrayImgNameAnillos = ["01_anillo_sheet.png", "02_anillo_sheet.png", "03_anillo_sheet.png", "04_anillo_sheet.png"];
const kArrayImgNameAnillosAnimados = ["", "", "anillo_azul_sheet.png", ""];
const kArrayImgNameGameButtons = ["laberintos.png", "rompecabezas.png", "series_logicas.png", "comparaciones.png"];
const kArrayUrlGames = ["","","series",""];
const kArrayGameIds = [TGamesId.LABERINTOS, TGamesId.ROMPECABEZAS, TGamesId.SERIES, TGamesId.COMPARACIONES];
const kArrayTitles = ["Laberintos", "Puzles", "Series Lógicas", "Comparaciones"];

const kTextWhenDiplomaLocked = "Aún no tienes suficientes poderes en tu anillo. Supera los juegos para hacerte con todos y conseguir tu diploma."
const kPathToNarrations = "./assets/locuciones/";
const kNarrationDiplomaLocked = "loc_diploma_locked_es.mp3";
const kNarrationDeleteLogros = "loc_borrado_progresos_es.mp3";

@Component({
  selector: 'app-distribuidor-juegos',
  templateUrl: './distribuidor-juegos.component.html',
  styleUrls: ['./distribuidor-juegos.component.css']
})
export class DistribuidorJuegosComponent extends BaseViewComponent implements OnInit {

  @ViewChildren(BtnJuegoComponent) btnGameControllers!: QueryList<BtnJuegoComponent>;
  @ViewChild(FlotanteVideoComponent, { static: true }) flotanteVideoController!: FlotanteVideoComponent;
  @ViewChild(FlotanteCartelaComponent, { static: true }) flotanteCartelaController!: FlotanteCartelaComponent;
  @ViewChild(FlotanteDeleteLogrosComponent, { static: true }) flotanteConfirmacionController!: FlotanteDeleteLogrosComponent;
  arrayGameButtonsInfoForTemplate: BtnJuegoInfo[] = [];

  constructor(private soundPlayer: SoundPlayerService, 
    protected appState:AppStateService, 
    protected navigationManager:NavigationManagerService, 
    private gameManager:GamesManagerService,
    private storageManager: StorageManagerService) { 

    super(navigationManager, appState);
  }

  ngOnInit(): void {
    
    this.setGameButtonsInfo();

    //reset de juego recien completado
    this.gameManager.lastGameCompleted = TGamesId.NONE;
  }

  ngAfterViewInit(){

    super.ngAfterViewInit();

    setTimeout(() => {
      this.showGameButtons();
    }, 50);
  }

  setGameButtonsInfo(){

    let url: string = "";
    let idGame:TGamesId;
    let title:string = "";
    let pathToImage:string = "";
    let sessions:number = 0;
    let pathToAnilloImage:string = "";
    let pathToAnilloImageAnimado:string = "";
    let lastCompleted:boolean = false;
    let enabled:boolean = true;
    
    for (let i = 0; i < kArrayImgNameGameButtons.length; i++) {
      
      url = kArrayUrlGames[i];
      idGame = kArrayGameIds[i];
      title =  kArrayTitles[i];
      pathToImage = kPathToAssets + kArrayImgNameGameButtons[i];
      pathToAnilloImage = kPathToAssets + kArrayImgNameAnillos[i];
      pathToAnilloImageAnimado = kPathToAssets + kArrayImgNameAnillosAnimados[i];
      lastCompleted = (idGame==this.gameManager.lastGameCompleted);
      sessions = 0;
      enabled = (idGame == TGamesId.SERIES) ? true:false;

      let saveInfo:GameSaveInfo = this.storageManager.getGameSaveInfo(idGame);
      if(saveInfo){
        sessions = this.storageManager.getGameSaveInfo(idGame).sessionsCompleted;
      }
      
      let btnGame = new BtnJuegoInfo(url, idGame, title, pathToImage, sessions, pathToAnilloImage, pathToAnilloImageAnimado, lastCompleted, enabled);
      
      this.arrayGameButtonsInfoForTemplate.push(btnGame);
    }
  }

  showGameButtons()
  {
    let delay = 105;
    let counter = 0;
    this.btnGameControllers.forEach(button => {
      setTimeout(() => {
        button.show();
        //this.soundPlayer.playSoundFromDefinition(SoundDefinition.FLIPCARD);
      }, delay*counter);
      counter++;
    });
  }

  areAllGamesCompleted():boolean{

    for (let i = 0; i < this.arrayGameButtonsInfoForTemplate.length; i++) {
      
      if(this.arrayGameButtonsInfoForTemplate[i].stars < 3) return false;
      
    }

    return true;
  }

  goToGame(url:string)
  {
    this.navigateTo(url);
  }

  private deleteLogros(){

    //borramos progresos
    this.storageManager.deleteGameSaves();

    //quitamos logros
    this.btnGameControllers.forEach(button => {
      button.resetLogros();
    });
  }

  //#region toques
  onGameButtonClicked(info:BtnJuegoInfo){
    
    if(info.url != "")
    {

      //reset de ultimo juego completado
      this.gameManager.lastGameCompleted = TGamesId.NONE;

      this.fadeOut();
      setTimeout(() => {
        this.goToGame(info.url);
      }, 300);
    }
  }

  onClickBtnDiploma(){
    
    this.flotanteCartelaController.show(kTextWhenDiplomaLocked);

    this.soundPlayer.playSoundFromUrl(kPathToNarrations + kNarrationDiplomaLocked);
  }

  onClickBtnIntro(){
    
    this.soundPlayer.playSoundClick();

    let urlVideo = kPathToAssets + "videos/intro_3A_1T.mp4";
    this.flotanteVideoController.show(urlVideo);
  }

  onClikBtnBorrarPremios(){
    
    this.soundPlayer.playSoundClick();

    this.flotanteConfirmacionController.show();

    this.soundPlayer.playSoundFromUrl(kPathToNarrations + kNarrationDeleteLogros);
  }

  onClickFlotanteCartela(){
    this.flotanteCartelaController.hide();
    this.soundPlayer.stopSoundFromFileName(kPathToNarrations + kNarrationDiplomaLocked);
  }

  onClickFlotanteDeleteLogros(deleteLogros:boolean){
    
    if(deleteLogros){
      this.deleteLogros();
    }

    this.soundPlayer.stopSoundFromFileName(kPathToNarrations + kNarrationDeleteLogros);
  }
  //#endregion toquess

  //#region template
  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.JUEGOS;
  }

  getPageBackgroundcolorForTemplate():string{

    return this.appState.getPrimaryColorForCurrentAge().rgbString;
  }

  isDisabledBtnDiplomaForTemplate():boolean{
    
    return !this.areAllGamesCompleted();
  }
  //#endregion

}
