import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../modules/shared/shared.module';
import { SharedGamesModule } from '../../../modules/shared-games/shared-games.module';
import { SeriesViewTresPrimerComponent } from './views/series-view-tres-primer/series-view-tres-primer.component';
import { ContainerSeriesComponent } from './components/container-series/container-series.component';
import { PatronComponent } from './components/patron/patron.component';
// import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [SeriesViewTresPrimerComponent, ContainerSeriesComponent, PatronComponent],
  imports: [
    CommonModule, 
    SharedModule, 
    SharedGamesModule
  ]
})
export class Series31Module { }
