import { TBtnPestanya } from "../classes/t-btn-pestanya";

export class BtnPestanyaInfo {
    type:TBtnPestanya = TBtnPestanya.BITS;
    enabled:boolean = false;
    available:boolean = true;

    constructor(type:TBtnPestanya, enabled:boolean = false, available:boolean = true){
        this.type = type;
        this.enabled = enabled;
        this.available = available;
    }
}
