
import { Injectable } from '@angular/core';
import { GameSaveInfo } from '../classes/game-save-info';
import { TCuadernoFicha } from '../classes/t-cuaderno-ficha';

const kKeyGameSaves = "savedGames";
const kKeyFavoritesAdaptacion = "favorites_adaptacion";
const kKeyFavoritesTematico = "favorites_tematico";
const kKeyFavoritesEntrenamiento = "favorites_entrenamiento";

@Injectable({
  providedIn: 'root'
})
export class StorageManagerService {

  constructor() { }

  //#region juegos
  public saveGame(saveInfo:GameSaveInfo)
  {
    
    //obtenemos datos salvados
    let dataSaved:GameSaveInfo[] = this.getGameSaves();
    
    //miramos si estamos salvando un juego que ya existe
    let index = this.getGameSaveIndex(saveInfo.idGame);
    
    if(index == -1)
    {
      dataSaved.push(saveInfo);
    } 
    else{
      dataSaved[index]=saveInfo;
    }
    
    localStorage.setItem(kKeyGameSaves, JSON.stringify(dataSaved));
  }

  public getGameSaveInfo(idGame:number):GameSaveInfo{

    let dataSaved:GameSaveInfo[] = this.getGameSaves();

    for (let i = 0; i < dataSaved.length; i++) {
      if(dataSaved[i].idGame == idGame){
        //console.log("Encontrado salvado anterior!!!");
        return dataSaved[i];
      }
    }
    return null as any;      
  }

  public deleteGameSaves(){
    
    localStorage.removeItem(kKeyGameSaves);
  }

  private getGameSaveIndex(idGame:number):number{

    let index = -1;
    let dataSaved:GameSaveInfo[] = this.getGameSaves();

    for (let i = 0; i < dataSaved.length; i++) {
      if(dataSaved[i].idGame == idGame){
        index = i;
        break;
      }
    }

    return index;
         
  }

  private getGameSaves():GameSaveInfo[]{

    if(localStorage.getItem(kKeyGameSaves) != null){
      let data = localStorage.getItem(kKeyGameSaves);
      //console.log("Tenemos datos: " + data);
      let dataParsed:GameSaveInfo[] = [];
      dataParsed = data ? JSON.parse(data) : [];
      return dataParsed;
    }
    else{
      //console.log("No tenemos datos!");
      return [];
    }
  }
  //#endregion juegos

  //#region adaptacion
  public saveFichaToFavorites(id:string, typeCuaderno:TCuadernoFicha){

    //obtenemos datos salvados
    let dataSaved:string[] = this.getFavorites(typeCuaderno);
    
    //miramos si estamos salvando una ficha que ya existe
    let index = dataSaved.indexOf(id);
    
    if(index == -1)
    {
      dataSaved.push(id);
      localStorage.setItem(this.getKeyForCuadernos(typeCuaderno), JSON.stringify(dataSaved));
    } 
  }

  public deleteFichaFromFavorites(id:string, typeCuaderno:TCuadernoFicha){

    console.log("DeleteFicha");
    //obtenemos datos salvados
    let dataSaved:string[] = this.getFavorites(typeCuaderno);
    
    //miramos si estamos salvando una ficha que ya existe
    let index = dataSaved.indexOf(id);
    
    if(index != -1)
    {
      dataSaved.splice(index, 1);
      localStorage.setItem(this.getKeyForCuadernos(typeCuaderno), JSON.stringify(dataSaved));
    } 
  }

  public isFavorite(id:string, typeCuaderno:TCuadernoFicha):boolean{
    
    //obtenemos datos salvados
    let dataSaved:string[] = this.getFavorites(typeCuaderno);
    //console.log("Ficha: " + id + " favorite: " + (dataSaved.indexOf(id)>-1));

    return dataSaved.indexOf(id) > -1;
  }

  private getFavorites(typeCuaderno:TCuadernoFicha):string[]{

    let key = this.getKeyForCuadernos(typeCuaderno);

    if(localStorage.getItem(key) != null){
      let data = localStorage.getItem(key);
      //console.log("Favoritos adaptacion: " + data);
      let dataParsed:string[] = [];
      dataParsed = data ? JSON.parse(data) : [];
      return dataParsed;
    }
    else{
      console.log("No tenemos datos!");
      return [];
    }
  }

  private getKeyForCuadernos(type:TCuadernoFicha):string{
    switch(type){
      case TCuadernoFicha.ADAPTACION:
        return kKeyFavoritesAdaptacion;
      case TCuadernoFicha.ENTRENAMIENTO:
        return kKeyFavoritesEntrenamiento;
      case TCuadernoFicha.TEMATICO:
        return kKeyFavoritesTematico;
      default:
        console.log("ERROR: No se encuentra ese tipo de cuaderno: " + type);
        return kKeyFavoritesTematico;
    }
  }

  //#endregion adaptacion

}
