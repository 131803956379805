<div class="pageWrapper">
    <header>
        <app-top-bar
            [style]="getTopBarStyleForTemplate()"
            [navigateBackUrl]="'menu'"
            (clickAtras) = "eventOnClickBack()"
            (clickNavBar) = "eventOnClickNavBar($event)"
            (clickHome) = "eventOnClickHomeButton()">
        </app-top-bar>
    </header>
    <div class="titleBar">
        <p>CUADERNO DE ENTRENAMIENTO</p>
    </div>
    <div class="thumbsContainer">
        <ng-container *ngFor="let thumb of thumbs;">
            <app-btn-entrenamiento-thumb 
            [info] = "thumb"
            (clickEvent) = "eventOnClickThumb($event)">
            </app-btn-entrenamiento-thumb >
        </ng-container>
    </div>
</div>
