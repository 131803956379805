export class ContainerInfo {
    id:string = "";
    idDraggable:string = "";
    urlImage:string = "";
    urlImageWhenSolved = "";
    width = 0;
    height = 0;
    scale = 0;
    solved:boolean = false;

    constructor(id:string, idDraggable:string, urlImage:string, urlImageWhenSolved:string, width:number, height:number, scale = 0, solved:boolean = false){
        this.id = id;
        this.idDraggable = idDraggable;
        this.urlImage = urlImage;
        this.urlImageWhenSolved = urlImageWhenSolved;
        this.width = width;
        this.height = height;
        this.solved = solved;
        this.scale = scale;
    }
}
