<div class="wrapper" 
    [style.opacity]="getOpacityForTemplate()"
    [style.transform]="getTransformForTemplate()">
    <button (click)="onBtnClicked()"
        [style.background-image]="getBackgroundImage()"
        [style.border-color]="getBorderColor()"
        [disabled]="!info.enabled">
    </button>
    <p>{{info.title}}</p>
</div>

