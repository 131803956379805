import { Injectable } from '@angular/core';
import { TTrimestre } from '../modules/shared/classes/t-trimestre';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private userLogged:boolean = false;
  private trimestresAvailables:TTrimestre[] = [TTrimestre['3_1']];

  constructor() { }

  login(user:string, password:string):boolean{
    console.log("comprobando login para user: " + user);

    let result = user == "admin" && password == "admin";
    this.userLogged = result;

    return result;
  }

  getName():string{
    return "INVITADO";
  }

  getTrimestresAvailables():TTrimestre[]
  {
    return this.trimestresAvailables;
  }

}
