import { Component, OnInit, ViewChild } from '@angular/core';
import { TLanguage } from '../../../shared/classes/t-language';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { AppStateService } from '../../../shared/services/app-state.service';
import { FichaTematicaInfo } from '../../models/ficha-tematica-info';
import { TematicosConfigService } from '../../services/tematicos-config.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { Color } from '../../../shared/classes/color';
import { PainterComponent } from '../../../shared/components/painter/painter.component';
import { TPainterBrushSize } from '../../../shared/classes/t-painter-brush-size';
import { BtnCuadernosRecursoItemInfo } from '../../../shared/models/btn-cuadernos-recurso-item-info';
import { TCuadernosMenuItem } from '../../../shared/classes/t-cuadernos-menu-item';
import { TCuadernoFicha } from '../../../shared/classes/t-cuaderno-ficha';
import { CuadernosMenuBarComponent } from '../../../shared/components/cuadernos-menu-bar/cuadernos-menu-bar.component';
import { FlotanteFichaInteractivaComponent } from '../../../shared/components/flotante-ficha-interactiva/flotante-ficha-interactiva.component';
import { TEventFromFichaInteractiva } from '../../../shared/classes/t-event-from-ficha-interactiva';
import { BtnCuadernosMenuInfo } from '../../../shared/models/btn-cuadernos-menu-info';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { TNavBarButtons } from '../../../shared/classes/t-nav-bar-buttons';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { PainterBarComponent } from '../../../shared/components/painter-bar/painter-bar.component';

const kPathToAssets = "./assets/cuadernos_tematicos/";
const kFolderAssetsNameForAge3 = ["3A_1T", "3A_2T", "3A_3T"];
const kFolderAssetsNameForAge4 = ["4A_1T", "4A_2T", "4A_3T"];
const kFolderAssetsNameForAge5 = ["5A_1T", "5A_2T", "5A_3T"];
const kImgNameFavoriteDisabled = "favorito_desactivado.png";
const kFadeDelay = 200;

@Component({
  selector: 'app-tematicos-visor',
  templateUrl: './tematicos-visor.component.html',
  styleUrls: ['./tematicos-visor.component.css']
})
export class TematicosVisorComponent extends BaseViewComponent implements OnInit {

  @ViewChild(PainterComponent, { static: true }) painter?: PainterComponent;
  @ViewChild(FlotanteFichaInteractivaComponent,  {static: true}) flotanteFichaController!: FlotanteFichaInteractivaComponent;
  @ViewChild(CuadernosMenuBarComponent,  {static: true}) menuBarController!: CuadernosMenuBarComponent;
  @ViewChild(PainterBarComponent,  {static: true}) painterMenuBarController!: PainterBarComponent;

  pageOpacity = 0;

  fichas:FichaTematicaInfo[] = [];
  currentFichaInfo:FichaTematicaInfo = new FichaTematicaInfo();
  headers:any;
  favoriteForTemplate = false;
  opacityRecursosDesplegableForTemplate = 1;
  isShowingFlotanteForTemplate = false;
  opacityFlotanteForTemplate = 0;

  widthForCanvas = 1160;
  heightForCanvas = 833;
  topForCanvas = 0;
  leftForCanvas = 0;
  
  private fichaId:any;
  private imgNameFichaShowingInTemplate:string = "";
  private showingReverse = false;
  private ImgNameFavoriteShowingInTemplate:string = kImgNameFavoriteDisabled;
  private opacityFichaForTemplate = 0;
  private blockedButtons = false;

  constructor(private serverConfig:TematicosConfigService, 
    protected appState:AppStateService,
    private soundPlayer:SoundPlayerService,
    private activateRoute:ActivatedRoute, 
    protected navigationManager:NavigationManagerService, 
    protected storageManager:StorageManagerService) {

      super(navigationManager, appState);
  }

  ngOnInit(): void {

    super.ngOnInit();
    
    this.activateRoute.params.subscribe(data => 
      {
        this.fichaId = data.id;
        //console.log("Tengo el parametro: " + this.fichaId);
      });
  
    //Recuperamos info de todas las fichas
    //Nos hace falta saber la info de todas las fichas para poder navegar entre fichas sin hacer mas peticiones a la API
    this.getFichasFromServer(this.appState.getAge(), this.appState.getNumberOfTrimestre(), this.appState.getLanguage(), this.fichaId);
    
    //this.getFichaInfoFromServer(this.fichaId);
  }

  /* Se llama cuando se han recibido los datos de la ficha del server */
  initFicha(info:FichaTematicaInfo)
  {

    this.currentFichaInfo = info;
    this.imgNameFichaShowingInTemplate = this.currentFichaInfo.urlImg;
    this.showingReverse = false;

    //favorito en produccion
    //this.favoriteForTemplate = this.currentFichaInfo.favorite;

    //favorito en demo
    this.currentFichaInfo.favorite = this.storageManager.isFavorite(this.currentFichaInfo.id, TCuadernoFicha.TEMATICO);
    this.favoriteForTemplate = this.currentFichaInfo.favorite;

    //menu para la ficha
    this.menuBarController.setMenuForFicha(this.currentFichaInfo);
    
    //mostramos la ficha
    this.showFichaWithDelay(kFadeDelay);

    //seteamos url de vuelta para navegacion a la url de esta ficha
    let urlBack = "/visor_tematicos/" + this.currentFichaInfo.id;
    this.navigationManager.setBackUrl(urlBack);

     //borramos trazos
     this.painter!.clear();

     //ocultamos texto arrastrable
     this.painter!.hideDraggableText();
     this.painterMenuBarController.disableTextToolButton();
  }

  showFichaWithDelay(delay:number)
  {
    setTimeout(() => {
      this.opacityFichaForTemplate = 1;
    }, delay);
  }

  hideFicha()
  {
    this.opacityFichaForTemplate = 0;
  }

  getFichaInfoFromServer(id:string)
  {
    this.serverConfig.getFicha(id).subscribe(
      data => {
        this.initFicha(data);
      }, error => {
        console.log("ERROR desde el componente!");
      }
    );
  }

  getFichasFromServer(age:number, trimestre:number, language:TLanguage, currentFichaId:string)
  {
    this.serverConfig.getFichas(age, trimestre, language).subscribe(
      data => {
      
        /*** OJO: Usamos un array intermedio para recuperar solo las fichas activadas para la demo. */
        /*** En produccion no habra fichas desactivas por lo que no haria falta quitar de la respuesta del server aquellas desactivadas */
        let allFichas = data; //*** En produccion quitar esto
        //this.fichas = data; *** En produccion descomentar esto
        allFichas.forEach(ficha => {  /**En produccion quitar esto */
          if(ficha.enabled){
            this.fichas.push(ficha);
          }
        });

        this.initFicha(this.getFichaFromArray(currentFichaId));
        //console.log("Tengo fichas: " + this.fichas.length);
      }, error => {
        console.log("ERROR desde el componente!");
      }
    );
  }

  getFichaFromArray(id:string):FichaTematicaInfo
  {

    for (let i = 0; i < this.fichas.length; i++) {
      if(this.fichas[i].id === id)
      {
        return this.fichas[i];
      } 
    }

    console.log("[TEMATICOS-VISOR] ERROR: No se ha encontrado la ficha tematica con id: " + id);
    return new FichaTematicaInfo();
  }

  getAssetsFolder():string
  {
    let assetsFolder = kPathToAssets;
    let age:number = this.appState.getAge();
    let trimestre:number = this.appState.getNumberOfTrimestre();
  
    switch(age)
    {
      case 3:
        assetsFolder = assetsFolder + kFolderAssetsNameForAge3[trimestre-1]
        break;
      case 4:
        assetsFolder = assetsFolder + kFolderAssetsNameForAge4[trimestre-1]
        break;
      case 5:
        assetsFolder = assetsFolder + kFolderAssetsNameForAge5[trimestre-1]
        break;
    }

    return assetsFolder + "/";
  }

  getIndexOfCurrentFichaInArray():number{

    let index = this.fichas.indexOf(this.currentFichaInfo);
  
    return index;
  }

  flipImage()
  {

    if(this.showingReverse)
    {
      this.imgNameFichaShowingInTemplate = this.currentFichaInfo.urlImgReverse;
    }
    else
    {
      this.imgNameFichaShowingInTemplate = this.currentFichaInfo.urlImg;
    }

    this.showFichaWithDelay(kFadeDelay);
  }


  //#region eventos
  onClickReverse()
  {

    this.soundPlayer.playSoundClick();

    this.hideFicha();

    this.showingReverse = !this.showingReverse;

    setTimeout(() => {
      
      //borramos trazos
      this.painter!.clear();
      
      //flip de la ficha
      this.flipImage();
    }, kFadeDelay);
    
  }

  onClickFavorite(){

    this.soundPlayer.playSoundClick();

    this.currentFichaInfo.favorite = !this.currentFichaInfo.favorite;
    this.favoriteForTemplate = this.currentFichaInfo.favorite;
   
    //TODO: llamar al servicio para updatear si la ficha es favorita
    this.serverConfig.updateFavorite(this.currentFichaInfo);
  }

  onClickSiguiente(){

    this.soundPlayer.playSoundClick();

    if(this.blockedButtons) return;

    if(this.canNavigateNext())
    {

      this.blockedButtons = true;

      this.hideFicha();

      setTimeout(() => {
        this.initFicha(this.getNextFicha());

        this.blockedButtons = false;
      }, kFadeDelay);
    }  
  }

  onClickAnterior(){

    this.soundPlayer.playSoundClick();

    if(this.blockedButtons) return;

    if(this.canNavigatePrevious())
    {

      this.blockedButtons = true;

      this.hideFicha();

      setTimeout(() => {
        this.initFicha(this.getPreviousFicha());
        this.blockedButtons = false;
      }, kFadeDelay);
    }  
  }

  canNavigateNext():Boolean
  {

    let index = this.getIndexOfCurrentFichaInArray();
  
    if(this.fichas == null || this.fichas.length == 0 || index == -1 || index == this.fichas.length) return false;
    
    if(index + 1 >= this.fichas.length) return false;

    return true;
  }

  canNavigatePrevious():Boolean
  {

    let index = this.getIndexOfCurrentFichaInArray();

    if(this.fichas == null || this.fichas.length == 0 || index == -1 || index == 0) return false;

    if(index - 1 < 0) return false;

    return true;
  }

  getNextFicha():FichaTematicaInfo
  {

    let currentIndex = this.fichas.indexOf(this.currentFichaInfo);

    if(currentIndex > -1 && currentIndex < this.fichas.length)
    {
      return this.fichas[currentIndex+1];
    }

    return new FichaTematicaInfo();
  }

  getPreviousFicha():FichaTematicaInfo
  {
    let currentIndex = this.fichas.indexOf(this.currentFichaInfo);

    if(currentIndex > -1 && currentIndex > 0)
    {
      return this.fichas[currentIndex-1];
    }

    return new FichaTematicaInfo();
  }

  onClickMenuBarItem(info:BtnCuadernosMenuInfo)
  {
    
    switch(info.type)
    {
      case TCuadernosMenuItem.INTERACTIVA:
        //this.showFlotante();
        this.flotanteFichaController.showFlotante();
        break;
      case TCuadernosMenuItem.ENTRENAMIENTO:
        break;
      case TCuadernosMenuItem.RECURSOS:
        break;
      case TCuadernosMenuItem.CURRICULAR:
        //descarga del pdf
        this.serverConfig.downloadPdfFromUrl(info.urlAsociada, this.currentFichaInfo.title);
        break;
    }
  }

  onClickRecursoItem(info:BtnCuadernosRecursoItemInfo){

    //this.showFlotante();
    //console.log("Hola, soy el visor, parece que hemos pulsado un boton de desplegable: " + info.url);
    this.navigateTo(info.url);
  }


  onEventClickColorItem(color:Color)
  {
    this.painter!.setColor(color);
  }

  onEventClickBrushSize(size:TPainterBrushSize){
    

    this.painter!.setBrush(size);
  }

  onEventClickEraser()
  {
    this.painter!.erase();
  }

  onEventClickTextTool(enabled:boolean){

    if(enabled){
      this.painter!.showDraggableText();
    }
    else{
      this.painter!.hideDraggableText();
    }
  }

  onEventClickTrash(){

    this.painter!.clear();
  }

  onEventFichaInteractiva(eventFicha:TEventFromFichaInteractiva){
    console.log("Hemos recibido un evento de la ficha interactiva: " + eventFicha);
  }
  //#endregion

  //#region accesos desde el template
  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.TEMATICO;
  }

  getImgNameForTemplate():string
  {
    // console.log("getImage: " + this.fichaImgNameShowingInTemplate);
    return "url('" + this.getAssetsFolder() + this.imgNameFichaShowingInTemplate + "')";
  }

  getFavoriteImgNameForTemplate():string
  {
    return "url('" + kPathToAssets + this.ImgNameFavoriteShowingInTemplate + "')";
  }

  getImgOpacityForTemplate():string{
    return this.opacityFichaForTemplate.toString();
  }

  getFichaType():TCuadernoFicha{
    return TCuadernoFicha.TEMATICO;
  }

  getUrlFichaInteractiva():string{
    
    //console.log("URL Ficha: " + this.currentFichaInfo.urlFicha);
    //return "./assets/fichas/03_01/index.html";
    //return "../../../../../assets/fichas/03_02/index.html";
    return this.currentFichaInfo.urlFicha;
  }
  //#endregion

}
