import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TCuadernosMenuRecursoItem } from '../../classes/t-cuadernos-menu-recurso-item';
import { BtnCuadernosRecursoItemInfo } from '../../models/btn-cuadernos-recurso-item-info';
import { SoundPlayerService } from '../../services/sound-player.service';

const kPathToAssets = "./assets/cuadernos_barra_menu/";
const kImgNameDescargable = "descargable.png";
const kImgNameVideo = "video.png";
const kImgNameInteractivo = "interactivo.png";
const kImgNameAudio = "audio.png";
const kImageTrazo = "trazo.png";
const kImageEntrenamiento = "entrenamiento_desplegable.png";

@Component({
  selector: 'app-btn-cuaderno-menu-bar-recurso',
  templateUrl: './btn-cuaderno-menu-bar-recurso.component.html',
  styleUrls: ['./btn-cuaderno-menu-bar-recurso.component.css']
})
export class BtnCuadernoMenuBarRecursoComponent implements OnInit {

  @Input() info:BtnCuadernosRecursoItemInfo = new BtnCuadernosRecursoItemInfo();
  @Output() clickEvent = new EventEmitter<BtnCuadernosRecursoItemInfo>();

  constructor(private soundPlayer: SoundPlayerService) { }

  ngOnInit(){
    
  }

  onClick(){

    console.log("Click en boton recurso: " + this.info.name);
    
    this.soundPlayer.playSoundClick();
    
    this.clickEvent.emit(this.info);
  }

  getBackgroundImageForTemplate():string{

    let imageName = "";
    switch(this.info.type)
    {
      case TCuadernosMenuRecursoItem.DESCARGABLE:
        imageName = kImgNameDescargable;
        break;
      case TCuadernosMenuRecursoItem.INTERACTIVO:
        imageName = kImgNameInteractivo;
        break;
      case TCuadernosMenuRecursoItem.VIDEO:
        imageName = kImgNameVideo;
        break;
      case TCuadernosMenuRecursoItem.AUDIO:
        imageName = kImgNameAudio;
        break;
      case TCuadernosMenuRecursoItem.TRAZO:
        imageName = kImageTrazo;
        break;
      case TCuadernosMenuRecursoItem.ENTRENAMIENTO:
        imageName = kImageEntrenamiento;
        break;
    }

    return "url('" + kPathToAssets + imageName + "')";
  }

  getOpacityForTemplate():number{
    
    return (this.isDisabled()) ? 0.3 : 1;
  }

  isDisabled():boolean{
    return (this.info.type != TCuadernosMenuRecursoItem.ENTRENAMIENTO);
  }

}
