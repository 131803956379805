import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Color } from '../../classes/color';
import { CustomButtonInfo } from '../../models/custom-button-info';
import { SoundPlayerService } from '../../services/sound-player.service';

@Component({
  selector: 'app-custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.css']
})
export class CustomButtonComponent implements OnInit {

  @Input() info: CustomButtonInfo = new CustomButtonInfo("id", "", 0, 0, new Color(0,0,0), new Color(0,0,0),0, "");
  @Output() eventOnClick = new EventEmitter<CustomButtonInfo>();

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  onClick(){

    this.soundPlayer.playSoundClick();

    this.eventOnClick.emit(this.info);
  }


}
