<div class="pageWrapper">
    <header>
        <app-top-bar
            [style]="getTopBarStyleForTemplate()"
            [navigateBackUrl]="'menu_adaptacion'"
            (clickAtras) = "eventOnClickBack()"
            (clickNavBar) = "eventOnClickNavBar($event)"
            (clickHome) = "eventOnClickHomeButton()">
        </app-top-bar>
    </header>
    <div class="titleBar">
        <p class="title">PERIODO DE ADAPTACIÓN > </p>
        <p class="subtitle">{{currentFichaInfo.title}}</p>
    </div>
    <div class="mainContainer">
        <div class="leftBar">
            <button class="favorite" (click)="onClickFavorite()">
                <div [ngClass]="{'imgFavorite':favoriteForTemplate, 'imgNoFavorite':!favoriteForTemplate}"></div>
            </button>
            <!-- <button class="flip"></button> -->
            <!--Barra lateral con el menu-->
            <app-cuadernos-menu-bar
                [fichaType] = "getFichaType()"
                (clickMenuItem)="onClickMenuBarItem($event)">
            </app-cuadernos-menu-bar>   
            <button class="anterior" (click)="onClickAnterior()"></button>
        </div>
        <div class="main" 
            [style.background-image]="getImgNameForTemplate()"
            [style.opacity]="getImgOpacityForTemplate()">
            <div class="containerPainter">
                <app-painter
                    [width]="widthForCanvas"
                    [height]="heightForCanvas"
                    [top]="topForCanvas"
                    [left]="leftForCanvas"></app-painter>
            </div>
        </div><!--Imagen de la ficha-->
        <div class="rightBar">
            <app-painter-bar
                (onClickColorItem)="onEventClickColorItem($event)"
                (onClickBrushSize)="onEventClickBrushSize($event)"
                (onClickEraser)="onEventClickEraser()"
                (onClickTextTool)="onEventClickTextTool($event)"
                (onClickTrash)="onEventClickTrash()">
            </app-painter-bar>
            <button class="siguiente" (click)="onClickSiguiente()"></button>
        </div>
    </div>
    <app-flotante-ficha-interactiva
        [urlFicha]="getUrlFichaInteractiva()"
        (eventFicha)="onEventFichaInteractiva($event)">
    </app-flotante-ficha-interactiva> 
</div>


