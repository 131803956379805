import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';

@Component({
  selector: 'app-btn-diploma',
  templateUrl: './btn-diploma.component.html',
  styleUrls: ['./btn-diploma.component.css']
})
export class BtnDiplomaComponent implements OnInit {

  @Input() isDisabled:boolean = false;
  @Output() eventOnClick = new EventEmitter();

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  onClick(){

    console.log("Click");
    this.soundPlayer.playSoundClick();

    this.eventOnClick.emit();
  }

  getLockVisibilityForTemplate():string{
    return(this.isDisabled) ? "visible" : "hidden";
  }

}
