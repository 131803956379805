import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SoundPlayerService } from '../../services/sound-player.service';
import { AppStateService } from '../../services/app-state.service';

const kFadeDelay = 200;

@Component({
  selector: 'app-flotante-video',
  templateUrl: './flotante-video.component.html',
  styleUrls: ['./flotante-video.component.css']
})
export class FlotanteVideoComponent implements OnInit {

  @ViewChild("player") videoPlayer!: ElementRef; 
  @Output() onClose = new EventEmitter();

  private visible:boolean = false;
  private urlVideo:string = "";
  private opacity=0;
  
  constructor(private soundPlayer:SoundPlayerService, private appState:AppStateService) { }

  ngOnInit(): void {
  }

  public show(urlVideo:string)
  {
    this.urlVideo = urlVideo

    setTimeout(() => {
      
      this.videoPlayer.nativeElement.load();
      this.visible = true;
      this.opacity = 1;
    }, 50);
    
  }

  private hide(){
    this.opacity = 0;
    
    setTimeout(() => {
      this.visible = false;
    }, kFadeDelay);
  }

  onCloseClick(){

    this.soundPlayer.playSoundClick();

    this.onClose.emit();

    this.hide();
    this.videoPlayer.nativeElement.pause();
  }

  getVisibilityForTemplate():string{

    return (this.visible) ? "visible" : "hidden";
  }

  getUrlVideoForTemplate():string{
    
    return this.urlVideo;
  }

  getOpacityForTemplate():number{
    return this.opacity;
  }

  getHeaderBackgroundColorForTemplate():string{
    return this.appState.getPrimaryColorForCurrentAge().rgbString;
  }

  hasUrl():boolean{
    return (this.urlVideo != "");
  }

}
