export class DraggableInfo {
    id:string = "";
    idContainer:string = "";
    urlImage:string = "";
    urlImageWhenSolved = "";
    width = 0;
    height = 0;
    scale = 0;
    enabled:boolean = true;

    constructor(id:string, idContainer:string, urlImage:string, urlImageWhenSolved:string, width:number, height:number, scale = 1, enabled:boolean = true){
        this.id = id;
        this.idContainer = idContainer;
        this.urlImage = urlImage;
        this.urlImageWhenSolved = urlImageWhenSolved;
        this.width = width;
        this.height = height;
        this.enabled = enabled;
        this.scale = scale;
    }
}
