import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TNavBarButtons } from '../../classes/t-nav-bar-buttons';
import { SoundPlayerService } from '../../services/sound-player.service';
import { AppStateService } from '../../services/app-state.service';


const kTitleGeneradorTarjetas = "Generador \n de tarjetas";
const kTitleGeneradorTextos = "Generador \n de textos";
const kTitleMaterialComplementario = "Material complementario";
const kTitleDinamizador = "Dinamizador \n de aula";
const kTitleFriso = "Friso";
const kTitleVideo = "Video";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  @Input() buttonsToShow:TNavBarButtons[] = [];
  @Output() onBtnNavBarClicked = new EventEmitter<TNavBarButtons>();

  public titleGeneradorTarjetasForTemplate = kTitleGeneradorTarjetas;
  public titleGeneradorTextosForTemplate = kTitleGeneradorTextos;
  public titleMaterialComplementarioForTemplate = kTitleMaterialComplementario;
  public titleDinamizadorAulaForTemplate = kTitleDinamizador;
  public titleFrisoForTemplate = kTitleFriso;
  public titleVideoForTemplate = kTitleVideo;

  private showDinamizadorDesplegable:boolean = false;
  
  constructor(private soundPlayer:SoundPlayerService, private appState:AppStateService) { }

  ngOnInit(): void {
  }

  canShowTarjetasButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.GENERADOR_TARJETAS);
  }

  canShowTextosButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.GENERADOR_TEXTOS);
  }

  canShowComplementarioButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.MATERIAL_COMPLEMENTARIO);
  }

  canShowDinamizadorButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.DINAMIZADOR);
  }

  canShowFrisoButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.FRISO);
  }

  canShowKarokeButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.KARAOKE);
  }

  canShowVideoButton():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.VIDEO);
  }

  canShowGamesTitle():boolean{
    return this.buttonsToShow.includes(TNavBarButtons.GAMES_TITLE);
  }

  canShowDinamizadorDesplegable():boolean
  {
    return this.showDinamizadorDesplegable;
  }

  getOpacityForDinamizadorDesplegable():string{
    
    if(this.showDinamizadorDesplegable) return "1";

    return "0";
  }

  getClassForDisableDinamizadorDesplegable():string{
    if(!this.showDinamizadorDesplegable) return "dinamizador_desplegable_hidden";

    return "";
  }

  getBackgroundColorForTemplate():string{
    
    return this.appState.getPrimaryColorForCurrentAge().rgbString;
  }

  getTitleColorForTemplate():string{
    return this.appState.getTitleColorForCurrentAge().rgbString;
  }

  onBtnTarjetasClicked(){
    
    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.GENERADOR_TARJETAS);
  }

  onBtnTextosClicked(){

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.GENERADOR_TEXTOS);
  }

  onBtnComplementarioClicked()
  {

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.MATERIAL_COMPLEMENTARIO);
  }

  onBtnDinamizadorClicked(){

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = !this.showDinamizadorDesplegable;
   
    this.onBtnNavBarClicked.emit(TNavBarButtons.DINAMIZADOR);
  }

  onBtnDinamizadorPalmasClicked()
  {

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;

    this.onBtnNavBarClicked.emit(TNavBarButtons.DINAMIZADOR_PALMAS);
  }

  onBtnDinamizadorSemaforoClicked()
  {
    
    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;

    this.onBtnNavBarClicked.emit(TNavBarButtons.DINAMIZADOR_SEMAFORO);
  }

  onBtnDinamizadorRelojClicked()
  {
    
    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;

    this.onBtnNavBarClicked.emit(TNavBarButtons.DINAMIZADOR_RELOJ);
  }

  onBtnDinamizadorCaraClicked()
  {
    
    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;

    this.onBtnNavBarClicked.emit(TNavBarButtons.DINAMIZADOR_CARA);
  }

  onBtnFrisoClicked()
  {

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;

    this.onBtnNavBarClicked.emit(TNavBarButtons.FRISO);
  }

  onKarokeClicked(){

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.KARAOKE);
  }

  onInstrumentalClicked()
  {

    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.INSTRUMENTAL);
  }

  onBtnVideoClicked(){
    
    this.soundPlayer.playSoundClick();

    this.showDinamizadorDesplegable = false;
    this.onBtnNavBarClicked.emit(TNavBarButtons.INSTRUMENTAL);
  }

}
