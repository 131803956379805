import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Color } from '../../classes/color';
import { SoundPlayerService } from '../../services/sound-player.service';

@Component({
  selector: 'app-btn-painter-bar-color',
  templateUrl: './btn-painter-bar-color.component.html',
  styleUrls: ['./btn-painter-bar-color.component.css']
})
export class BtnPainterBarColorComponent implements OnInit {

  @Input() color:Color = new Color(0,0,0,0);
  @Output() eventOnClick = new EventEmitter<BtnPainterBarColorComponent>();

  enabled = false;

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  public disable(){
    
    this.enabled = false;
  }

  public enable(){
    this.enabled = true;
  }

  onClick(){
    
    this.soundPlayer.playSoundClick();

    this.enabled = true;

    this.eventOnClick.emit(this);
  }

}
