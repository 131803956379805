import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { GameStarComponent } from '../game-star/game-star.component';

const kAnimationStar1 = "enableStar1 0.5s forwards";
const kAnimationStar2 = "enableStar2 0.5s forwards";
const kAnimationStar3 = "enableStar3 0.5s forwards";
const kAnimationsStar = [kAnimationStar1, kAnimationStar2, kAnimationStar3];

@Component({
  selector: 'app-games-stars',
  templateUrl: './games-stars.component.html',
  styleUrls: ['./games-stars.component.css']
})
export class GamesStarsComponent implements OnInit {

  @ViewChildren(GameStarComponent) starControllers!: QueryList<GameStarComponent>;

  arrayStarsControllers: GameStarComponent[] = [];

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){

    setTimeout(() => {
      this.arrayStarsControllers = this.starControllers.toArray();
    }, 50);
  }

  public enableStar(index:number){
    if(index > this.starControllers.length) return;

    this.arrayStarsControllers[index].enableStar();
  }
}
