
export class Color {
    public r:number = 0;
    public g:number = 0;
    public b:number = 0;
    public a:number = 0;
    public rgbString = "rgb(0,0,0,0)";

    constructor(r:number, g:number, b:number, a:number = 1){
        this.r = r;
        this.g = g;
        this.b = b;
        this.a = a;
        this.rgbString = "rgb(" + this.r.toString() + "," + this.g.toString() + "," + this.b.toString() + "," + this.a.toString() + ")";
    }
}
