import { Component, OnInit } from '@angular/core';
import { BtnTrimestreInfo } from '../../classes/btn-trimestre-info';
import { TTrimestre } from '../../modules/shared/classes/t-trimestre';
import { LoginService } from '../../services/login.service';
import { AppStateService } from '../../modules/shared/services/app-state.service';
import { BaseViewComponent } from '../../modules/shared/views/base-view/base-view.component';
import { NavigationManagerService } from '../../modules/shared/services/navigation-manager.service';

@Component({
  selector: 'app-selector-trimestre',
  templateUrl: './selector-trimestre.component.html',
  styleUrls: ['./selector-trimestre.component.css']
})
export class SelectorTrimestreComponent extends BaseViewComponent implements OnInit {

  buttonsTrimestresForTemplate:BtnTrimestreInfo[] = [];

  constructor(private loginService:LoginService, protected appState:AppStateService, protected navigationManager:NavigationManagerService) {
    super(navigationManager, appState);
    this.autoScaleView = false;
  }
  
  ngOnInit(): void {

    super.ngOnInit();
    this.setButtonsTrimestres();
  }

  ngAfterViewInit(): void {
    
    super.ngAfterViewInit();
    // (<HTMLElement>document.querySelector('#mainView')).style.opacity="1";
    // (<HTMLElement>document.querySelector('#htmlId')).style.backgroundColor="red";
    // (<HTMLElement>document.querySelector('#htmlId')).style.backgroundImage="url('assets/selector/fondo.png')"
  }

  setButtonsTrimestres()
  {

    //3 años
    let btnTrimestre = new BtnTrimestreInfo(TTrimestre['3_1'], this.loginService.getTrimestresAvailables().includes(TTrimestre['3_1']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['3_2'], this.loginService.getTrimestresAvailables().includes(TTrimestre['3_2']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['3_3'], this.loginService.getTrimestresAvailables().includes(TTrimestre['3_3']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    //4años
    btnTrimestre = new BtnTrimestreInfo(TTrimestre['4_1'], this.loginService.getTrimestresAvailables().includes(TTrimestre['4_1']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['4_2'], this.loginService.getTrimestresAvailables().includes(TTrimestre['4_2']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['4_3'], this.loginService.getTrimestresAvailables().includes(TTrimestre['4_3']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);


    //5 años
    btnTrimestre = new BtnTrimestreInfo(TTrimestre['5_1'], this.loginService.getTrimestresAvailables().includes(TTrimestre['5_1']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['5_2'], this.loginService.getTrimestresAvailables().includes(TTrimestre['5_2']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);

    btnTrimestre = new BtnTrimestreInfo(TTrimestre['5_3'], this.loginService.getTrimestresAvailables().includes(TTrimestre['5_3']));
    this.buttonsTrimestresForTemplate.push(btnTrimestre);
  }

  getName():string{
    return this.loginService.getName();
  }

  onBtnTrimestreClicked(info:BtnTrimestreInfo){
    
    this.appState.setTrimestre(info.trimestre);

    this.navigateTo("menu");
  }

  onClickBackButton(){
    console.log("Click back");

    window.location.href = 'https://badabam.santillana.es';
  }

  getVersionForTemplate():string{
    return this.appState.getVersion();
  }
}
