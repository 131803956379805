import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { MaterialComplementarioViewComponent } from './views/material-complementario-view/material-complementario-view.component';
import { BtnPestanyaComponent } from './components/btn-pestanya/btn-pestanya.component';
import { MenuPestanyasComponent } from './components/menu-pestanyas/menu-pestanyas.component';
import { FrameForBitsComponent } from './components/frame-for-bits/frame-for-bits.component';
import { FrameForCuentosComponent } from './components/frame-for-cuentos/frame-for-cuentos.component';
import { BtnCuentoThumbComponent } from './components/btn-cuento-thumb/btn-cuento-thumb.component';

@NgModule({
  declarations: [MaterialComplementarioViewComponent, BtnPestanyaComponent, MenuPestanyasComponent, FrameForBitsComponent, FrameForCuentosComponent, BtnCuentoThumbComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports:[MaterialComplementarioViewComponent]
})
export class MaterialComplementarioModule { }
