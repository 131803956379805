import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ContainerComponent } from '../../../../shared-games/components/container/container.component';


@Component({
  selector: 'app-container-series',
  templateUrl: './container-series.component.html',
  styleUrls: ['./container-series.component.css']
})
export class ContainerSeriesComponent extends ContainerComponent implements OnInit {

  constructor() { 
    super();
  }
}
