<button (click)="onClick()" 
    (mousedown)="onDown()"
    (mouseleave)="onLeave()"
    (mouseover)="onOver()"
    [style.transform]="getTransformForTemplate()"   
    [style.background-color]="getBackgroundColorForTemplate()"
    [style.background-image]="getButtonBackgroundImageForTemplate()"
    [style.opacity]="opacityForTemplate">
    <img src="./assets/juegos/slot.png">
    <div class="frontContainer">
        <p>{{btnInfo.title}}</p>
        <div class="containerStars">
            <app-lingote></app-lingote>
            <app-lingote></app-lingote>
            <app-lingote></app-lingote>
        </div>
    </div>
    <div *ngIf="showAnilloAnimadoForTemplate" class="anillo_animado"
        [style.background-image]="getAnilloBackgroundImageForTemplate()"
        [style.background-position]="getAnilloBackgroundPositionForTemplate()">
    </div>
    <div *ngIf="!showAnilloAnimadoForTemplate" class="anillo"
        [style.background-image]="getAnilloBackgroundImageForTemplate()"
        [style.background-position]="getAnilloBackgroundPositionForTemplate()"
        [style.transform]="'scale(' + getAnilloScaleForTemplate() + ')'"
        [style.transition]="getAnilloTransitionForTemplate()"
        [style.animation]="getAnilloAnimationForTemplate()">
    </div>
</button>

