<canvas #canvas
    [style.top]="top"
    [style.left]="left"
    (mousedown)="onMouseDown($event)"
    (touchstart)="onTouchStart($event)"
    (mouseup)="onMouseUp($event)"
    (touchend)="onTouchEnd($event)"
    (mousemove)="onMouseMove($event)"
    (touchmove)="onTouchMove($event)"
    (mouseleave)="onMouseLeave($event)">
</canvas>  
<app-draggable-text></app-draggable-text>



