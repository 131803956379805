
import { Color } from "../classes/color";

export class CustomButtonInfo {
    
    id:string = "id";
    label:string = "";
    width:number = 100;
    height:number = 100;
    textColor:Color = new Color(255,255,255,1);
    backgroundColor:Color = new Color(255,255,255,1);
    fontSize:number;
    iconUrl:string;

    constructor(id:string, label:string, width:number, heigth:number, textColor:Color, backgroundColor:Color, fontSize:number, iconUrl:string){
        this.id = id;
        this.label = label;
        this.width = width;
        this.height = heigth;
        this.textColor = textColor;
        this.backgroundColor = backgroundColor;
        this.fontSize = fontSize;
        this.iconUrl = iconUrl;
    }
}
