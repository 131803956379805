import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Color } from '../../classes/color';
import { CustomButtonInfo } from '../../models/custom-button-info';
import { SoundPlayerService } from '../../services/sound-player.service';

const kFadeDelay = 200;

@Component({
  selector: 'app-flotante-with-buttons',
  templateUrl: './flotante-with-buttons.component.html',
  styleUrls: ['./flotante-with-buttons.component.css']
})
export class FlotanteWithButtonsComponent implements OnInit {

  @Input() width = 900;
  @Input() height = 400;
  @Output() eventOnButtonClicked = new EventEmitter<CustomButtonInfo>();
  public textToShowForTemplate = "";
  public buttons:CustomButtonInfo[] = [];
  
  private visible = false;
  private opacity = 0;

  constructor(private soundPlayer:SoundPlayerService) { }
  
  ngOnInit(){}

  public show(textToShow:string, buttons:CustomButtonInfo[])
  {
    
    this.buttons = buttons;
    this.textToShowForTemplate = textToShow;
    this.visible = true;
    this.opacity = 1;
  }

  public hide(){
    this.opacity = 0;
    
    setTimeout(() => {
      this.visible = false;
    }, kFadeDelay);
  }

  onClickButton(info:CustomButtonInfo)
  {
    this.eventOnButtonClicked.emit(info);
  }

  getVisibilityForTemplate():string{

    return (this.visible) ? "visible" : "hidden";
  }

  getOpacityForTemplate():number{
    return this.opacity;
  }

}
