import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './classes/in-memory-data-service';
import { SharedModule } from './modules/shared/shared.module';
import { CuadernosTematicosModule } from './modules/cuadernos-tematicos/cuadernos-tematicos.module'
import { CuadernosAdaptacionModule } from './modules/cuadernos-adaptacion/cuadernos-adaptacion.module';
import { CuadernosEntrenamientoModule } from './modules/cuadernos-entrenamiento/cuadernos-entrenamiento.module';
import { FrisoModule } from './modules/friso/friso.module';
import { LibroModule } from './modules/libro/libro.module';
import { JuegosModule } from './modules/juegos/juegos.module';
import { KaraokeModule } from './modules/karaoke/karaoke.module';
import { MaterialComplementarioModule } from './modules/material-complementario/material-complementario.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './views/login/login.component';
import { MaxAnimadoComponent } from './components/login/max-animado/max-animado.component';
import { LisaAnimadaComponent } from './components/login/lisa-animada/lisa-animada.component';
import { TeoAnimadoComponent } from './components/login/teo-animado/teo-animado.component';
import { SelectorTrimestreComponent } from './views/selector-trimestre/selector-trimestre.component';
import { BtnTrimestreComponent } from './components/selector/btn-trimestre/btn-trimestre.component';
import { MenuTrimestreComponent } from './views/menu-trimestre/menu-trimestre.component';
import { BtnTrimestreActivityComponent } from './components/menu/btn-trimestre-activity/btn-trimestre-activity.component';
import { SharedGamesModule } from './modules/shared-games/shared-games.module';
import { Series31Module } from './modules/games/series-3-1/series-3-1.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MaxAnimadoComponent,
    LisaAnimadaComponent,
    TeoAnimadoComponent,
    SelectorTrimestreComponent,
    BtnTrimestreComponent,
    MenuTrimestreComponent,
    BtnTrimestreActivityComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CuadernosTematicosModule,
    CuadernosAdaptacionModule,
    CuadernosEntrenamientoModule,
    LibroModule,
    FrisoModule,
    KaraokeModule,
    JuegosModule,
    MaterialComplementarioModule,
    SharedGamesModule,
    Series31Module,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    HttpClientInMemoryWebApiModule.forRoot(
      InMemoryDataService, { dataEncapsulation: false }
    )
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
