<div class="nav" [style.background-color]="getBackgroundColorForTemplate()">
    <div class="angulo_izquierdo"></div>
    <div class="juegos_title_container" *ngIf="canShowGamesTitle()">
        <p [style.color]="getTitleColorForTemplate()">Juegos</p>
    </div>
    <div class="botonera">
        <button disabled *ngIf="canShowTarjetasButton()" class="menu_tarjetas menu_button" (click)="onBtnTarjetasClicked()">
            <p>{{titleGeneradorTarjetasForTemplate}}</p>
        </button>
        <button *ngIf="canShowTextosButton()" class="menu_textos menu_button" (click)="onBtnTextosClicked()">
            <p>{{titleGeneradorTextosForTemplate}}</p>
        </button>
        <button *ngIf="canShowFrisoButton()" class="menu_friso menu_button" (click)="onBtnFrisoClicked()">
            <p>{{titleFrisoForTemplate}}</p>
        </button>
        <button *ngIf="canShowComplementarioButton()" class="menu_complementario menu_button" (click)="onBtnComplementarioClicked()">
            <p>{{titleMaterialComplementarioForTemplate}}</p>
        </button>
        <button disabled *ngIf="canShowVideoButton()" class="menu_video menu_button" (click)="onBtnVideoClicked()">
            <p>{{titleVideoForTemplate}}</p>
        </button>
        <div *ngIf="canShowDinamizadorButton()" class="dinamizador_container">
            <button class="menu_dinamizador menu_button" (click)="onBtnDinamizadorClicked()">
                <p>{{titleDinamizadorAulaForTemplate}}</p>
            </button>
            <div [ngClass]="getClassForDisableDinamizadorDesplegable()"
                class="dinamizador_desplegable"
                [style.opacity]="getOpacityForDinamizadorDesplegable()">
                <button disabled (click)="onBtnDinamizadorRelojClicked()" class="dinamizador_button dinamizador_button_reloj"></button>
                <button disabled (click)="onBtnDinamizadorPalmasClicked()" class="dinamizador_button dinamizador_button_palmas"></button>
                <button disabled (click)="onBtnDinamizadorSemaforoClicked()" class="dinamizador_button dinamizador_button_semaforo"></button>
                <button disabled (click)="onBtnDinamizadorCaraClicked()" class="dinamizador_button dinamizador_button_cara"></button>
            </div>
        </div>
    </div><!--botonera-->
    <div class="angulo_derecho"></div>
</div>
