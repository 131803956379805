export enum TTopBarStyles {
    "NONE",
    "MENU",
    "TEMATICO",
    "FRISO",
    "JUEGOS",
    "LIBRO",
    "KARAOKE",
    "MATERIAL_COMPLEMENTARIO",
}
