import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TTrimestre } from '../../classes/t-trimestre';
import { Utils } from '../../classes/utils';
import { SoundPlayerService } from '../../services/sound-player.service';

@Component({
  selector: 'app-trimestre-banner',
  templateUrl: './trimestre-banner.component.html',
  styleUrls: ['./trimestre-banner.component.css']
})
export class TrimestreBannerComponent implements OnInit {

  @Input() idTrimestre:TTrimestre = TTrimestre['3_1'];
  @Output() onClickHomeButton = new EventEmitter();

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  getLabel():string{

    let result = "";

    switch(this.idTrimestre)
    {
      case TTrimestre['3_1']: 
      case TTrimestre['4_1']: 
      case TTrimestre['5_1']:
        result = "Primer trimestre";
        break;
      case TTrimestre['3_2']:
      case TTrimestre['4_2']:
      case TTrimestre['5_2']:
        result = "Segundo trimestre";
        break;
      case TTrimestre['3_3']:
      case TTrimestre['4_3']:
      case TTrimestre['5_3']:
        result = "Primer trimestre";
        break;
    }
    return result;
  }

  getColor():string{
    
    let result = "";

    switch(this.idTrimestre)
    {
      case TTrimestre['3_1']:
      case TTrimestre['3_2']:
      case TTrimestre['3_3']:
        result = Utils.colorTitleByAge[0].rgbString;
        break;
      case TTrimestre['4_1']:
      case TTrimestre['4_2']: 
      case TTrimestre['4_3']:
        result = Utils.colorTitleByAge[1].rgbString;
        break;
      case TTrimestre['5_1']:
      case TTrimestre['5_2']: 
      case TTrimestre['5_3']:
        result = Utils.colorTitleByAge[2].rgbString;
        break;
    }
    
    return result;
  }

  onClick(){

    this.soundPlayer.playSoundClick();

    this.onClickHomeButton.emit();
  }

}
