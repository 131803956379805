import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KaraokeComponent } from './views/karaoke/karaoke.component';
import { SharedModule } from '../../modules/shared/shared.module';

@NgModule({
  declarations: [KaraokeComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [KaraokeComponent]
})
export class KaraokeModule { }
