import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TCuadernoFicha } from '../../../shared/classes/t-cuaderno-ficha';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { AppStateService } from '../../../shared/services/app-state.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { BtnAdaptacionThumbComponent } from '../../components/btn-adaptacion-thumb/btn-adaptacion-thumb.component';
import { BtnAdaptacionThumbInfo } from '../../models/btn-adaptacion-thumb-info'
import { AdaptacionConfigService }  from '../../services/adaptacion-config.service';

@Component({
  selector: 'app-adaptacion-miniaturas',
  templateUrl: './adaptacion-miniaturas.component.html',
  styleUrls: ['./adaptacion-miniaturas.component.css']
})
export class AdaptacionMiniaturasComponent extends BaseViewComponent implements OnInit {

  @ViewChildren(BtnAdaptacionThumbComponent) thumbsControllers!: QueryList<BtnAdaptacionThumbComponent>;
  thumbs:BtnAdaptacionThumbInfo[] = [];

  private blocked:boolean = false;

  constructor(private serverConfig:AdaptacionConfigService, 
    protected navigationManager:NavigationManagerService, 
    protected appState: AppStateService, 
    private storageManager:StorageManagerService) { 
    super(navigationManager, appState);
  }

  ngOnInit(): void {

    super.ngOnInit();
     
    this.getThumbsInfoFromServer();

    //url a la que ir cuando pulsamos en la atras
    this.appState.setLastNavigatedUrl("menu");
  }

  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.TEMATICO;
  }

  getBackgroundColorForTemplate():string{
    
    return this.appState.getPrimaryColorForCurrentAge().rgbString;
  }

  getThumbsInfoFromServer()
  {

    this.serverConfig.getThumbnails().subscribe(
      data => {
        this.thumbs = data;
        
        //Seteamos los favoritos. Esto solo hace falta en la demo, en produccion vendran ya seteados con el servicio
        this.setFavorites();
        
        setTimeout(() => {
          this.showThumbs();
        }, 100);
        
        //console.log("Tengo miniaturas: " + this.thumbs.length);
      }, error => {
        console.log("ERROR desde el componente!");
      }
    );
  }

  setFavorites(){

    this.thumbs.forEach(thumbInfo => {

      thumbInfo.favorite = (this.storageManager.isFavorite(thumbInfo.id, TCuadernoFicha.ADAPTACION)) ? true : false;
    });

  }

  showThumbs()
  {
    let delay = 25;
    let counter = 0;
    this.thumbsControllers.forEach(thumb => {
      setTimeout(() => {
        thumb.showThumb();
      }, delay*counter);
      counter++;
    });
  }

  eventOnClickThumb(info:BtnAdaptacionThumbInfo){

    if(this.blocked) return;

    this.blocked = true;

    let url =  "/visor_adaptacion/" + info.id

    this.navigateTo(url);

    // this.fadeOut();

    // setTimeout(() => {
      
    //   let url =  "/visor_adaptacion/" + info.id
    //   this.router.navigate([url]);
    // }, 300);

  }
}
