import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { TEventFromFichaInteractiva } from '../../../shared/classes/t-event-from-ficha-interactiva';
import { FlotanteFichaInteractivaComponent } from '../../../shared/components/flotante-ficha-interactiva/flotante-ficha-interactiva.component';
import { AppStateService } from '../../../shared/services/app-state.service';
import { CuentoInfo } from '../../models/cuento-info';
import { ComplementarioConfigService } from '../../services/complementario-config.service';
import { BtnCuentoThumbComponent } from '../btn-cuento-thumb/btn-cuento-thumb.component';

const kPathToAssets = "./assets/material_complementario/cuentos/3A_1/miniaturas/";
const kArrayCuentosUrl:string[] = ["", "", "", "", "", ""];
const kArrayCuentosThumbName:string[] = ["3a_min_01.jpg", "3a_min_02.jpg", "3a_min_03.jpg", "3a_min_04.jpg", "3a_min_05.jpg", "3a_min_06.jpg"];
const kArrayCuentosTitles:string[] = ["Title 1", "Title 2", "Title 3", "Title 4", "Title 5", "Title 6"];

@Component({
  selector: 'app-frame-for-cuentos',
  templateUrl: './frame-for-cuentos.component.html',
  styleUrls: ['./frame-for-cuentos.component.css']
})
export class FrameForCuentosComponent implements OnInit {

  @ViewChild(FlotanteFichaInteractivaComponent,  {static: true}) flotanteCuentoController!: FlotanteFichaInteractivaComponent;
  @ViewChildren(BtnCuentoThumbComponent) cuentoThumbsControllers!: QueryList<BtnCuentoThumbComponent>;
  arrayThumbsInfo:CuentoInfo[] = [];
  
  private isShowing: boolean = false;
  private opacity: number = 0;

  constructor(private serverConfig:ComplementarioConfigService, private appState:AppStateService) { }
  
  ngOnInit(): void {

    this.getCuentosInfoFromServer();
  }

  private getCuentosInfoFromServer()
  {

    this.serverConfig.getCuentos(this.appState.getAge(), this.appState.getNumberOfTrimestre(), this.appState.getLanguage()).subscribe(
      data => {
        this.arrayThumbsInfo = data;
        
        setTimeout(() => {
          this.showThumbs();
        }, 100);
        
        //console.log("Tengo miniaturas: " + this.arrayThumbsInfo.length);
      }, error => {
        console.log("ERROR desde el componente!");
      }
    );
  }

  showThumbs()
  {
    let delay = 25;
    let counter = 0;
    this.cuentoThumbsControllers.forEach(thumb => {
      setTimeout(() => {
        thumb.showThumb();
      }, delay*counter);
      counter++;
    });
  }
  private setCuentosInfo(){
    for (let i = 0; i < kArrayCuentosUrl.length; i++) {
      
      let url = kArrayCuentosUrl[i];
      let urlThumb = kArrayCuentosThumbName[i];
      let title = kArrayCuentosTitles[i];
      let info = new CuentoInfo(i.toString(), url, kPathToAssets + urlThumb, title);
      this.arrayThumbsInfo.push(info);
      
    }
  }

  //#region public methods
  public show(){

    this.opacity = 0;
    this.isShowing = true;
    setTimeout(() => {
      this.opacity = 1;
    }, 50);
  }

  public hide()
  {
    this.opacity = 0;
    setTimeout(() => {
      this.isShowing = false;
    }, 300);
  }
  //#endregion public methods

  //#region toques
  onClick(){
    this.flotanteCuentoController.showFlotante();
  }

  onEventFlotanteCuento(eventFicha:TEventFromFichaInteractiva){
    //console.log("Hemos recibido un evento de la ficha interactiva: " + eventFicha);
  }

  eventOnClickCuentoThumb(info:CuentoInfo){

    setTimeout(() => {
      this.flotanteCuentoController.showFlotanteWithUrl(info.url);
      //this.flotanteCuentoController.showFlotante(); 
    }, 100);
  }
  //#endregion

  //#region  template
  getUrlForCuento():string{
    return "./assets/cuentos_mundo/index.html";
  }

  getVisibilityForTemplate():string{
    return (this.isShowing) ? "block" : "none";
  }

  getOpacityForTemplate():number{

    return this.opacity;
  }
  //#endregion

}
