import { Component, OnInit, ViewChild } from '@angular/core';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { AppStateService } from '../../../shared/services/app-state.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { BtnPestanyaInfo } from '../../models/btn-pestanya-info';

import { FrameForBitsComponent } from '../../components/frame-for-bits/frame-for-bits.component';
import { FrameForCuentosComponent } from '../../components/frame-for-cuentos/frame-for-cuentos.component';
import { TBtnPestanya } from '../../classes/t-btn-pestanya';

@Component({
  selector: 'app-material-complementario-view',
  templateUrl: './material-complementario-view.component.html',
  styleUrls: ['./material-complementario-view.component.css']
})
export class MaterialComplementarioViewComponent extends BaseViewComponent implements OnInit {

  @ViewChild(FrameForBitsComponent,  {static: true}) frameBitsController!: FrameForBitsComponent;
  @ViewChild(FrameForCuentosComponent,  {static: true}) frameCuentosController!: FrameForCuentosComponent;

  private showingPestanya = TBtnPestanya.BITS;

  constructor(private soundPlayer:SoundPlayerService, protected appState:AppStateService, protected navigationManager:NavigationManagerService) { 
    super(navigationManager, appState);
  }


  ngAfterViewInit(){
    super.ngAfterViewInit();

    setTimeout(() => {
      this.frameBitsController.showFrame();
    }, 50);
  }

  //#region toques
  eventOnClickBtnPestanya(info:BtnPestanyaInfo)
  {
    
    switch(info.type){
      case TBtnPestanya.BITS:
        this.showingPestanya = TBtnPestanya.BITS;
        this.frameBitsController.showFrame();
        this.frameCuentosController.hide();
        break;
      case TBtnPestanya.CUENTOS:
        this.showingPestanya = TBtnPestanya.CUENTOS;
        this.frameBitsController.hideFrame();
        this.frameCuentosController.show();
        break;
      case TBtnPestanya.RUTINAS:
        this.showingPestanya = TBtnPestanya.RUTINAS;
        this.frameBitsController.hideFrame();
        this.frameCuentosController.show();
        break;
      case TBtnPestanya.CANCIONERO:
        this.showingPestanya = TBtnPestanya.CANCIONERO;
        this.frameBitsController.hideFrame();
        this.frameCuentosController.hide();
        break;
      case TBtnPestanya.AMPLIACION:
        this.showingPestanya = TBtnPestanya.AMPLIACION;
        this.frameBitsController.hideFrame();
        this.frameCuentosController.hide();
        break;
      case TBtnPestanya.CURRICULAR:
        this.showingPestanya = TBtnPestanya.CURRICULAR;
        this.frameBitsController.hideFrame();
        this.frameCuentosController.hide();
        break;
    }
  }
  //#endregion toques

  //#region template
  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.MATERIAL_COMPLEMENTARIO;
  }

  getPageBackgroundcolorForTemplate():string{

    return this.appState.getPrimaryColorForCurrentAge().rgbString;
  }
  //#endregion template

}
