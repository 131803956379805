<div class="pageWrapper" (click)="onClick()"
    [style.opacity]="getOpacityForTemplate()"
    [style.visibility]="getVisibilityForTemplate()">
    <div class="rotulo"></div>
    <div class="personajesContainer" id="personajesContainer">
        <!-- <app-teo-animado  *ngIf="show==0"></app-teo-animado>
        <app-max-animado  *ngIf="show==1"></app-max-animado>
        <app-lina-animado *ngIf="show==2"></app-lina-animado> -->
    </div>
    <footer></footer>
</div>

