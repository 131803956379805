import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { TLanguage } from '../../shared/classes/t-language';
import { CuentoInfo } from '../models/cuento-info';

@Injectable({
  providedIn: 'root'
})
export class ComplementarioConfigService {

  private cuentosUrl = "api/complementario_cuentos";

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  
  constructor(private http:HttpClient) { }

  getCuentos(age:number, trimestre:number, language:TLanguage):Observable<CuentoInfo[]>
  {
    return this.http.get<CuentoInfo[]>(this.cuentosUrl)
      .pipe(
        tap(_ => console.log('cuentos recibidos')),
        catchError(this.handleError<CuentoInfo[]>('getCuentos', []))
      );
  }
  
  private handleError<T>(operation = 'operation', result?: T) {
    console.log("ERROR!!!");
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
