import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';

const kFadeDelay = 200;

@Component({
  selector: 'app-game-info',
  templateUrl: './game-info.component.html',
  styleUrls: ['./game-info.component.css']
})
export class GameInfoComponent implements OnInit {

  @Input() description:string = "";
  @Input() urlNarration:string = "";
  @Output() onClose = new EventEmitter();

  private visible = false;
  private opacity = 0;

  constructor(private soundPlayer:SoundPlayerService) { }
  
  ngOnInit(){}


  public hide(){
    this.opacity = 0;

    this.soundPlayer.stopSoundFromFileName(this.urlNarration);

    setTimeout(() => {
      this.visible = false;
    }, kFadeDelay);
  }

  public show(){
    
    this.visible = true;
    this.opacity = 1;
    if(this.urlNarration != ""){
      this.soundPlayer.playSoundFromUrl(this.urlNarration);
    }
  }

  public isVisible(){
    return this.visible;
  }

  onClick(){

    this.soundPlayer.playSoundClick();

    this.onClose.emit();

    this.hide();
  }

  getVisibilityForTemplate():string{

    return (this.visible) ? "visible" : "hidden";
  }

  getOpacityForTemplate():number{
    
    return this.opacity;
  }

}
