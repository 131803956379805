
<!--boton normal-->
<button [disabled]="isDisabled()" 
    *ngIf="!isDisabled() && !isDesplegableButton()" 
    [style.background-image]="'url(' + getBackgroundImage() + ')'"
    [style.opacity] = "getOpacity()"
    (click)="onClick()">
    <div class="btnContainer"><!--Meto el texto en un div porque en ios no funciona bien el flex sobre un button-->
        <p>{{getTitle()}}</p>
    </div>
</button>

<!--boton con desplegable-->
<div *ngIf="!isDisabled() && isDesplegableButton()" class="containerRecursos">
    <button (click)="onClick()" 
        [disabled]="isDisabled()"
        [style.background-image]="'url(' + getBackgroundImage() + ')'"
        [style.opacity] = "getOpacity()">
        <div class="btnContainer">
            <p>{{getTitle()}}</p>
        </div>
    </button>
    <div class="desplegableRecursos"
        [style.opacity]="opacityRecursosDesplegableForTemplate"
        [style.visibility]="visibilityRecursosDesplegableForTemplate">
        <ng-container *ngFor="let btnRecurso of getRecursos();">
            <app-btn-cuaderno-menu-bar-recurso
                [info]="btnRecurso"
                (clickEvent)="onClickRecurso($event)">
            </app-btn-cuaderno-menu-bar-recurso>
        </ng-container>
    </div>
</div>
