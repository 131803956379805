import { Component, OnInit, ViewChild } from '@angular/core';
import { BtnTrimestreActivityInfo } from '../../classes/btn-trimestre-activity-info';
import { TTopBarStyles } from '../../modules/shared/classes/t-top-bar-styles';
import { TTrimestre } from '../../modules/shared/classes/t-trimestre';
import { TTrimestreActivity } from '../../classes/t-trimestre-activity';
import { AppStateService } from '../../modules/shared/services/app-state.service';
import { SoundPlayerService } from '../../modules/shared/services/sound-player.service';
import { BaseViewComponent } from '../../modules/shared/views/base-view/base-view.component';
import { NavigationManagerService } from '../../modules/shared/services/navigation-manager.service';
import { FlotanteFichaInteractivaComponent } from '../../modules/shared/components/flotante-ficha-interactiva/flotante-ficha-interactiva.component';

const kPathToAssets = "./assets/menu_trimestre/";
const kUrlGeneradorTextos = "./assets/generador_textos/index.html";

//Imagenes para los fondos
const kBgImage_3_1 = "juegos_3a.png";
const kBgImage_3_2 = "";
const kBgImage_3_3 = "";
const kBgImage_4_1 = "";
const kBgImage_4_2 = "";
const kBgImage_4_3 = "";
const kBgImage_5_1 = "";
const kBgImage_5_2 = "";
const kBgImage_5_3 = "";
const kArrayBgImages = [kBgImage_3_1, kBgImage_3_2, kBgImage_3_3, 
  kBgImage_4_1, kBgImage_4_2, kBgImage_4_3, 
  kBgImage_5_1, kBgImage_5_2, kBgImage_5_3];

//Imagenes para el boton de play
const kBtnPlayImage_3_1 = "juegos_btn_3a_frames.png";
const kBtnPlayImage_3_2 = "";
const kBtnPlayImage_3_3 = "";
const kBtnPlayImage_4_1 = "";
const kBtnPlayImage_4_2 = "";
const kBtnPlayImage_4_3 = "";
const kBtnPlayImage_5_1 = "";
const kBtnPlayImage_5_2 = "";
const kBtnPlayImage_5_3 = "";
const kArrayBtnPlayImages = [kBtnPlayImage_3_1, kBtnPlayImage_3_2, kBtnPlayImage_3_3, 
  kBtnPlayImage_4_1, kBtnPlayImage_4_2, kBtnPlayImage_4_3, 
  kBtnPlayImage_5_1, kBtnPlayImage_5_2, kBtnPlayImage_5_3];


@Component({
  selector: 'app-menu-trimestre',
  templateUrl: './menu-trimestre.component.html',
  styleUrls: ['./menu-trimestre.component.css']
})
export class MenuTrimestreComponent extends BaseViewComponent implements OnInit {

  @ViewChild(FlotanteFichaInteractivaComponent,  {static: true}) flotante!: FlotanteFichaInteractivaComponent;
  
  private blockedButtons:boolean = false;
  
  public arrayButtonsActivityInfoForTemplate:BtnTrimestreActivityInfo[] = [];

  constructor(private soundPlayer:SoundPlayerService, protected appState:AppStateService, protected navigationManager:NavigationManagerService) {
    
    super(navigationManager, appState); 
  }

  ngOnInit(): void {

    super.ngOnInit();
    
    this.setButtonsActivities();

    //url a la que ir cuando pulsamos en la atras
    this.appState.setLastNavigatedUrl("selector");
  }

  setButtonsActivities(){
   
    this.arrayButtonsActivityInfoForTemplate.push(new BtnTrimestreActivityInfo(TTrimestreActivity.AVENTURAS));
    this.arrayButtonsActivityInfoForTemplate.push(new BtnTrimestreActivityInfo(TTrimestreActivity.FRISO));
    this.arrayButtonsActivityInfoForTemplate.push(new BtnTrimestreActivityInfo(TTrimestreActivity.ENTRENAMIENTO));
    this.arrayButtonsActivityInfoForTemplate.push(new BtnTrimestreActivityInfo(TTrimestreActivity.TRAZOS));
    this.arrayButtonsActivityInfoForTemplate.push(new BtnTrimestreActivityInfo(TTrimestreActivity.TEMATICO));
    this.arrayButtonsActivityInfoForTemplate.push(new BtnTrimestreActivityInfo(TTrimestreActivity.CANCIONES));
    this.arrayButtonsActivityInfoForTemplate.push(new BtnTrimestreActivityInfo(TTrimestreActivity.ADAPTACION));
  }

  getTrimestreForTemplate():TTrimestre
  {
    return this.appState.getTrimestre();
  }

  getTopBarStyleForTemplate():TTopBarStyles
  {
    return TTopBarStyles.MENU;
  }

  getBackgroundImage(): string{
    
    //accedemos al nombre de la imagen en el array de nombres usando el trimestre como indice
    let bgImageName = kArrayBgImages[this.appState.getTrimestre()];

    let backgroundStyle = "url(" + kPathToAssets + bgImageName + ")";
    return backgroundStyle;
  }

  getBtnPlayImage():string{

    //accedemos al nombre de la imagen en el array de nombres usando el trimestre como indice
    let bgImageName = kArrayBtnPlayImages[this.appState.getTrimestre()];

    let backgroundStyle = "url(" + kPathToAssets + bgImageName + ")";
    return backgroundStyle;
  }

  onBtnJuegosClicked()
  {
    
    if(this.blockedButtons) return;

    this.blockedButtons = true;
    
    this.soundPlayer.playSoundClick();

    this.navigateTo("juegos");
  }

  //Evento que llega desde el componente BtnTrimestreActivity
  onBtnTrimestreActivityClicked(info:BtnTrimestreActivityInfo){

    let urlToNavigate:string = "";

    switch(info.activity){
      case TTrimestreActivity.TEMATICO:
        urlToNavigate = "menu_tematicos";
        break;
      case TTrimestreActivity.ADAPTACION:
        urlToNavigate = "menu_adaptacion";
        break;
      case TTrimestreActivity.ENTRENAMIENTO:
        urlToNavigate = "menu_entrenamiento";
        break;
      case TTrimestreActivity.FRISO:
        urlToNavigate = "friso";
        break;
      case TTrimestreActivity.AVENTURAS:
        urlToNavigate = "libro";
        break;
      case TTrimestreActivity.CANCIONES:
        urlToNavigate = "karaoke";
        break;
    }

    this.navigateTo(urlToNavigate);
  }

  //#region overrides
  protected showGeneradorTextos(){
    this.flotante.showFlotanteWithUrl(kUrlGeneradorTextos);
  }

  //#endregion overrides
}
