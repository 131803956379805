export class CuentoInfo {
    
    id:string = "";
    url:string = "";
    urlThumb:string = "";
    title:string = "";
    enabled:boolean = true;

    constructor(id:string, url:string, urlThumb:string, title:string, enabled:boolean = true){
        this.id = id;
        this.url = url;
        this.urlThumb = urlThumb;
        this.title = title;
        this.enabled = enabled;
    }
}
