<div class="container"
[style.display]="getVisibilityForTemplate()"
[style.opacity]="getOpacityForTemplate()">
    <div class="containerThumbs">
        <ng-container *ngFor="let cuento of arrayThumbsInfo;">
            <app-btn-cuento-thumb
                [info]="cuento"
                (eventOnClick)="eventOnClickCuentoThumb($event)">
            </app-btn-cuento-thumb>
        </ng-container>
    </div>
</div>
<app-flotante-ficha-interactiva
    [urlFicha]="getUrlForCuento()"
    (eventFicha)="onEventFlotanteCuento($event)">
</app-flotante-ficha-interactiva>
