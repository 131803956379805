<!-- <div class="containerAnimacion" (click)="clickAnimacion()">
    <div class="animacionLina"></div>
</div> -->
<div class="pageWrapper">
    <header>
        <div class="btnAtrasContainer" (click)="onClickBackButton()">
            <button class="btnAtras"></button>
        </div>
        <div class="title"></div>
        <div class="userContainer">
            <div class="user"></div>
            <p>{{getName()}}</p>
        </div>
    </header>
    <div class="mainContainer">
        <div class="containerLeft">
            <div class="containerAge">
                <div class="topBar"></div>
                <div class="ageBar">
                    <div class="ageImageContainer"></div>
                </div>
            </div>
            <div class="containerBotonera">
                <ng-container *ngFor="let button of buttonsTrimestresForTemplate; index as i;">
                    <div class="containerTrimestreButton" *ngIf="i < 3">
                        <app-btn-trimestre
                        [info] = "button"
                        (clickEvent) = "onBtnTrimestreClicked($event)">
                        </app-btn-trimestre>
                    </div>
                    
                </ng-container>
            </div>
        </div>
        <div class="containerMiddle">
            <div class="containerAge">
                <div class="topBar"></div>
                <div class="ageBar">
                    <div class="ageImageContainer"></div>
                </div>
            </div>
            <div class="containerBotonera">
                <ng-container *ngFor="let button of buttonsTrimestresForTemplate; index as i;">
                    <div class="containerTrimestreButton" *ngIf="i > 2 && i < 6">
                        <app-btn-trimestre 
                            [info] = "button"
                            (clickEvent) = "onBtnTrimestreClicked($event)">
                    </app-btn-trimestre>
                    </div> 
                </ng-container>
            </div>
        </div>
        <div class="containerRight">
            <div class="containerAge">
                <div class="topBar"></div>
                <div class="ageBar">
                    <div class="ageImageContainer"></div>
                </div>
            </div>
            <div class="containerBotonera">
                <ng-container *ngFor="let button of buttonsTrimestresForTemplate; index as i;">
                    <div class="containerTrimestreButton" *ngIf="i > 5">
                        <app-btn-trimestre 
                            [info] = "button"
                            (clickEvent) = "onBtnTrimestreClicked($event)">
                        </app-btn-trimestre>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <footer>
        <div class="logo"></div>
        <button disabled></button>
    </footer>
    <div class="version">{{getVersionForTemplate()}}</div>
</div>
