import { Component, OnInit } from '@angular/core';
import { Utils } from '../../../modules/shared/classes/utils';

@Component({
  selector: 'app-teo-animado',
  templateUrl: './teo-animado.component.html',
  styleUrls: ['./teo-animado.component.css']
})
export class TeoAnimadoComponent implements OnInit {

  private teoClasess:string = "teo";
  private kTimeSalta = 2071;
  constructor() { }

  ngOnInit(): void {

    this.playRandomAnimation();
  }

  public playRandomAnimation(){

    setTimeout(() => {
      //console.log("Hola, voy a lanzar una animacion random en Teo...");
      this.teoSalta();
    }, Utils.getRandomInt(1000, 5000));
    
    
  }

  teoSalta(){
    this.teoClasess = "teo teoSalta";
    setTimeout(() => {
      this.teoReset();

      //siguiente animacion
      this.playRandomAnimation();
    }, this.kTimeSalta);
  }

  teoReset(){
    this.teoClasess = "teo";
  }

  getClassForTemplate():string
  {
    return this.teoClasess;
  }

}
