<div class="pageWrapper" [style.background-color]="getPageBackgroundcolorForTemplate()">
    <header>
        <app-top-bar
            [style]="getTopBarStyleForTemplate()"
            [navigateBackUrl]="'menu'"
            (clickAtras) = "eventOnClickBack()"
            (clickNavBar) = "eventOnClickNavBar($event)"
            (clickHome) = "eventOnClickHomeButton()">
        </app-top-bar>
    </header>
    <div class="middleContainer">
        <div class="gameButtonsContainer">
            <ng-container *ngFor="let button of arrayGameButtonsInfoForTemplate;">
                <app-btn-juego
                    [btnInfo]="button"
                    (eventOnClick)="onGameButtonClicked($event)">
                </app-btn-juego>
            </ng-container>
        </div>
        <div class="sideBar">
            <app-btn-diploma
                [isDisabled]="isDisabledBtnDiplomaForTemplate()"
                (eventOnClick)="onClickBtnDiploma()">
            </app-btn-diploma>
            <div class="btnIntroContainer">
                <button class="btnIntro" (click)="onClickBtnIntro()"></button>
                <p class="lblBtnIntro">Ver intro</p>
            </div>
            <button class="btnBorrarPremios" (click)="onClikBtnBorrarPremios()">
                <p>Borrar logros</p>
            </button>
        </div>
    </div>
</div>

<!-- Flotantes para video, diploma y confirmacion de borrado de logros-->
<app-flotante-video></app-flotante-video>
<app-flotante-cartela (onClose)="onClickFlotanteCartela()"></app-flotante-cartela>
<app-flotante-delete-logros (eventButtonClicked)="onClickFlotanteDeleteLogros($event)"></app-flotante-delete-logros>

