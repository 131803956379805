import { Component, HostListener, OnInit, QueryList, ViewChild } from '@angular/core';
import { Color } from '../../../shared/classes/color';
import { GamesManagerService } from '../../services/games-manager.service';
import { GamesTopBarComponent } from '../../components/games-top-bar/games-top-bar.component';
import { GameInfoComponent } from '../../components/game-info/game-info.component';
import { Router } from '@angular/router';
import { GameExitoComponent } from '../../components/game-exito/game-exito.component';
import { TGamesId } from '../../classes/t-games-id';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { GameSaveInfo } from '../../../shared/classes/game-save-info';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';

const kFadeDelay = 200;
const kAutoScaleWidth = 1365;
const kAutoScaleHeight = 971;

@Component({
  selector: 'app-games-base-view',
  templateUrl: './games-base-view.component.html',
  styleUrls: ['./games-base-view.component.css']
})
export class GamesBaseViewComponent implements OnInit {

  @ViewChild(GamesTopBarComponent,  {static: true}) topbarController!: GamesTopBarComponent;
  @ViewChild(GameInfoComponent,  {static: true}) gameInfoController!: GameInfoComponent;
  @ViewChild(GameExitoComponent,  {static: true}) gameExitoController!: GameExitoComponent;

  info:string = "";                 //se setea en la superclase
  infoNarrationUrl:string = "";     //se setea en la superclase
  gameId:TGamesId = TGamesId.NONE;  //se setea en la superclase
  title:string = "";                //se setea en la superclase

  protected autoScaleView = true;

  constructor(protected gameManager:GamesManagerService, protected router:Router, protected storageManager:StorageManagerService, protected navigationManager:NavigationManagerService) { 
  }

  @HostListener('window:resize', ['$event']) onResize() {
    
    if(this.autoScaleView){
      this.setAutoScale(this.autoScaleView);
    }
  }

  setAutoScale(enabled:boolean){
    
    if(enabled){
      
      var w = window.innerWidth;
      var h = window.innerHeight;

      var finalScale = h/kAutoScaleHeight;

      if((w/kAutoScaleWidth) > (h/kAutoScaleHeight)){
        finalScale = (h/kAutoScaleHeight);
      }else{
        finalScale = (w/kAutoScaleWidth);
      }
      //console.log("Escala final: " + finalScale);
      (<HTMLElement>document.querySelector('#diaporama')).style.position="relative";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).className="diap_containerItbookEnabled";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.transform = "scale("+finalScale+","+finalScale+")";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.marginLeft= ((w - (kAutoScaleWidth * finalScale))/2)+"px";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.marginTop= ((h - (kAutoScaleHeight * finalScale))/2)+"px";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.overflow="hidden";
    }
    else{
      
      (<HTMLElement>document.querySelector('#diaporamaContainer')).className="diap_containerItbook";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.transform = "none";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.marginLeft= "0";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.marginTop= "0";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.overflow="hidden";
    }
  }

  ngOnInit(): void {

    this.setAutoScale(this.autoScaleView);

    //reset de ronda actual
    this.gameManager.setCurrentRound(1);

    //seteo de sesion para el juego actual
    this.initSessions();
  }

  protected initSessions(){
    
    let saveInfo:GameSaveInfo = this.storageManager.getGameSaveInfo(this.gameId);
    if(saveInfo){
      this.gameManager.setCurrentSession(Math.min(saveInfo.sessionsCompleted+1, this.gameManager.getMaxSessions()));
    }
    else{ //primera sesion
      this.gameManager.setCurrentSession(1);
    }
  }

  protected setBackgroundColor(color:Color){
    
    (<HTMLElement>document.querySelector('#htmlId')).style.backgroundColor=color.rgbString;
  }

  ngAfterViewInit(): void {
    
    this.fadeIn();

    setTimeout(() => {
      this.showInfo();
    }, 1000);
    // (<HTMLElement>document.querySelector('#htmlId')).style.backgroundColor="red";
    // (<HTMLElement>document.querySelector('#htmlId')).style.backgroundImage="url('assets/selector/fondo.png')"
  }

  protected clearRound(){}

  protected setupRound(){}

  protected showNextStar(){

    let starIndex = this.gameManager.getCurrentRound()-1;
    this.topbarController.enableStar(starIndex);
  }

  protected showExito(){

    this.gameExitoController.show();
  }

  protected showInfo(){

    this.gameInfoController.show();
  }

  protected nextRound(){
    
    //incrementamos numero de rondas
    this.gameManager.setCurrentRound(this.gameManager.getCurrentRound() + 1);

    if(this.isGameOver()) //juego terminado
    {

      //incrementamos numero de sesiones
      if(this.gameManager.getCurrentSession() <= this.gameManager.getMaxSessions())
      {
        console.log("Actualizamos sesiones.");

        //si es la primera vez que completamos esta sesion marcamos el juego como recien completado para mostrar el lingote con efecto
        if(this.sessionCompletedForFirstTime()){
          console.log("Primera vez que completamos esta sesion!");
          this.gameManager.lastGameCompleted = this.gameId;
        }
        
        //salvamos sesion actual como completada
        this.save();

        //siguiente sesion. No dejamos incrementar sesiones mas alla del numero maximo de sesiones
        this.gameManager.setCurrentSession(Math.min(this.gameManager.getCurrentSession() + 1, this.gameManager.getMaxSessions()));
      }
      else{
        console.log("Todas las sesiones completadas, no actualizamos nada.");
      }

      //salimos
      setTimeout(() => {
        this.exit();
      }, 1000);
    }
  }

  protected sessionCompletedForFirstTime():boolean{

    let firstTimeCompleted:boolean = true;

    let saveInfo = this.storageManager.getGameSaveInfo(this.gameId);
    
    if(saveInfo){
      
      if(this.gameManager.getCurrentSession() == saveInfo.sessionsCompleted){
        firstTimeCompleted = false;
      }
    }

    return firstTimeCompleted;
  }

  protected save(){

    let saveInfo = new GameSaveInfo(this.gameId, this.gameManager.getCurrentSession());
    this.storageManager.saveGame(saveInfo);
  }

  protected isGameOver(){
    return (this.gameManager.getCurrentRound() > this.gameManager.getMaxRounds());
  }

  protected fadeIn(){
    (<HTMLElement>document.querySelector('#mainView')).style.opacity="1";
  }

  protected fadeOut(){
    (<HTMLElement>document.querySelector('#mainView')).style.opacity="0";
  }

  protected exit(){
    this.fadeOut();

    setTimeout(() => {
      //this.router.navigate(['juegos']);
      this.navigationManager.navigateBack();
    }, kFadeDelay);
  }

  eventOnClicClose(){
    
    this.exit();
  }

  eventOnClicInfo(){
    
    this.showInfo();
  }

  eventOnExitoClose(){

    this.exit();
  }

}
