<button (click)="onClick()"
    [style.width]="info.width + 'px'"
    [style.height]="info.height +'px'"
    [style.background-color]="info.backgroundColor.rgbString">

    <div class="labelContainer">
        <img [src]="info.iconUrl">
        <p [style.color]="info.textColor.rgbString"
            [style.font-size]="info.fontSize + 'px'">
            {{info.label}}
        </p>
    </div>
</button>
