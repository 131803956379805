import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TButtonStatus } from '../../classes/t-button-status';
import { TCuadernosMenuItem } from '../../classes/t-cuadernos-menu-item';
import { BtnCuadernosMenuInfo } from '../../models/btn-cuadernos-menu-info';
import { BtnCuadernosRecursoItemInfo } from '../../models/btn-cuadernos-recurso-item-info';
import { SoundPlayerService } from '../../services/sound-player.service';

const kPathToAssets = "./assets/cuadernos_barra_menu/";
const kOpacityWhenDisabled = 0.3;

@Component({
  selector: 'app-btn-cuadernos-menu',
  templateUrl: './btn-cuadernos-menu.component.html',
  styleUrls: ['./btn-cuadernos-menu.component.css']
})
export class BtnCuadernosMenuComponent implements OnInit {

  @Input() info!:BtnCuadernosMenuInfo;
  @Output() eventOnClick = new EventEmitter<BtnCuadernosMenuInfo>();
  @Output() eventOnClickItemDesplegable = new EventEmitter<BtnCuadernosRecursoItemInfo>();

  opacityRecursosDesplegableForTemplate=0;
  visibilityRecursosDesplegableForTemplate="hidden";

  private backgroundImageForTemplate = "";
  private title = "";
  
  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.setBackgroundImage();
      this.setTitle();
    }, 10);
  }

  private setBackgroundImage(){

    let urlImg = "";
    switch(this.info.type){
      case TCuadernosMenuItem.INTERACTIVA:
        urlImg = "ficha_interactiva.png";
        break;
      case TCuadernosMenuItem.ENTRENAMIENTO:
        urlImg = "ficha_entrenamiento.png";
        break;
      case TCuadernosMenuItem.RECURSOS:
        urlImg = "recursos.png";
        break;
      case TCuadernosMenuItem.CURRICULAR:
        urlImg = "adaptacion_curricular.png";
        break;
      case TCuadernosMenuItem.TRAZOS:
        urlImg = "trazos.png";
        break;
    }

    this.backgroundImageForTemplate = kPathToAssets + urlImg;
  }

  private setTitle(){

    switch(this.info.type){
      case TCuadernosMenuItem.INTERACTIVA:
        this.title = "Ficha Interactiva";
        break;
      case TCuadernosMenuItem.ENTRENAMIENTO:
        this.title = "Ficha de Entrenamiento";
        break;
      case TCuadernosMenuItem.RECURSOS:
        this.title = "Recursos";
        break;
      case TCuadernosMenuItem.CURRICULAR:
        this.title = "Adaptación Curricular";
        break;
      case TCuadernosMenuItem.TRAZOS:
        this.title = "Trazos";
        break;
    }
  }

  public setStatus(status:TButtonStatus){

    this.info.status = status;
  }

  public setUrlAsociada(url:string)
  {
    this.info.urlAsociada = url;
  }

  public setRecursos(recursos:BtnCuadernosRecursoItemInfo[]){
    
    this.info.recursos = recursos;
  }

  public setEntrenamiento(entrenamiento:BtnCuadernosRecursoItemInfo[]){
    
    this.info.recursos = entrenamiento;
  }

  public setTrazos(trazos:BtnCuadernosRecursoItemInfo[]){
    this.info.recursos = trazos;
  }

  public hideDesplegables(){
    
    this.hideDesplegableRecursos();
  }


  //#region  toques
  onClick(){
    
    this.soundPlayer.playSoundClick();

    if((this.info.type == TCuadernosMenuItem.RECURSOS || this.info.type == TCuadernosMenuItem.ENTRENAMIENTO) && this.info.recursos.length>0){
      if(this.opacityRecursosDesplegableForTemplate == 0)
      {
        this.showDesplegableRecursos();
      }
      else{
        this.hideDesplegableRecursos();
      }
    }

    this.eventOnClick.emit(this.info);

  }

  onClickRecurso(info:BtnCuadernosRecursoItemInfo){

    console.log("Hola soy un boton de menu y me he enterado de que hemos pulsado un boton de desplegable: " + info.url);
    this.eventOnClickItemDesplegable.emit(info);
  }

  private showDesplegableRecursos()
  {
    this.opacityRecursosDesplegableForTemplate = 1;
    this.visibilityRecursosDesplegableForTemplate = "visible";
  }

  private hideDesplegableRecursos()
  {
    this.opacityRecursosDesplegableForTemplate = 0;
    this.visibilityRecursosDesplegableForTemplate = "hidden";
  }

  //#endregion toques
  //#region accesos desde el template 

  isDisabled(){
    return (this.info.status == TButtonStatus.DISABLED);
  }

  canShow(){
    return (this.info.status == TButtonStatus.DISABLED || this.info.status == TButtonStatus.ENABLED);
  }

  getBackgroundImage():string{

    return this.backgroundImageForTemplate;
  }

  isDesplegableButton():boolean{

    return (this.info.type == TCuadernosMenuItem.RECURSOS || 
      this.info.type == TCuadernosMenuItem.ENTRENAMIENTO || 
      this.info.type == TCuadernosMenuItem.TRAZOS);
  }

  getTitle():string
  {
    return this.title;
  }

  getRecursos():BtnCuadernosRecursoItemInfo[]{
    
    return this.info.recursos;
  }

  getFichasEntrenamiento():BtnCuadernosRecursoItemInfo[]{
    return [];
  }

  getTrazos():BtnCuadernosRecursoItemInfo[]{
    return [];
  }

  getOpacity():number{

    //Velamos todas las fichas de trazos porque aun no estan disponibles
    return (this.info.type == TCuadernosMenuItem.TRAZOS) ? 0.3 : 1;
    //return (this.info.status == TButtonStatus.ENABLED) ? 1 : kOpacityWhenDisabled;
  }
  //#endregion


}
