import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SoundPlayerService } from '../../../../modules/shared/services/sound-player.service';
import { AppStateService } from '../../services/app-state.service';

const kDelayBeforeNavigate = 300;

@Component({
  selector: 'app-btn-atras',
  templateUrl: './btn-atras.component.html',
  styleUrls: ['./btn-atras.component.css']
})
export class BtnAtrasComponent implements OnInit {

  @Input() urlToNavigate:string = "";
  @Output() eventBtnAtrasClicked = new EventEmitter<string>();

  private blocked:boolean = false;

  constructor(private soundPlayer:SoundPlayerService, private router:Router, private appState:AppStateService) { }

  ngOnInit(): void {
  }

  onClick()
  {

    if(this.blocked)  return;

    this.blocked = true;

    this.soundPlayer.playSoundClick();

    // console.log("Click en boton atras!");
    // console.log("Ahora navegaria a: " + this.urlToNavigate);

    this.eventBtnAtrasClicked.emit(this.urlToNavigate);

    // if(this.urlToNavigate != "")  //si tenemos puesta una direccion de navegacion la usamos y vamos a ella
    // { 

    //   this.appState.setLastNavigatedUrl(this.router.url);

    //   setTimeout(() => {
    //     this.router.navigate([this.urlToNavigate]);
    //   }, kDelayBeforeNavigate);
    // }    
  }

}
