import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TematicosConfigService } from '../../services/tematicos-config.service';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { BtnTematicoThumbInfo } from '../../models/btn-tematico-thumb-info';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { BtnTematicoThumbComponent } from '../../components/btn-tematico-thumb/btn-tematico-thumb.component';
import { AppStateService } from '../../../shared/services/app-state.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { TCuadernoFicha } from '../../../shared/classes/t-cuaderno-ficha';

@Component({
  selector: 'app-tematicos-minituras',
  templateUrl: './tematicos-minituras.component.html',
  styleUrls: ['./tematicos-minituras.component.css']
})
export class TematicosMiniturasComponent extends BaseViewComponent implements OnInit {

  @ViewChildren(BtnTematicoThumbComponent) thumbsControllers!: QueryList<BtnTematicoThumbComponent>;
  thumbs:BtnTematicoThumbInfo[] = [];
  headers:any;

  private blocked:boolean = false;

  constructor(private serverConfig:TematicosConfigService, 
    protected navigationManager:NavigationManagerService, 
    protected appState: AppStateService, 
    protected storageManager: StorageManagerService) {
    
    super(navigationManager, appState);
  }

  ngOnInit(): void {

    super.ngOnInit();
    
    this.getThumbsInfoFromServer();

    //url a la que ir cuando pulsamos en la atras
    this.appState.setLastNavigatedUrl("menu");
  }

  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.TEMATICO;
  }

  getThumbsInfoFromServer()
  {

    this.serverConfig.getThumbnails().subscribe(
      data => {
        this.thumbs = data;
        
        //Seteamos los favoritos. Esto solo hace falta en la demo, en produccion vendran ya seteados con el servicio
        this.setFavorites();

        setTimeout(() => {
          this.showThumbs();
        }, 100);
        
        //console.log("Tengo miniaturas: " + this.thumbs.length);
      }, error => {
        console.log("ERROR desde el componente!");
      }
    );

    // this.blogPostService.getBlogPost(this.route.snapshot.paramMap.get('id')).subscribe(blogPost => {
    //         this.blogPost = blogPost;
    //     }, error => {
    //         console.error(error);
    //     });


    // this.serverConfig.getThumbnails()
    // .subscribe(resp => { // la respuesta es de tipo `HttpResponse<Config>`
    //   // mostrar las cabeceras de la petición
    //   const keys = resp.headers.keys();
    //   this.headers = keys.map(key => `${key}: ${resp.headers.get(key)}`);
    //   // Acceder al body directamente, que es de tipo Config.
      
    //   //this.thumbs = { ... resp.body };
    //   console.log(this.thumbs.length);
    //   console.log('resp: ' + JSON.stringify(resp.body));
    //   console.log(this.headers);
    // });
  }

  setFavorites(){

    this.thumbs.forEach(thumbInfo => {

      thumbInfo.favorite = (this.storageManager.isFavorite(thumbInfo.id, TCuadernoFicha.TEMATICO)) ? true : false;
    });

  }

  showThumbs()
  {
    let delay = 25;
    let counter = 0;
    this.thumbsControllers.forEach(thumb => {
      setTimeout(() => {
        thumb.showThumb();
      }, delay*counter);
      counter++;
    });
  }

  eventOnClickThumb(info:BtnTematicoThumbInfo){

    if(this.blocked) return;

    this.blocked = true;

    let url =  "/visor_tematicos/" + info.id

    this.navigateTo(url);
  }
  

}
