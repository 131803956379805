export class GameSaveInfo {
    idGame:number = 0;
    sessionsCompleted:number = 0;

    constructor(id:number, sessions:number = 0)
    {
        this.idGame = id;
        this.sessionsCompleted = sessions;
    }
}
