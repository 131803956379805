import { InMemoryDbService } from "angular-in-memory-web-api";

export class InMemoryDataService implements InMemoryDbService {

    createDb(){
        const tematico_thumbs = [
            {id:"f001", title:"Ficha 1", index:0, urlThumb:"01_min.jpg", favorite:true, enabled:true},
            {id:"f002", title:"Ficha 2", index:1, urlThumb:"02_min.jpg", favorite:false, enabled:true},
            {id:"f003", title:"Ficha 3", index:2, urlThumb:"03_min.jpg", favorite:true, enabled:true},
            {id:"f004", title:"Ficha 4", index:3, urlThumb:"04_min.jpg", favorite:false, enabled:true},
            {id:"f005", title:"Ficha 5", index:4, urlThumb:"05_min.jpg", favorite:false, enabled:true},
            {id:"f006", title:"Ficha 6", index:5, urlThumb:"06_min.jpg", favorite:true, enabled:true},
            {id:"f007", title:"Ficha 7", index:6, urlThumb:"07_min.jpg", favorite:false, enabled:true},
            {id:"f008", title:"Ficha 8", index:7, urlThumb:"08_min.jpg", favorite:false, enabled:true},
            {id:"f009", title:"Ficha 9", index:8, urlThumb:"09_min.jpg", favorite:false, enabled:true},
            {id:"f010", title:"Ficha 10", index:9, urlThumb:"10_min.jpg", favorite:false, enabled:true},
            {id:"f011", title:"Ficha 11", index:10, urlThumb:"11_min.jpg", favorite:false, enabled:false},
            {id:"f012", title:"Ficha 12", index:11, urlThumb:"12_min.jpg", favorite:false, enabled:false},
            {id:"f013", title:"Ficha 13", index:12, urlThumb:"13_min.jpg", favorite:false, enabled:false},
            {id:"f014", title:"Ficha 14", index:13, urlThumb:"14_min.jpg", favorite:false, enabled:false},
            {id:"f015", title:"Ficha 15", index:14, urlThumb:"15_min.jpg", favorite:false, enabled:false},
            {id:"f016", title:"Ficha 16", index:15, urlThumb:"16_min.jpg", favorite:false, enabled:false},
            {id:"f017", title:"Ficha 17", index:16, urlThumb:"17_min.jpg", favorite:false, enabled:false},
            {id:"f018", title:"Ficha 18", index:17, urlThumb:"18_min.jpg", favorite:false, enabled:false},
            {id:"f019", title:"Ficha 19", index:18, urlThumb:"19_min.jpg", favorite:false, enabled:false},
            {id:"f020", title:"Ficha 20", index:19, urlThumb:"20_min.jpg", favorite:false, enabled:false}
        ];

        const tematico_fichas =[
            {id:"f001", title:"Ficha 1", urlImg:"01.jpg", urlImgReverse:"01_2.jpg", urlFicha:"./assets/fichas/tematicos/3A_01/01/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:true},
            {id:"f002", title:"Ficha 2", urlImg:"02.jpg", urlImgReverse:"02_2.jpg", urlFicha:"./assets/fichas/tematicos/3A_01/02/index.html", urlsCuadernosEntrenamiento:[], favorite:true, 
                recursos:[{url:"urlRecurso", name:"¿Que parte del cuerpo suena?", type:2}, {url:"urlRecurso2", name:"Las partes\n del cuerpo", type:3}], 
                urlCurricular:"", enabled:true},
            {id:"f003", title:"Ficha 3", urlImg:"03.jpg", urlImgReverse:"03_2.jpg", urlFicha:"./assets/fichas/tematicos/3A_01/03/index.html", 
                urlsCuadernosEntrenamiento:[{url:"/visor_entrenamiento/f001", name:"Ficha 1. El círculo", type:5}], favorite:false, 
                recursos:[{url:"urlRecurso", name:"El circulo", type:0}, {url:"urlRecurso2", name:"Las partes\nde la cara", type:2}], 
                urlCurricular:"", enabled:true},
            {id:"f004", title:"Ficha 4", urlImg:"04.jpg", urlImgReverse:"04_2.jpg", urlFicha:"./assets/fichas/tematicos/3A_01/04/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:true},
            {id:"f005", title:"Ficha 5", urlImg:"05.jpg", urlImgReverse:"05_2.jpg", urlFicha:"./assets/fichas/tematicos/3A_01/05/index.html", 
                urlsCuadernosEntrenamiento:[{url:"/visor_entrenamiento/f002", name:"Ficha 2. El número 1", type:5}], favorite:true, 
                recursos:[{url:"urlRecurso", name:"El número 1", type:0}], 
                urlCurricular:"./assets/cuadernos_tematicos/pdf/05.pdf", enabled:true},
            {id:"f006", title:"Ficha 6", urlImg:"06.jpg", urlImgReverse:"06_2.jpg", urlFicha:"./assets/fichas/tematicos/3A_01/06/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[{url:"urlRecurso", name:"Veo, pienso, me pregunto", type:1}], urlCurricular:"", enabled:true},
            {id:"f007", title:"Ficha 7", urlImg:"07.jpg", urlImgReverse:"07_2.jpg", urlFicha:"./assets/fichas/tematicos/3A_01/07/index.html", 
                urlsCuadernosEntrenamiento:[{url:"/visor_entrenamiento/f003", name:"Ficha 3. Abierto/cerrado", type:5}], favorite:false, 
                recursos:[{url:"urlRecurso", name:"¿Abierto o cerrado?", type:0}], urlCurricular:"", enabled:true},
            {id:"f008", title:"Ficha 8", urlImg:"08.jpg", urlImgReverse:"08_2.jpg", urlFicha:"./assets/fichas/tematicos/3A_01/08/index.html", 
                urlsCuadernosEntrenamiento:[{url:"/visor_entrenamiento/f004", name:"Ficha 4. Las letras", type:5}], favorite:false, 
                recursos:[], urlCurricular:"", enabled:true},
            {id:"f009", title:"Ficha 9", urlImg:"09.jpg", urlImgReverse:"09_2.jpg", urlFicha:"./assets/fichas/tematicos/3A_01/09/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:true},
            {id:"f010", title:"Ficha 10", urlImg:"10.jpg", urlImgReverse:"10_2.jpg", urlFicha:"./assets/fichas/tematicos/3A_01/10/index.html", 
                urlsCuadernosEntrenamiento:[{url:"/visor_entrenamiento/f005", name:"Ficha 5. Grafomotricidad", type:5}, {url:"/visor_entrenamiento/f006", name:"Ficha 6. El número 1", type:5}], favorite:false, 
                recursos:[{url:"urlRecurso", name:"El número 1", type:0}], urlCurricular:"", enabled:true},
            
            {id:"f011", title:"Ficha 11", urlImg:"11.jpg", urlImgReverse:"11_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:false},
            {id:"f012", title:"Ficha 12", urlImg:"12.jpg", urlImgReverse:"12_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:false},
            {id:"f013", title:"Ficha 13", urlImg:"13.jpg", urlImgReverse:"13_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:false},
            {id:"f014", title:"Ficha 14", urlImg:"14.jpg", urlImgReverse:"14_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:false},
            {id:"f015", title:"Ficha 15", urlImg:"15.jpg", urlImgReverse:"15_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:false},
            {id:"f016", title:"Ficha 16", urlImg:"16.jpg", urlImgReverse:"16_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:false},
            {id:"f017", title:"Ficha 17", urlImg:"17.jpg", urlImgReverse:"17_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:false},
            {id:"f018", title:"Ficha 18", urlImg:"18.jpg", urlImgReverse:"18_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:false},
            {id:"f019", title:"Ficha 19", urlImg:"19.jpg", urlImgReverse:"19_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:false},
            {id:"f020", title:"Ficha 20", urlImg:"20.jpg", urlImgReverse:"20_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlsCuadernosEntrenamiento:[], favorite:false, 
                recursos:[], urlCurricular:"", enabled:false}
        ];

        const adaptacion_thumbs = [
            {id:"f001", title:"Ficha 1", index:0, urlThumb:"01_min.jpg", favorite:true, enabled:true},
            {id:"f002", title:"Ficha 2", index:1, urlThumb:"02_min.jpg", favorite:false, enabled:true},
            {id:"f003", title:"Ficha 3", index:2, urlThumb:"03_min.jpg", favorite:true, enabled:true},
            {id:"f004", title:"Ficha 4", index:3, urlThumb:"04_min.jpg", favorite:false, enabled:true},
            {id:"f005", title:"Ficha 5", index:4, urlThumb:"05_min.jpg", favorite:false, enabled:false},
            {id:"f006", title:"Ficha 6", index:5, urlThumb:"06_min.jpg", favorite:true, enabled:false},
            {id:"f007", title:"Ficha 7", index:6, urlThumb:"07_min.jpg", favorite:false, enabled:false},
            {id:"f008", title:"Ficha 8", index:7, urlThumb:"08_min.jpg", favorite:false, enabled:false}
        ];

        const adaptacion_fichas = [
            {id:"f001", title:"Ficha 1", urlImg:"01.jpg", urlFicha:"./assets/fichas/adaptacion/3A_01/01/index.html", favorite:false, enabled:true},
            {id:"f002", title:"Ficha 2", urlImg:"02.jpg", urlFicha:"./assets/fichas/adaptacion/3A_01/02/index.html", favorite:false, enabled:true},
            {id:"f003", title:"Ficha 3", urlImg:"03.jpg", urlFicha:"./assets/fichas/adaptacion/3A_01/03/index.html", favorite:false, enabled:true},
            {id:"f004", title:"Ficha 4", urlImg:"04.jpg", urlFicha:"./assets/fichas/adaptacion/3A_01/04/index.html", favorite:false, enabled:true},
            {id:"f005", title:"Ficha 5", urlImg:"05.jpg", urlFicha:"", favorite:false, enabled:false},
            {id:"f006", title:"Ficha 6", urlImg:"06.jpg", urlFicha:"", favorite:false, enabled:false},
            {id:"f007", title:"Ficha 7", urlImg:"07.jpg", urlFicha:"", favorite:false, enabled:false},
            {id:"f008", title:"Ficha 8", urlImg:"08.jpg", urlFicha:"", favorite:false, enabled:false}
        ]

        const entrenamiento_thumbs = [
            {id:"f001", title:"Ficha 1", index:0, urlThumb:"01_min.jpg", favorite:true, enabled:true},
            {id:"f002", title:"Ficha 2", index:1, urlThumb:"02_min.jpg", favorite:false, enabled:true},
            {id:"f003", title:"Ficha 3", index:2, urlThumb:"03_min.jpg", favorite:true, enabled:true},
            {id:"f004", title:"Ficha 4", index:3, urlThumb:"04_min.jpg", favorite:false, enabled:true},
            {id:"f005", title:"Ficha 5", index:4, urlThumb:"05_min.jpg", favorite:false, enabled:true},
            {id:"f006", title:"Ficha 6", index:5, urlThumb:"06_min.jpg", favorite:true, enabled:true},
            {id:"f007", title:"Ficha 7", index:6, urlThumb:"07_min.jpg", favorite:false, enabled:false},
            {id:"f008", title:"Ficha 8", index:7, urlThumb:"08_min.jpg", favorite:false, enabled:false},
            {id:"f009", title:"Ficha 9", index:7, urlThumb:"09_min.jpg", favorite:false, enabled:false},
            {id:"f010", title:"Ficha 10", index:7, urlThumb:"10_min.jpg", favorite:false, enabled:false},
            {id:"f011", title:"Ficha 11", index:7, urlThumb:"11_min.jpg", favorite:false, enabled:false},
            {id:"f012", title:"Ficha 12", index:7, urlThumb:"12_min.jpg", favorite:false, enabled:false}
        ];

        const entrenamiento_fichas = [
            {id:"f001", title:"Ficha 1", urlImg:"01.jpg", urlImgReverse:"01_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"", favorite:false, enabled:true},
            {id:"f002", title:"Ficha 2", urlImg:"02.jpg", urlImgReverse:"02_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"", favorite:false, enabled:true},
            {id:"f003", title:"Ficha 3", urlImg:"03.jpg", urlImgReverse:"03_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"", favorite:false, enabled:true},
            {id:"f004", title:"Ficha 4", urlImg:"04.jpg", urlImgReverse:"04_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"", favorite:false, enabled:true},
            {id:"f005", title:"Ficha 5", urlImg:"05.jpg", urlImgReverse:"05_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"fakeUrl", favorite:false, enabled:true},
            {id:"f006", title:"Ficha 6", urlImg:"06.jpg", urlImgReverse:"06_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"fakeUrl", favorite:false, enabled:true},
            {id:"f007", title:"Ficha 7", urlImg:"07.jpg", urlImgReverse:"07_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"", favorite:false, enabled:false},
            {id:"f008", title:"Ficha 8", urlImg:"08.jpg", urlImgReverse:"08_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"", favorite:false, enabled:false},
            {id:"f009", title:"Ficha 9", urlImg:"09.jpg", urlImgReverse:"09_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"", favorite:false, enabled:false},
            {id:"f010", title:"Ficha 10", urlImg:"10.jpg", urlImgReverse:"10_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"", favorite:false, enabled:false},
            {id:"f011", title:"Ficha 11", urlImg:"11.jpg", urlImgReverse:"11_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"", favorite:false, enabled:false},
            {id:"f012", title:"Ficha 12", urlImg:"12.jpg", urlImgReverse:"12_2.jpg", urlFicha:"./assets/fichas/03_01/index.html", urlTrazos:"", favorite:false, enabled:false}
        ]

        const complementario_cuentos = [
            {id:"c001", url:"./assets/cuentos_mundo/index.html", urlThumb:"./assets/material_complementario/cuentos/3A_1/miniaturas/3a_min_01.jpg", title:"La luciérnaga que no\nquería volar", enabled:true},
            {id:"c002", url:"", urlThumb:"./assets/material_complementario/cuentos/3A_1/miniaturas/3a_min_02.jpg", title:"Ubuntu", enabled:false},
            {id:"c003", url:"", urlThumb:"./assets/material_complementario/cuentos/3A_1/miniaturas/3a_min_03.jpg", title:"El paisajista", enabled:false},
            {id:"c004", url:"", urlThumb:"./assets/material_complementario/cuentos/3A_1/miniaturas/3a_min_04.jpg", title:"Las princesas danzarinas", enabled:false},
            {id:"c005", url:"", urlThumb:"./assets/material_complementario/cuentos/3A_1/miniaturas/3a_min_05.jpg", title:"La oveja tigre", enabled:false},
            {id:"c006", url:"", urlThumb:"./assets/material_complementario/cuentos/3A_1/miniaturas/3a_min_06.jpg", title:"Ratón de campo\ny ratón de ciudad", enabled:false},
        ]

        return {tematico_thumbs, tematico_fichas, adaptacion_thumbs, adaptacion_fichas, entrenamiento_thumbs, entrenamiento_fichas, complementario_cuentos};
    }
}
