import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DraggableInfo } from '../../models/draggable-info';
import {CdkDrag, CdkDragDrop, CdkDragEnd, DragRef, Point} from '@angular/cdk/drag-drop';
import { GameItemComponent } from '../game-item/game-item.component';
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-draggable',
  templateUrl: './draggable.component.html',
  styleUrls: ['./draggable.component.css']
})
export class DraggableComponent extends GameItemComponent implements OnInit {

  @Input() info!:DraggableInfo;   
  @ViewChild('draggable') myDraggable!: ElementRef;
  @ViewChild(CdkDrag) draggable!: CdkDrag;
  @Output() endedDragged = new EventEmitter<DraggableComponent>();

  protected screenScale:number = 1;
  private resizeObservable$: Observable<Event>;
  private resizeSubscription$: Subscription;

  constructor() { 
    
    super();

    //Observamos el cambio de tamaño de pantalla para saber como se esta escalando el documento.
    //Hace falta para que el arrastre funcione bien con la app escalada.
    this.resizeObservable$ = fromEvent(window, 'resize');
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      this.setScreenScale();
      //console.log("scale: " + this.screenScale);
    })

    this.setScreenScale();
  }

  private setScreenScale(){

    this.screenScale = (<HTMLElement>document.querySelector('#diaporamaContainer')).getBoundingClientRect().width / (<HTMLElement>document.querySelector('#diaporamaContainer')).offsetWidth;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(() => {
      this.urlImageBackground = this.info.urlImage;
      this.width = this.info.width;
      this.height = this.info.height;
      this.scale = this.info.scale;
    }, 50);
  }

  ngOnDestroy() {
    this.resizeSubscription$.unsubscribe()
  }

  public moveToInitPosition(){
    
    this.draggable.reset();
  }

  Ended(event:CdkDragEnd<DraggableInfo>){
    this.endedDragged.emit(this);
  }

  /*Ajuste de la posicion al arrastrar teniendo en cuenta que podemos tener la venta escalada */
  dragConstrainPoint = (point:Point, dragRef:DragRef) => {
    
    let zoomMoveXDifference = 0; 
    let zoomMoveYDifference = 0;
    //console.log("freeDragPosition dragRef: " + Math.round(dragRef.getFreeDragPosition().x) + " / " + Math.round(dragRef.getFreeDragPosition().y) + ")");
    
    if (this.screenScale != 1) {
     zoomMoveXDifference = (1 - this.screenScale) * dragRef.getFreeDragPosition().x;
     zoomMoveYDifference = (1 - this.screenScale) * dragRef.getFreeDragPosition().y;
    }
    return {
      x: point.x + zoomMoveXDifference,
      y: point.y + zoomMoveYDifference
    };
  }

  /* Ajuste de posicion cuando soltamos en draggable fuera de un container */
  // endDragging(event:CdkDragEnd) {
  //   const elementMoving = event.source.getRootElement();
  //   let elementMovingParent = elementMoving.parentElement;
  //   if(elementMovingParent == null)
  //   {
  //     elementMovingParent = elementMoving;
  //   }
  //   const elementMovingRect: ClientRect = elementMoving.getBoundingClientRect();
  //   // let div = document.getElementById("draggableText");
  //   let xpos =(elementMovingRect.left - elementMovingParent.getBoundingClientRect().left) / this.scale;
  //   elementMoving.style.left = xpos + "px";
  //   let ypos =(elementMovingRect.top - elementMovingParent.getBoundingClientRect().top) /this.scale;
  //   elementMoving.style.top = ypos + "px";
  //   const cdkDrag = event.source as CdkDrag;
  //   cdkDrag.reset();
  // }

}
