import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { AppStateService } from '../../../shared/services/app-state.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { GamesStarsComponent } from '../games-stars/games-stars.component';

@Component({
  selector: 'app-games-top-bar',
  templateUrl: './games-top-bar.component.html',
  styleUrls: ['./games-top-bar.component.css']
})
export class GamesTopBarComponent implements OnInit {

  @Input() title:string = "TITULO";  
  @Output() eventClickClose = new EventEmitter();
  @Output() eventClickInfo = new EventEmitter(); 
  @ViewChild(GamesStarsComponent,  {static: true}) starsController!: GamesStarsComponent;

  backgroundColorForTemplate:string = "";

  constructor(private appState:AppStateService, private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
    
    this.backgroundColorForTemplate = this.appState.getPrimaryColorForCurrentAge().rgbString;
  }

  public enableStar(index:number){
    this.starsController.enableStar(index);
  }

  //#region toques
  onClickClose(){

    this.soundPlayer.playSoundClick();
    this.eventClickClose.emit();
  }

  onClickInfo(){
    this.soundPlayer.playSoundClick();
    this.eventClickInfo.emit();
  }

}
