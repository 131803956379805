import { Component, OnInit } from '@angular/core';
import { SoundDefinition } from '../../../shared/classes/sound-definition';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';

const kStyleClassForStarAnimated = "starAnimada";

@Component({
  selector: 'app-game-star',
  templateUrl: './game-star.component.html',
  styleUrls: ['./game-star.component.css']
})
export class GameStarComponent implements OnInit {

  animationForStar = "none";
  animationStyleClass = "";

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  public enableStar(){
    
    this.soundPlayer.playSoundFromDefinition(SoundDefinition.MINISTAR);
    this.animationStyleClass = kStyleClassForStarAnimated;
  }

}
