import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { TrimestreBannerComponent } from './components/trimestre-banner/trimestre-banner.component';
import { BtnAtrasComponent } from './components/btn-atras/btn-atras.component';
import { BtnLoginComponent } from './components/btn-login/btn-login.component';
import { BaseViewComponent } from './views/base-view/base-view.component';
import { PainterComponent } from './components/painter/painter.component';
import { PainterBarComponent } from './components/painter-bar/painter-bar.component';
import { CuadernosMenuBarComponent } from './components/cuadernos-menu-bar/cuadernos-menu-bar.component';
import { BtnCuadernoMenuBarRecursoComponent } from './components/btn-cuaderno-menu-bar-recurso/btn-cuaderno-menu-bar-recurso.component';
import { FlotanteFichaInteractivaComponent } from './components/flotante-ficha-interactiva/flotante-ficha-interactiva.component';
import { FlotanteVideoComponent } from './components/flotante-video/flotante-video.component';
import { FlotanteCartelaComponent } from './components/flotante-cartela/flotante-cartela.component';
import { FlotanteWithButtonsComponent } from './components/flotante-with-buttons/flotante-with-buttons.component';
import { CustomButtonComponent } from './components/custom-button/custom-button.component';
import { BtnPainterBarColorComponent } from './components/btn-painter-bar-color/btn-painter-bar-color.component';
import { BtnCuadernosMenuComponent } from './components/btn-cuadernos-menu/btn-cuadernos-menu.component';
import { DraggableTextComponent } from './components/draggable-text/draggable-text.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TextFieldModule } from '@angular/cdk/text-field'; 

@NgModule({
  declarations: [NavBarComponent, 
    TopBarComponent, 
    TrimestreBannerComponent, 
    BtnAtrasComponent, 
    BtnLoginComponent, 
    BaseViewComponent, 
    PainterComponent, 
    PainterBarComponent, 
    CuadernosMenuBarComponent, 
    BtnCuadernoMenuBarRecursoComponent, FlotanteFichaInteractivaComponent, FlotanteVideoComponent, FlotanteCartelaComponent, FlotanteWithButtonsComponent, CustomButtonComponent, BtnPainterBarColorComponent, BtnCuadernosMenuComponent, DraggableTextComponent],
  imports: [
    CommonModule,
    RouterModule,
    DragDropModule,
    TextFieldModule
  ],
  exports: [
    TopBarComponent,
    BaseViewComponent, 
    PainterComponent,
    PainterBarComponent,
    CuadernosMenuBarComponent,
    FlotanteFichaInteractivaComponent,
    FlotanteVideoComponent,
    FlotanteCartelaComponent,
    FlotanteWithButtonsComponent,
    DraggableTextComponent
  ]
})
export class SharedModule { }
