import { Component, OnInit } from '@angular/core';
import { AppStateService } from '../../../shared/services/app-state.service';

@Component({
  selector: 'app-game-item',
  templateUrl: './game-item.component.html',
  styleUrls: ['./game-item.component.css']
})
export class GameItemComponent implements OnInit {

  protected urlImageBackground = "";
  protected scale = 1;
  protected opacity = 1;
  protected transition = "";
  protected width = 0;
  protected height = 0;

  constructor() { 
  }

  ngOnInit(): void {
  }

  public setScale(scale:number)
  {
    this.scale = scale;
  }

  public getScale():number{
    return this.scale;
  }

  public setOpacity(opacity:number)
  {
    this.opacity = opacity;
  }

  public getOpacity():number{
    return this.opacity;
  }

  public setTransition(transition:string){
    this.transition = transition;
  }

  public getTransition():string{
    return this.transition;
  }

  public setUrlImgBackground(url:string)
  {
    this.urlImageBackground = url;
  }

  public getUrlImgBackground():string{
    return this.urlImageBackground;
  }

  public setWidth(width:number)
  {
    this.width = width;
  }

  public getWidth():number{
    return this.width;
  }

  public setHeight(height:number)
  {
    this.height = height;
  }

  public getHeight():number{
    return this.height;
  }

  public showWithScale(time:number = 0){

    if(time > 0)
    {
      this.transition = "transform " + time + "s";
    }
    this.scale = 1;
  }

  public hideWithScale(time:number = 0){

    if(time > 0)
    {
      this.transition = "transform " + time + "s";
    }

    this.scale = 0;
  }

  public showWithOpacity(time:number = 0){

    if(time > 0)
    {
      this.transition = "opacity " + time + "s";
    }

    this.opacity = 1;
  }

  public hideWithOpacity(time:number = 0){

    if(time > 0)
    {
      this.transition = "opacity " + time + "s";
    }

    this.opacity = 0;
  }

}
