<div class="gameItem"
    [ngStyle]="{
        'background-image': 'url(' + getUrlImgBackground() + ')',
        'transform': 'scale(' + getScale() + ')',
        'opacity': getOpacity(),
        'width': getWidth() + 'px',
        'height': getHeight() + 'px',
        'transition': getTransition()
    }"
></div>

