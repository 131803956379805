import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { BtnJuegoInfo } from '../../models/btn-juego-info';
import { AppStateService } from '../../../shared/services/app-state.service';
import { LingoteComponent } from '../lingote/lingote.component';
import { SoundDefinition } from '../../../shared/classes/sound-definition';

const kOffsetForAnilloEnabled = -159;
const kAnilloAnimationStyle = "animacionAnilloAzul 2.5s steps(20) forwards";

export enum tAnilloStates{DISABLED, ENABLED, ANIMATED};

@Component({
  selector: 'app-btn-juego',
  templateUrl: './btn-juego.component.html',
  styleUrls: ['./btn-juego.component.css']
})
export class BtnJuegoComponent implements OnInit {

  @Input() btnInfo!:BtnJuegoInfo;
  @Output() eventOnClick = new EventEmitter<BtnJuegoInfo>();
  @ViewChildren(LingoteComponent) lingotesController!: QueryList<LingoteComponent>;

  opacityForTemplate = 1;
  showAnilloAnimadoForTemplate = false;

  private anilloEnabled = false;
  private scaleForTemplate: number = 0;
  private anilloScaleForTemplate:number = 1;
  private anilloTransitionForTemplate:string = "none";
  private anilloAnimationForTemplate:string = "none";
  private anilloStatus:tAnilloStates = tAnilloStates.DISABLED;
  private urlAnilloBackgroundImage = "";
  private anilloBackgroundImagePosition = "";
  
  constructor(private soundPlayer:SoundPlayerService, private appState:AppStateService) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){

    setTimeout(() => {
      if(this.lingotesController == null) console.log("No tengo controladores de lingotes!!!");
      this.setLingotes();
      this.opacityForTemplate = (this.btnInfo.enabled) ? 1:0.3;
    }, 50);
    
  }

  setLingotes(){

    if(this.btnInfo == null) return;

    let lingotes = this.btnInfo.stars;
    let enableWithEffect = this.btnInfo.newLingote;
    
    switch(lingotes)
    { 
      case 0:
        this.setAnilloStatus(tAnilloStates.DISABLED);
        break;
      case 1:
        this.setAnilloStatus(tAnilloStates.DISABLED);
        this.lingotesController.toArray()[0].enableLingote(enableWithEffect);
        break;
      case 2:
        this.setAnilloStatus(tAnilloStates.DISABLED);
        this.lingotesController.toArray()[0].enableLingote();
        this.lingotesController.toArray()[1].enableLingote(enableWithEffect);
        break;
      case 3:
        this.lingotesController.toArray()[0].enableLingote();
        this.lingotesController.toArray()[1].enableLingote();
        this.lingotesController.toArray()[2].enableLingote(enableWithEffect);
        
        //anillo
        if(enableWithEffect){
          this.setAnilloStatus(tAnilloStates.ANIMATED);
          this.soundPlayer.playSoundFromDefinition(SoundDefinition.TACHAN, false, 1, 4);
        }
        else{
          this.setAnilloStatus(tAnilloStates.ENABLED);
        } 
        break;
      default:
        break;
    }
  }

  private setAnilloStatus(status:tAnilloStates){
    
    this.anilloStatus = status;

    switch(this.anilloStatus){
      case tAnilloStates.DISABLED:
        this.urlAnilloBackgroundImage = this.btnInfo.urlImgAnillo;
        this.anilloBackgroundImagePosition = "left center";
        this.showAnilloAnimadoForTemplate = false;
        break;
      case tAnilloStates.ENABLED:
        this.urlAnilloBackgroundImage = this.btnInfo.urlImgAnillo;
        this.anilloBackgroundImagePosition = kOffsetForAnilloEnabled.toString() + "px center";
        this.showAnilloAnimadoForTemplate = false;
        break;
      case tAnilloStates.ANIMATED:
        this.urlAnilloBackgroundImage = this.btnInfo.urlImgAnilloAnimado;        
        this.anilloBackgroundImagePosition = "left center";
        this.showAnilloAnimadoForTemplate = true;
        break;
    }
  }

  public show(){
    
    this.scaleForTemplate = 1;
  }

  public resetLogros(){
    
    this.lingotesController.forEach(lingote => {
      lingote.disableLingote();
    });
    
    this.setAnilloStatus(tAnilloStates.DISABLED);
  }

  onClick(){

    this.soundPlayer.playSoundClick();

    this.scaleForTemplate = 1;
    
    this.eventOnClick.emit(this.btnInfo);
  }

  onDown(){
    
    this.scaleForTemplate = 1.01;
  }

  onLeave(){
    this.scaleForTemplate = 1;
  }

  onOver(){
   // this.scaleForTemplate = 1.01;
  }

  //#region template
  getBackgroundColorForTemplate():string{

    return this.appState.getPrimaryColorForCurrentAge().rgbString;
  }

  getAnilloBackgroundPositionForTemplate():string{
    return this.anilloBackgroundImagePosition;
    let position = (this.anilloEnabled) ? kOffsetForAnilloEnabled + "px center" : "left center";
    return position;
  }

  getButtonBackgroundImageForTemplate():string{
    
    return "url('" + this.btnInfo.urlImg + "')"; 
  }

  getAnilloBackgroundImageForTemplate():string{
    
    return "url('" + this.urlAnilloBackgroundImage + "')"; 
  }

  getTransformForTemplate():string{
    return "scale(" + this.scaleForTemplate + ")";
  }

  getAnilloScaleForTemplate():number{
    return this.anilloScaleForTemplate;
  }

  getAnilloTransitionForTemplate():string{
    return this.anilloTransitionForTemplate;
  }

  getAnilloAnimationForTemplate(){
    return this.anilloAnimationForTemplate;
  }
  //#endregion

}
