import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TEventFromFichaInteractiva } from '../../classes/t-event-from-ficha-interactiva';

@Component({
  selector: 'app-flotante-ficha-interactiva',
  templateUrl: './flotante-ficha-interactiva.component.html',
  styleUrls: ['./flotante-ficha-interactiva.component.css']
})
export class FlotanteFichaInteractivaComponent implements OnInit {
  
  @Input() urlFicha:string = "";
  @Output() eventFicha = new EventEmitter<TEventFromFichaInteractiva>();
  @ViewChild('iframe') iframe!: ElementRef;
  safeUrl!:SafeResourceUrl;

  private isShowingFlotanteForTemplate: boolean = false;
  private opacityFlotanteForTemplate: number = 0;

  constructor(public sanitizer:DomSanitizer) { 
    
    if (window.addEventListener) {
      window.addEventListener("message", this.eventFromFichaReceived.bind(this), false);
    } else {
       (<any>window).attachEvent("onmessage", this.eventFromFichaReceived.bind(this));
    }
  }

  ngOnInit(): void {
    
  }

  eventFromFichaReceived: any = (event: any) =>  {
    
    // console.log("Soy el flotante y he recibido un mensaje: " + event.data);
    this.eventFicha.emit(event.data);

    //Si se ha recibido evento de cerrar, cerramos la ficha
    if(event.data == TEventFromFichaInteractiva.CLOSE)
    {
      this.hideFlotante();
    }
  }

  //#region public methods
  public showFlotante(){

    //mostramos flotante
    this.show();
  }

  public showFlotanteWithUrl(url:string){

    if(url.length == 0) return;

    this.urlFicha = url;
  
    //mostramos flotante
    this.show();
  }

  private show(){

    this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.urlFicha);

    this.opacityFlotanteForTemplate = 0;
    this.isShowingFlotanteForTemplate = true;
    setTimeout(() => {
      this.opacityFlotanteForTemplate = 1;
    }, 50);
  }

  hideFlotante()
  {
    this.opacityFlotanteForTemplate = 0;
    setTimeout(() => {
      this.isShowingFlotanteForTemplate = false;
    }, 300);
  }

  //#endregion

  //#region template
  getVisibilityFlotanteForTemplate():string{
    return (this.isShowingFlotanteForTemplate) ? "visible" : "hidden";
  }

  getOpacityFlotanteForTemplate():number{

    return this.opacityFlotanteForTemplate;
  }

  haveValidUrlToShow():boolean{
    
    return(this.safeUrl != null);
  }
  //#endregion

}
