<div class="pageWrapper"
  [style.opacity]="getOpacityForTemplate()"
  [style.visibility]="getVisibilityForTemplate()">

  <header [style.background-color]="getHeaderBackgroundColorForTemplate()">
    <button class="btnClose" (click)="onCloseClick()"></button>
  </header>
  
  <div class="containerVideo" *ngIf="hasUrl()">
    <video #player width="1280" height="720" oncontextmenu="return false;" controls controlsList="nodownload" autoplay>
      <source [src]="getUrlVideoForTemplate()" type="video/mp4">
      <!-- <source src="movie.ogg" type="video/ogg"> -->
      ¡Sin soporte para el tag video!
    </video> 
  </div>
</div>