import { BtnCuadernosRecursoItemInfo } from "../../shared/models/btn-cuadernos-recurso-item-info";
import { CuadernosFichaInfo } from "../../shared/models/cuadernos-ficha-info";


export class FichaTematicaInfo extends CuadernosFichaInfo {
    
    urlImgReverse:string = "";
    urlsCuadernosEntrenamiento:BtnCuadernosRecursoItemInfo[] = [];
    recursos:BtnCuadernosRecursoItemInfo[] = [];
    urlCurricular:string = "";
}

