import { InvokeFunctionExpr } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BtnTrimestreActivityInfo } from '../../../classes/btn-trimestre-activity-info';
import { TTrimestre } from '../../../modules/shared/classes/t-trimestre';
import { TTrimestreActivity } from '../../../classes/t-trimestre-activity';
import { SoundPlayerService } from '../../../modules/shared/services/sound-player.service';

const kColorTitleAge3 = "rgb(157,198,193)";
const kColorTitleAge4 = "rgb(157,198,193)";
const kColorTitleAge5 = "rgb(157,198,193)";

const kColorDescriptionAge3 = "rgb(131,58,157)";
const kColorDescriptionAge4 = "rgb(131,58,157)";
const kColorDescriptionAge5 = "rgb(131,58,157)";

const kTematicoDescription3_1 = "¿Por qué nos suenan las tripas?";
const kTematicoDescription3_2 = "";
const kTematicoDescription3_3 = "";
const kTematicoDescription4_1 = "";
const kTematicoDescription4_2 = "";
const kTematicoDescription4_3 = "";
const kTematicoDescription5_1 = "";
const kTematicoDescription5_2 = "";
const kTematicoDescription5_3 = "";

const kAventurasDescription3_1 = "El misterio de las tripas rugientes";
const kAventurasDescription3_2 = "";
const kAventurasDescription3_3 = "";
const kAventurasDescription4_1 = "";
const kAventurasDescription4_2 = "";
const kAventurasDescription4_3 = "";
const kAventurasDescription5_1 = "";
const kAventurasDescription5_2 = "";
const kAventurasDescription5_3 = "";

const kArrayTematicoDescriptions = [kTematicoDescription3_1, kTematicoDescription3_2, kTematicoDescription3_3, 
    kTematicoDescription4_1, kTematicoDescription4_2, kTematicoDescription4_3, 
    kTematicoDescription5_1, kTematicoDescription5_2, kTematicoDescription5_3]; 

const kArrayAventurasDescriptions = [kAventurasDescription3_1, kAventurasDescription3_2, kAventurasDescription3_3, 
  kAventurasDescription4_1, kAventurasDescription4_2, kAventurasDescription4_3, 
  kAventurasDescription5_1, kAventurasDescription5_2, kAventurasDescription5_3]; 

const kPathToAssets = "./assets/menu_trimestre/";

//imagenes de fondo para adaptacion
const kBgImageAdaptacion3_1 = "adaptacion.png";
const kBgImageAdaptacion3_2 = "adaptacion.png";
const kBgImageAdaptacion3_3 = "adaptacion.png";
const kBgImageAdaptacion4_1 = "adaptacion.png";
const kBgImageAdaptacion4_2 = "adaptacion.png";
const kBgImageAdaptacion4_3 = "adaptacion.png";
const kBgImageAdaptacion5_1 = "adaptacion.png";
const kBgImageAdaptacion5_2 = "adaptacion.png";
const kBgImageAdaptacion5_3 = "adaptacion.png";
const kArrayBgImagesAdaptacion = [kBgImageAdaptacion3_1, kBgImageAdaptacion3_2, kBgImageAdaptacion3_3,
  kBgImageAdaptacion4_1, kBgImageAdaptacion4_2, kBgImageAdaptacion4_3,
  kBgImageAdaptacion5_1, kBgImageAdaptacion5_2, kBgImageAdaptacion5_3]

//imagenes de fondo para tematico
const kBgImageTematico3_1 = "tematico_3_1.png";
const kBgImageTematico3_2 = "tematico_3_1.png";
const kBgImageTematico3_3 = "tematico_3_1.png";
const kBgImageTematico4_1 = "tematico_3_1.png";
const kBgImageTematico4_2 = "tematico_3_1.png";
const kBgImageTematico4_3 = "tematico_3_1.png";
const kBgImageTematico5_1 = "tematico_3_1.png";
const kBgImageTematico5_2 = "tematico_3_1.png";
const kBgImageTematico5_3 = "tematico_3_1.png";
const kArrayBgImagesTematico = [kBgImageTematico3_1, kBgImageTematico3_2, kBgImageTematico3_3,
  kBgImageTematico4_1, kBgImageTematico4_2, kBgImageTematico4_3,
  kBgImageTematico5_1, kBgImageTematico5_2, kBgImageTematico5_3];

//imagenes de fondo para canciones
const kBgImageCanciones3_1 = "canciones.png";
const kBgImageCanciones3_2 = "canciones.png";
const kBgImageCanciones3_3 = "canciones.png";
const kBgImageCanciones4_1 = "canciones.png";
const kBgImageCanciones4_2 = "canciones.png";
const kBgImageCanciones4_3 = "canciones.png";
const kBgImageCanciones5_1 = "canciones.png";
const kBgImageCanciones5_2 = "canciones.png";
const kBgImageCanciones5_3 = "canciones.png";
const kArrayBgImagesCanciones = [kBgImageCanciones3_1, kBgImageCanciones3_2, kBgImageCanciones3_3,
  kBgImageCanciones4_1, kBgImageCanciones4_2, kBgImageCanciones4_3,
  kBgImageCanciones5_1, kBgImageCanciones5_2, kBgImageCanciones5_3];

//Imagenes fondo entrenamiento
const kBgImageEntrenamiento3_1 = "entrenamiento.png";
const kBgImageEntrenamiento3_2 = "entrenamiento.png";
const kBgImageEntrenamiento3_3 = "entrenamiento.png";
const kBgImageEntrenamiento4_1 = "entrenamiento.png";
const kBgImageEntrenamiento4_2 = "entrenamiento.png";
const kBgImageEntrenamiento4_3 = "entrenamiento.png";
const kBgImageEntrenamiento5_1 = "entrenamiento.png";
const kBgImageEntrenamiento5_2 = "entrenamiento.png";
const kBgImageEntrenamiento5_3 = "entrenamiento.png";
const kArrayBgImagesEntrenamiento = [kBgImageEntrenamiento3_1, kBgImageEntrenamiento3_2, kBgImageEntrenamiento3_3,
  kBgImageEntrenamiento4_1, kBgImageEntrenamiento4_2, kBgImageEntrenamiento4_3,
  kBgImageEntrenamiento5_1, kBgImageEntrenamiento5_2, kBgImageEntrenamiento5_3];

//Imagenes fondo trazos
const kBgImageTrazos3_1 = "trazos.png";
const kBgImageTrazos3_2 = "trazos.png";
const kBgImageTrazos3_3 = "trazos.png";
const kBgImageTrazos4_1 = "trazos.png";
const kBgImageTrazos4_2 = "trazos.png";
const kBgImageTrazos4_3 = "trazos.png";
const kBgImageTrazos5_1 = "trazos.png";
const kBgImageTrazos5_2 = "trazos.png";
const kBgImageTrazos5_3 = "trazos.png";
const kArrayBgImagesTrazos = [kBgImageTrazos3_1, kBgImageTrazos3_2, kBgImageTrazos3_3,
  kBgImageTrazos4_1, kBgImageTrazos4_2, kBgImageTrazos4_3,
  kBgImageTrazos5_1, kBgImageTrazos5_2, kBgImageTrazos5_3];

//Imagenes fondo aventuras
const kBgAventuras3_1 = "libro_3_1.png";
const kBgAventuras3_2 = "libro_3_1.png";
const kBgAventuras3_3 = "libro_3_1.png";
const kBgAventuras4_1 = "libro_3_1.png";
const kBgAventuras4_2 = "libro_3_1.png";
const kBgAventuras4_3 = "libro_3_1.png";
const kBgAventuras5_1 = "libro_3_1.png";
const kBgAventuras5_2 = "libro_3_1.png";
const kBgAventuras5_3 = "libro_3_1.png";
const kArrayBgImagesAventuras = [kBgAventuras3_1, kBgAventuras3_2, kBgAventuras3_3,
  kBgAventuras4_1, kBgAventuras4_2, kBgAventuras4_3,
  kBgAventuras5_1, kBgAventuras5_2, kBgAventuras5_3];

//Imagenes fondo Friso
const kBgImageFriso3_1 = "friso_3_1.png";
const kBgImageFriso3_2 = "friso_3_1.png";
const kBgImageFriso3_3 = "friso_3_1.png";
const kBgImageFriso4_1 = "friso_3_1.png";
const kBgImageFriso4_2 = "friso_3_1.png";
const kBgImageFriso4_3 = "friso_3_1.png";
const kBgImageFriso5_1 = "friso_3_1.png";
const kBgImageFriso5_2 = "friso_3_1.png";
const kBgImageFriso5_3 = "friso_3_1.png";
const kArrayBgImagesFriso = [kBgImageFriso3_1, kBgImageFriso3_2, kBgImageFriso3_3,
  kBgImageFriso4_1, kBgImageFriso4_2, kBgImageFriso4_3, 
  kBgImageFriso5_1, kBgImageFriso5_2, kBgImageFriso5_3];

@Component({
  selector: 'app-btn-trimestre-activity',
  templateUrl: './btn-trimestre-activity.component.html',
  styleUrls: ['./btn-trimestre-activity.component.css']
})
export class BtnTrimestreActivityComponent implements OnInit {

  @Input() info: BtnTrimestreActivityInfo = new BtnTrimestreActivityInfo(TTrimestreActivity.ADAPTACION);
  @Output() clickEvent = new EventEmitter<BtnTrimestreActivityInfo>();
  disabled:Boolean = false;
  opacity:number = 1;

  private blocked:boolean = false;

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
    switch(this.info.activity){
      case TTrimestreActivity.TRAZOS:
        this.disabled = true;
        this.opacity = 0.3;
    }
  }

  getTitle():string{
    switch(this.info.activity){
      case TTrimestreActivity.ADAPTACION:
        return "Periodo de adaptación";
      case TTrimestreActivity.AVENTURAS:
        return "Libro de aventuras";
      case TTrimestreActivity.CANCIONES:
        return "Canciones";
      case TTrimestreActivity.ENTRENAMIENTO:
        return "Cuaderno de entrenamiento";
      case TTrimestreActivity.FRISO:
        return "Friso";
      case TTrimestreActivity.TEMATICO:
        return "Cuaderno temático";
      case TTrimestreActivity.TRAZOS:
        return "Trazos";
    }
  }

  getTitleBackgroundColor():string{
    
    switch(this.info.trimestre)
    {
      case TTrimestre['3_1']:
      case TTrimestre['3_2']:
      case TTrimestre['3_3']:
        return kColorTitleAge3;
      default:
        return kColorTitleAge3;
    }
  }

  getDescriptionColor():string{

    switch(this.info.trimestre)
    {
      case TTrimestre['3_1']:
      case TTrimestre['3_2']:
      case TTrimestre['3_3']:
        return kColorDescriptionAge3;
      default:
          return kColorDescriptionAge3;
    }
  }

  getDescription():string{
    switch(this.info.activity){
      case TTrimestreActivity.AVENTURAS:
        return kArrayAventurasDescriptions[this.info.trimestre];
      case TTrimestreActivity.TEMATICO:
        return kArrayTematicoDescriptions[this.info.trimestre];
      default:
        return "";
    }
  }

  getBackgroundImage():string
  {
    switch(this.info.activity)
    {
      case TTrimestreActivity.ADAPTACION:
        return "url('" + kPathToAssets + kArrayBgImagesAdaptacion[this.info.trimestre] + "')";
      case TTrimestreActivity.AVENTURAS:
        return "url('" + kPathToAssets + kArrayBgImagesAventuras[this.info.trimestre] + "')";
      case TTrimestreActivity.CANCIONES:
        return "url('" + kPathToAssets + kArrayBgImagesCanciones[this.info.trimestre] + "')";
      case TTrimestreActivity.ENTRENAMIENTO:
        return "url('" + kPathToAssets + kArrayBgImagesEntrenamiento[this.info.trimestre] + "')";
      case TTrimestreActivity.FRISO:
        return "url('" + kPathToAssets + kArrayBgImagesFriso[this.info.trimestre] + "')";
      case TTrimestreActivity.TEMATICO:
        return "url('" + kPathToAssets + kArrayBgImagesTematico[this.info.trimestre] + "')";
      case TTrimestreActivity.TRAZOS:
        return "url('" + kPathToAssets + kArrayBgImagesTrazos[this.info.trimestre] + "')";
    }
  }

  onClicked()
  {

    //console.log("Boton trimestre: " + this.info.trimestre);
    if(this.blocked) return;

    this.blocked = true;

    this.soundPlayer.playSoundClick();

    this.clickEvent.emit(this.info);
  }

}
