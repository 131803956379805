
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BtnTrimestreInfo } from '../../../classes/btn-trimestre-info';
import { TTrimestre } from '../../../modules/shared/classes/t-trimestre';
import { SoundPlayerService } from '../../../modules/shared/services/sound-player.service';

const kPathToAssets = "./assets/selector/";

@Component({
  selector: 'app-btn-trimestre',
  templateUrl: './btn-trimestre.component.html',
  styleUrls: ['./btn-trimestre.component.css']
})
export class BtnTrimestreComponent implements OnInit {

  @Input() info: BtnTrimestreInfo = new BtnTrimestreInfo(TTrimestre['3_1'], false);
  @Output() clickEvent = new EventEmitter<BtnTrimestreInfo>();

  constructor(private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {
  }

  getBtnLabel():string{
    
    let lblTrimestre:string = "";

    switch(this.info.trimestre)
    {
      case TTrimestre['3_1']:
      case TTrimestre['4_1']:
      case TTrimestre['5_1']:
        lblTrimestre = "Primer trimestre";
        break;
      case TTrimestre['3_2']:
      case TTrimestre['4_2']:
      case TTrimestre['5_2']:
        lblTrimestre = "Segundo trimestre";
        break;
      case TTrimestre['3_3']:
      case TTrimestre['4_3']:
      case TTrimestre['5_3']:
        lblTrimestre = "Tercer trimestre";
        break;
    }
    
    return lblTrimestre;
  }

  getBtnTitleImage():string{
    
    let imageName:string = "";

    switch(this.info.trimestre)
    {
      case TTrimestre['3_1']:
        imageName = "3a_1.png";
        break;
      case TTrimestre['4_1']:
        imageName = "4a_1.png";
        break;
      case TTrimestre['5_1']:
        imageName = "5a_1.png";
        break;
        break;
      case TTrimestre['3_2']:
        imageName = "3a_2.png";
        break;
      case TTrimestre['4_2']:
        imageName = "4a_2.png";
        break;
      case TTrimestre['5_2']:
        imageName = "5a_2.png";
        break;
        break;
      case TTrimestre['3_3']:
        imageName = "3a_3.png";
        break;
      case TTrimestre['4_3']:
        imageName = "4a_3.png";
        break;
      case TTrimestre['5_3']:
        imageName = "5a_3.png";
        break;
        break;
    }
    
    return kPathToAssets + imageName;
  }

  
  isDisabled():boolean{
    return !this.info.available;
  }

  /*Funcion chapuza que asigna la clase de estilo que colocara la imagen de fondo apropiada dependiendo del boton de que se trate
  Hay que hacer una clase por cada boton porque llevan animaciones diferentes cada uno
  */
  getClassForBackgroundImage():string{
    
    let className="";

    
    switch(this.info.trimestre){
      case TTrimestre['3_1']:
        className = "btnTrimestre3_1";
        break;
      case TTrimestre['3_2']:
        className = "btnTrimestre3_2";
        break;
      case TTrimestre['3_3']:
        className = "btnTrimestre3_3";
        break;
      case TTrimestre['4_1']:
        className = "btnTrimestre4_1";
        break;
      case TTrimestre['4_2']:
        className = "btnTrimestre4_2";
        break;
      case TTrimestre['4_3']:
        className = "btnTrimestre4_3";
        break;
      case TTrimestre['5_1']:
        className = "btnTrimestre5_1";
        break;
      case TTrimestre['5_2']:
        className = "btnTrimestre5_2";
        break;
      case TTrimestre['5_3']:
        className = "btnTrimestre5_3";
        break;
    } 

    return className;
  }

  getLabelColor():string{

    let colorAge:string = "white";
    switch(this.info.trimestre)
    {
      case TTrimestre['3_1']:
      case TTrimestre['3_2']:
      case TTrimestre['3_3']:
        colorAge = "rgb(0,135,124)";
        break;
      case TTrimestre['4_1']:
      case TTrimestre['4_2']:
      case TTrimestre['4_3']:
        colorAge = "rgb(128,55,155)";
        break;
      case TTrimestre['5_1']:
      case TTrimestre['5_2']:
      case TTrimestre['5_3']:
        colorAge = "rgb(222,56,49)";
        break;
    }

    return colorAge;
  }

  onClick()
  {

    this.soundPlayer.playSoundClick();
    this.clickEvent.emit(this.info);
  }

}
