<div class="wrapper">
    <div class="colorsContainer">
        <ng-container *ngFor="let color of arrayColorForTemplate">
            <app-btn-painter-bar-color
                [color]="color"
                (eventOnClick)="eventOnClickButtonColor($event)">
            </app-btn-painter-bar-color>
        </ng-container>
        <div [ngClass]="{'containerEraserSelected':isSelectedEraser(), 'containerEraserUnSelected':!isSelectedEraser()}" >
            <button [ngClass]="{'eraserSelected':isSelectedEraser(), 'eraser':!isSelectedEraser()}" 
                (click)="clickEraser()">
            </button>
        </div>
    </div>
    <div class="separador">
    </div>
    <div class="optionsContainer">
        <div [ngClass]="{'toolContainerButtonSelected':isSelectedTrazoFino(), 'toolContainerButtonUnselected':!isSelectedTrazoFino()}">
            <button [ngClass]="{'trazoFinoSelected':isSelectedTrazoFino(), 'trazoFino':!isSelectedTrazoFino()}" 
                (click)="clickBrushSmall()">
            </button>
        </div>
        <div [ngClass]="{'toolContainerButtonSelected':isSelectedTrazoGrueso(), 'toolContainerButtonUnselected':!isSelectedTrazoGrueso()}">
            <button [ngClass]="{'trazoGruesoSelected':isSelectedTrazoGrueso(), 'trazoGrueso':!isSelectedTrazoGrueso()}" 
                (click)="clickBrushBig()">
            </button>
        </div>
        <!-- <div [ngClass]="{'toolContainerButtonSelected':isSelectedEraser(), 'toolContainerButtonUnselected':!isSelectedEraser()}">
            <button [ngClass]="{'borrarSelected':isSelectedEraser(), 'borrar':!isSelectedEraser()}" 
                (click)="clickEraser()">
            </button>
        </div> -->
        <div [ngClass]="{'toolContainerButtonSelected':isSelectedTextTool(), 'toolContainerButtonUnselected':!isSelectedTextTool()}">
            <button [ngClass]="{'textoSelected':isSelectedTextTool(), 'texto':!isSelectedTextTool()}"
                (click)="clickTextTool()"></button>
        </div>
        <div [ngClass]="{'toolContainerButtonSelected':isSelectedTrash(), 'toolContainerButtonUnselected':!isSelectedTrash()}">
            <button [ngClass]="{'papeleraSelected':isSelectedTrash(), 'papelera':!isSelectedTrash()}" 
            (mousedown)="downTrash()" (mouseup)="upTrash()" (mouseleave)="upTrash()" (click)="clickTrash()"></button>
        </div>
    </div>
    <div class="whiteLine"></div>
</div>
