import { TCuadernoFicha } from "../classes/t-cuaderno-ficha";
import { BtnCuadernosRecursoItemInfo } from "./btn-cuadernos-recurso-item-info";

export class CuadernosFichaInfo {
    id:string = "";
    title:string = "";
    urlImg:string = "";
    urlFicha:string = "";
    favorite:boolean = false;
    enabled:boolean = true;
    // urlImgReverse:string = "";
    // urlsCuadernosEntrenamiento:string[] = [];
    // favorite:boolean = false;
    // recursos:BtnCuadernosRecursoItemInfo[] = [];
}
