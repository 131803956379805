import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LibroComponent } from './views/libro/libro.component';
import { SharedModule } from '../../modules/shared/shared.module';


@NgModule({
  declarations: [LibroComponent],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[LibroComponent]
})
export class LibroModule { }
