import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { AdaptacionMiniaturasComponent } from './views/adaptacion-miniaturas/adaptacion-miniaturas.component';
import { AdaptacionVisorComponent } from './views/adaptacion-visor/adaptacion-visor.component';
import { BtnAdaptacionThumbComponent } from './components/btn-adaptacion-thumb/btn-adaptacion-thumb.component';

@NgModule({
  declarations: [AdaptacionMiniaturasComponent, AdaptacionVisorComponent, BtnAdaptacionThumbComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],
  exports: [AdaptacionVisorComponent, AdaptacionMiniaturasComponent]
})
export class CuadernosAdaptacionModule { }
