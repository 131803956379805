<div class="wrapper"
    [style.opacity]="getOpacityForTemplate()"
    [style.visibility]="getVisibilityForTemplate()">
    <div class="cartela">
        <div class="containerText">
            <p>{{getTextCartelaForTemplate()}}</p>
        </div>
        <div class="containerButtons">
            <button class="btnDelete" (click)="onClick(true)">{{getTextButtonDeleteForTemplate()}}</button>
            <button class="btnNoDelete" (click)="onClick(false)">{{getTextButtonNoDeleteForTemplate()}}</button>
        </div>
    </div>
</div>

