import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppStateService } from '../../../shared/services/app-state.service';
import { SoundPlayerService } from '../../../shared/services/sound-player.service';
import { BtnEntrenamientoThumbInfo } from '../../models/btn-entrenamiento-thumb-info';
import { Utils } from '../../../shared/classes/utils';

const kPathToAssets = "./assets/cuadernos_entrenamiento/miniaturas/";

@Component({
  selector: 'app-btn-entrenamiento-thumb',
  templateUrl: './btn-entrenamiento-thumb.component.html',
  styleUrls: ['./btn-entrenamiento-thumb.component.css']
})
export class BtnEntrenamientoThumbComponent implements OnInit {

  @Input() info:BtnEntrenamientoThumbInfo = new BtnEntrenamientoThumbInfo();
  @Output() clickEvent = new EventEmitter<BtnEntrenamientoThumbInfo>();

  showAsFavorito = false;

  private scaleForTemplate = 0;

  constructor(private appState:AppStateService, private soundPlayer:SoundPlayerService) { }

  ngOnInit(): void {

    this.showAsFavorito = this.info.favorite;
  }

  getBackgroundImage():string
  {

    let pathToThumbImage:string = kPathToAssets + this.info.urlThumb;
    return "url('" + pathToThumbImage + "')";
  }

  getBorderColor():string
  {
  
    return this.appState.getPrimaryColorForCurrentAge().rgbString;
  }

  onBtnClicked(){

    this.soundPlayer.playSoundClick();

    this.clickEvent.emit(this.info);
  }

  //#region template
  getFichaURL():string
  {
    return "/visor_entrenamiento/" + this.info.id;
  }

  getTransformForTemplate():string
  {
    return "scale(" + this.scaleForTemplate + ")";
  }

  getOpacityForTemplate(){
    return (this.info.enabled)? 1:0.3;
  }

  //#endregion

  //#region public methods
  public showThumb(){
    this.scaleForTemplate = 1;
  }
  //#endregion

}
