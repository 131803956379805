import { TButtonStatus } from "../classes/t-button-status";
import { TCuadernosMenuItem } from "../classes/t-cuadernos-menu-item";
import { BtnCuadernosRecursoItemInfo } from "./btn-cuadernos-recurso-item-info";

export class BtnCuadernosMenuInfo {
    
    type:TCuadernosMenuItem = TCuadernosMenuItem.ENTRENAMIENTO;
    status:TButtonStatus = TButtonStatus.ENABLED;
    recursos: BtnCuadernosRecursoItemInfo[] = [];
    urlAsociada:string = "";

    constructor(type:TCuadernosMenuItem, status:TButtonStatus, url="", recursos:BtnCuadernosRecursoItemInfo[] = [])
    {
        this.type = type;
        this.status = status;
        this.urlAsociada = url;
        this.recursos = recursos;
    }
}
