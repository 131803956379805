import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TematicosMiniturasComponent } from './views/tematicos-minituras/tematicos-minituras.component';
import { BtnTematicoThumbComponent } from './components/btn-tematico-thumb/btn-tematico-thumb.component';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import { TematicosVisorComponent } from './views/tematicos-visor/tematicos-visor.component';

@NgModule({
  declarations: [TematicosMiniturasComponent, 
                BtnTematicoThumbComponent, TematicosVisorComponent],
  imports: [
    CommonModule, 
    SharedModule,
    RouterModule
    
  ],
  exports: [TematicosMiniturasComponent, TematicosVisorComponent]
})
export class CuadernosTematicosModule { }
