import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TPainterBrushSize } from '../classes/t-painter-brush-size';

@Injectable({
  providedIn: 'root'
})
export class NavigationManagerService {

  private backUrl:string = "";
  private navigationHistory:string[] = [];

  constructor(private router:Router) { }

  public getBackUrl():string{
    return this.backUrl;
  }

  public setBackUrl(previousUrl:string){
    console.log("Hemos fijado url de retorno: " + previousUrl);
    this.backUrl = "";
    if(this.navigationHistory.length > 0 && previousUrl.length > 0)
    {
      this.navigationHistory[this.navigationHistory.length-1] = previousUrl;
      console.log("Historial: " + this.navigationHistory);
    }
  }

  public getCurrentUrl():string{
    return this.router.url;
  }

  public navigateTo(url:string){

    
    //si estamos en esa url no hacemos nada
    if(!this.canNavigateTo(url)) return;

    this.router.navigate([url]);
    this.navigationHistory.push(url);
    console.log("Historial: " + this.navigationHistory);
  }

  public canNavigateTo(url:string){
   
    return !(this.router.url == url || this.router.url == "/"+url);
  }

  public navigateBack(){
    
    //sino tenemos historial y le damos al boton de atras llevamos a menu del trimestre
    if(this.navigationHistory.length <= 1 ){

      console.log("ERROR: no tengo historial");
      this.navigationHistory = [];  
      this.backUrl = "";
    
      if(this.router.url != "/menu" )
      {
        this.navigationHistory.push("menu");
        this.router.navigate(["/menu"]);
      }
      else{
        this.navigationHistory.push("selector");
        this.router.navigate(["/selector"]);
      }
      return;
    }
    
    //retrocedemos a la url anterior
    this.navigationHistory.splice(this.navigationHistory.length-1, 1);
    console.log("Historial: " + this.navigationHistory);
    this.router.navigate([this.navigationHistory[this.navigationHistory.length-1]]);
  }

  public clearHistory(){
    this.navigationHistory = [];
  }
}
