export enum TNavBarButtons {
    "GENERADOR_TARJETAS",
    "GENERADOR_TEXTOS",
    "MATERIAL_COMPLEMENTARIO",
    "DINAMIZADOR",
    "FRISO",
    "KARAOKE",
    "INSTRUMENTAL",
    "VIDEO",
    "GAMES_TITLE",
    "DINAMIZADOR_PALMAS",
    "DINAMIZADOR_SEMAFORO",
    "DINAMIZADOR_RELOJ",
    "DINAMIZADOR_CARA"
}
