import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TTopBarStyles } from '../../classes/t-top-bar-styles';
import { TNavBarButtons } from '../../classes/t-nav-bar-buttons';
import { TTrimestre } from '../../classes/t-trimestre';
import { AppStateService } from '../../services/app-state.service';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.css']
})
export class TopBarComponent implements OnInit {

  @Input() style:TTopBarStyles = TTopBarStyles.NONE;
  @Input() navigateBackUrl:string = "";
  @Output() clickAtras = new EventEmitter();
  @Output() clickUser = new EventEmitter();
  @Output() clickNavBar = new EventEmitter<TNavBarButtons>();
  @Output() clickHome = new EventEmitter();
  
  constructor(private appState:AppStateService) { }

  ngOnInit(): void {
  }

  getTrimestreForTemplate():TTrimestre{
    
    return this.appState.getTrimestre();
  }

  getNavBarButtonsToShow(){
    switch (this.style) {
      case TTopBarStyles.NONE:
        return [];
      case TTopBarStyles.MENU:
        return [TNavBarButtons.GENERADOR_TARJETAS, TNavBarButtons.GENERADOR_TEXTOS, TNavBarButtons.MATERIAL_COMPLEMENTARIO, TNavBarButtons.DINAMIZADOR];
      case TTopBarStyles.TEMATICO:
        return [TNavBarButtons.FRISO, TNavBarButtons.MATERIAL_COMPLEMENTARIO, TNavBarButtons.DINAMIZADOR];
      case TTopBarStyles.FRISO:
        return [TNavBarButtons.DINAMIZADOR];
      case TTopBarStyles.LIBRO:
        return [TNavBarButtons.DINAMIZADOR];
      case TTopBarStyles.KARAOKE:
        return [TNavBarButtons.DINAMIZADOR];
      case TTopBarStyles.JUEGOS:
        return [TNavBarButtons.GAMES_TITLE];
      case TTopBarStyles.MATERIAL_COMPLEMENTARIO:
        return [TNavBarButtons.DINAMIZADOR];
      default:
        return [];
    }
  }

  getBackgroundColorForTemplate():string{
    
    return this.appState.getPrimaryColorForCurrentAge().rgbString;
  }

  getPreviousUrlForTemplate():string
  {
    return this.navigateBackUrl;
  }

  onBtnAtrasClicked(){

    this.clickAtras.emit();
  }

  onBtnUserClicked(){

    this.clickUser.emit();
  }

  onBtnNavBarClicked(navBarButton:TNavBarButtons){
    
    this.clickNavBar.emit(navBarButton);
  }

  onBtnHomeClicked(){
    this.clickHome.emit();
  }

}
