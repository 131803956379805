import { Component, OnInit } from '@angular/core';
import { Utils } from '../../../modules/shared/classes/utils';

@Component({
  selector: 'app-max-animado',
  templateUrl: './max-animado.component.html',
  styleUrls: ['./max-animado.component.css']
})
export class MaxAnimadoComponent implements OnInit {

  private maxClasses:string = "max";
  private kTimeSaluda = 3210;
  
  constructor() { }

  ngOnInit(): void {

    this.playRandomAnimation();
  }

  public playRandomAnimation(){

    setTimeout(() => {
      //console.log("Hola, voy a lanzar una animacion random en Max...");
      this.maxSaluda();
    }, Utils.getRandomInt(1000, 5000));
    
    
  }

  maxSaluda(){
    this.maxClasses = "max maxSaluda";
    setTimeout(() => {
      this.maxReset();

      //siguiente animacion
      this.playRandomAnimation();
    }, this.kTimeSaluda);
  }

  maxReset(){
    this.maxClasses = "max";
  }

  getClassForTemplate():string
  {
    return this.maxClasses;
  }

}
