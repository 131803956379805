<div class="pageWrapper" [style.background-color]="backgroundColorForTemplate">
    <div class="topBarContainer">
        <app-games-top-bar
            [title]="title"
            (eventClickClose)="eventOnClicClose()"
            (eventClickInfo)="eventOnClicInfo()">
        </app-games-top-bar>
    </div>
    
    <div class="verSerieContainer">
        <button class="verSerie" (click)="onClickVerSeries()"></button>
    </div>
    
    <div class="middleContainer">
        <div class="containers">
            <div class="item" *ngFor="let containerInfo of arrayContainersForTemplate;">
                <app-container-series
                    [info]="containerInfo">
                </app-container-series>
            </div>
        </div>
        <div class="draggables">
            <div class="item" *ngFor="let draggableInfo of arrayDraggablesForTemplate;">
                <app-draggable
                    [info]="draggableInfo"
                    (endedDragged)="onDraggabledEndDrag($event)">
                </app-draggable>
            </div>
        </div>
        
    </div>
</div>
<app-game-exito
    (onClose)="eventOnExitoClose()">
</app-game-exito>
<app-patron
    [urlImagesPatron]="arrayUrlImagesPatron"
    [backgroundColor]="backgroundColorForPatron">
</app-patron>
<app-game-info
    [description]="info"
    [urlNarration]="infoNarrationUrl">
</app-game-info>



