import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TCuadernoFicha } from '../../shared/classes/t-cuaderno-ficha';
import { TLanguage } from '../../shared/classes/t-language';
import { CuadernosFichaInfo } from '../../shared/models/cuadernos-ficha-info';
import { StorageManagerService } from '../../shared/services/storage-manager.service';
import { BtnAdaptacionThumbInfo } from '../models/btn-adaptacion-thumb-info';

@Injectable({
  providedIn: 'root'
})
export class AdaptacionConfigService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private thumbsUrl = "api/adaptacion_thumbs";
  private fichasUrl = "api/adaptacion_fichas";
  private updateFavorito = "api/adaptacion_favorito";

  constructor(private http:HttpClient, private storageManager:StorageManagerService) { }

  getThumbnails():Observable<BtnAdaptacionThumbInfo[]>
  {
    return this.http.get<BtnAdaptacionThumbInfo[]>(this.thumbsUrl)
      .pipe(
        tap(_ => console.log('Datos miniaturas adaptacion recibidos')),
        catchError(this.handleError<BtnAdaptacionThumbInfo[]>('getThumbnails', []))
      );
  }

  getFichas(age:number, trimestre:number, language:TLanguage):Observable<CuadernosFichaInfo[]>
  {
    return this.http.get<CuadernosFichaInfo[]>(this.fichasUrl)
      .pipe(
        tap(_ => console.log('Datos fichas adaptacion recibidos')),
        catchError(this.handleError<CuadernosFichaInfo[]>('getFichas', []))
      );
  }

  getFicha(id:string):Observable<CuadernosFichaInfo>
  {
    return this.http.get<CuadernosFichaInfo>(this.fichasUrl + "/" + id)
      .pipe(
        tap(_ => console.log('Ficha adaptacion recibida')),
        catchError(this.handleError<CuadernosFichaInfo>('getFicha'))
      );
  }

  updateFavorite(info:CuadernosFichaInfo): Observable<any> {

    if(info.favorite){
      this.storageManager.saveFichaToFavorites(info.id, TCuadernoFicha.ADAPTACION);
    }
    else{
      this.storageManager.deleteFichaFromFavorites(info.id, TCuadernoFicha.ADAPTACION);
    }
    
    return this.http.put(this.updateFavorito, info, this.httpOptions)
    .pipe(
      tap(_ => console.log("updateado favorito!")),
      catchError(this.handleError<CuadernosFichaInfo>('updateFavorite'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    //console.log("ERROR!!!");
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
