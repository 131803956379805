import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TLanguage } from '../../shared/classes/t-language';
import { catchError, map, tap } from 'rxjs/operators';
import { FichaEntrenamientoInfo } from '../models/ficha-entrenamiento-info'
import { BtnEntrenamientoThumbInfo } from '../models/btn-entrenamiento-thumb-info';
import { StorageManagerService } from '../../shared/services/storage-manager.service';
import { TCuadernoFicha } from '../../shared/classes/t-cuaderno-ficha';
@Injectable({
  providedIn: 'root'
})
export class EntrenamientoConfigService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private thumbsUrl = "api/entrenamiento_thumbs";
  private fichasUrl = "api/entrenamiento_fichas";
  private updateFavorito = "api/entrenamientos_favorito";

  constructor(private http:HttpClient, private storageManager:StorageManagerService) { }

  getThumbnails():Observable<BtnEntrenamientoThumbInfo[]>
  {
    return this.http.get<BtnEntrenamientoThumbInfo[]>(this.thumbsUrl)
      .pipe(
        tap(_ => console.log('Datos miniaturas adaptacion recibidos')),
        catchError(this.handleError<BtnEntrenamientoThumbInfo[]>('getThumbnails', []))
      );
  }

  getFichas(age:number, trimestre:number, language:TLanguage):Observable<FichaEntrenamientoInfo[]>
  {
    return this.http.get<FichaEntrenamientoInfo[]>(this.fichasUrl)
      .pipe(
        tap(_ => console.log('Datos fichas adaptacion recibidos')),
        catchError(this.handleError<FichaEntrenamientoInfo[]>('getFichas', []))
      );
  }

  getFicha(id:string):Observable<FichaEntrenamientoInfo>
  {
    return this.http.get<FichaEntrenamientoInfo>(this.fichasUrl + "/" + id)
      .pipe(
        tap(_ => console.log('Ficha adaptacion recibida')),
        catchError(this.handleError<FichaEntrenamientoInfo>('getFicha'))
      );
  }

  updateFavorite(info:FichaEntrenamientoInfo): Observable<any> {
    
    if(info.favorite){
      this.storageManager.saveFichaToFavorites(info.id, TCuadernoFicha.ENTRENAMIENTO);
    }
    else{
      this.storageManager.deleteFichaFromFavorites(info.id, TCuadernoFicha.ENTRENAMIENTO);
    }
    
    return this.http.put(this.updateFavorito, info, this.httpOptions)
    .pipe(
      tap(_ => console.log("updateado favorito!")),
      catchError(this.handleError<FichaEntrenamientoInfo>('updateFavorite'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    console.log("ERROR!!!");
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
