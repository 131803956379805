import { Component, EventEmitter, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { TBtnPestanya } from '../../classes/t-btn-pestanya';
import { BtnPestanyaInfo } from '../../models/btn-pestanya-info';
import { BtnPestanyaComponent } from '../btn-pestanya/btn-pestanya.component';

@Component({
  selector: 'app-menu-pestanyas',
  templateUrl: './menu-pestanyas.component.html',
  styleUrls: ['./menu-pestanyas.component.css']
})
export class MenuPestanyasComponent implements OnInit {

  @Output() onClickBtnPestanya = new EventEmitter<BtnPestanyaInfo>();
  @ViewChildren(BtnPestanyaComponent) btnPestanyaControllers!: QueryList<BtnPestanyaComponent>;

  private currentPestanyaSelected!:BtnPestanyaComponent;

  arrayBtnPestanyasForTemplate:BtnPestanyaInfo[] = [new BtnPestanyaInfo(TBtnPestanya.BITS, true),
    new BtnPestanyaInfo(TBtnPestanya.CUENTOS, false, true),
    new BtnPestanyaInfo(TBtnPestanya.RUTINAS, false, false),
    new BtnPestanyaInfo(TBtnPestanya.CANCIONERO, false, false),
    new BtnPestanyaInfo(TBtnPestanya.AMPLIACION, false, false),
    new BtnPestanyaInfo(TBtnPestanya.CURRICULAR, false, false)];
  
    constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(() => {

      //pestanya de bits seleccionada por defecto
      this.currentPestanyaSelected = this.btnPestanyaControllers.toArray()[0];
    }, 10);
  }

  //#region toques
  eventOnClickBtnPestanya(pestanyaClicked: BtnPestanyaComponent){
    
    //si hemos tocado la pestanya seleccionada no hacemos nada
    if(this.currentPestanyaSelected && this.currentPestanyaSelected == pestanyaClicked) return;
    
    //deseleccionamos boton anterior
    if(this.currentPestanyaSelected)
    {
      this.currentPestanyaSelected.disable();
    }

    //seleccionamos nuevo boton
    this.currentPestanyaSelected = pestanyaClicked;
    this.currentPestanyaSelected.enable();
    
    //enviamos evento
    this.onClickBtnPestanya.emit(pestanyaClicked.info);
  }
  //#endregion

}
