import { TGamesId } from "../../shared-games/classes/t-games-id";

export class BtnJuegoInfo {
    url:string = "";
    type:TGamesId = TGamesId.LABERINTOS;
    title:string = "";
    urlImg:string = "";
    stars:number = 0;
    urlImgAnillo:string = "";
    urlImgAnilloAnimado:string = "";
    newLingote:boolean = false; //indica que acabamos de conseguir un lingote para este juego
    enabled:boolean = true;

    constructor(url:string, type:TGamesId, title:string, urlImg:string, stars:number, urlImgAnillo:string, urlImgAnilloAnimado:string, newLingote: boolean=false, enabled:boolean=true){

        this.url = url;
        this.type = type;
        this.title = title;
        this.urlImg = urlImg;
        this.stars = stars;
        this.urlImgAnillo = urlImgAnillo;
        this.urlImgAnilloAnimado = urlImgAnilloAnimado;
        this.newLingote = newLingote;
        this.enabled = enabled;
    }
}
