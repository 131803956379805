<div class="pageWrapper"
    [style.opacity]="getOpacityForTemplate()"
    [style.visibility]="getVisibilityForTemplate()">
    <div class="cartela" 
    [style.width]="width + 'px'"
    [style.height]="height + 'px'">
        <div class="containerText">
            <p>{{textToShowForTemplate}}</p>
        </div>
        <div class="containerButtons">
            <ng-container *ngFor="let button of buttons;">
                <app-custom-button 
                    [info]="button" 
                    (eventOnClick)="onClickButton($event)">
                </app-custom-button>
            </ng-container>
        </div>
    </div>
</div>
