import { TTrimestre } from "../modules/shared/classes/t-trimestre";
import { TTrimestreActivity } from "./t-trimestre-activity";

export class BtnTrimestreActivityInfo {
    activity:TTrimestreActivity;
    trimestre:TTrimestre;

    constructor(activiy:TTrimestreActivity, trimestre:TTrimestre = TTrimestre["3_1"]){
        this.activity = activiy;
        this.trimestre = trimestre;
    }
}
