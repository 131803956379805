import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { TCuadernoFicha } from '../../../shared/classes/t-cuaderno-ficha';
import { TTopBarStyles } from '../../../shared/classes/t-top-bar-styles';
import { AppStateService } from '../../../shared/services/app-state.service';
import { NavigationManagerService } from '../../../shared/services/navigation-manager.service';
import { StorageManagerService } from '../../../shared/services/storage-manager.service';
import { BaseViewComponent } from '../../../shared/views/base-view/base-view.component';
import { BtnEntrenamientoThumbComponent } from '../../components/btn-entrenamiento-thumb/btn-entrenamiento-thumb.component';
import { BtnEntrenamientoThumbInfo } from '../../models/btn-entrenamiento-thumb-info';
import { EntrenamientoConfigService }  from '../../services/entrenamiento-config.service';

@Component({
  selector: 'app-entrenamiento-miniaturas',
  templateUrl: './entrenamiento-miniaturas.component.html',
  styleUrls: ['./entrenamiento-miniaturas.component.css']
})
export class EntrenamientoMiniaturasComponent extends BaseViewComponent implements OnInit {

  @ViewChildren(BtnEntrenamientoThumbComponent) thumbsControllers!: QueryList<BtnEntrenamientoThumbComponent>;
  thumbs:BtnEntrenamientoThumbInfo[] = [];

  private blocked:boolean = false;

  constructor(private serverConfig:EntrenamientoConfigService, 
    protected navigationManager:NavigationManagerService, 
    protected appState: AppStateService, 
    protected storageManager:StorageManagerService) { 
    super(navigationManager, appState);
  }

  ngOnInit(): void {

    super.ngOnInit();
    
    this.getThumbsInfoFromServer();

    //url a la que ir cuando pulsamos en la atras
    this.appState.setLastNavigatedUrl("menu");
  }

  getTopBarStyleForTemplate():TTopBarStyles{

    return TTopBarStyles.TEMATICO;
  }

  getThumbsInfoFromServer()
  {

    this.serverConfig.getThumbnails().subscribe(
      data => {
        this.thumbs = data;
        
        //Seteamos los favoritos. Esto solo hace falta en la demo, en produccion vendran ya seteados con el servicio
        this.setFavorites();
        
        setTimeout(() => {
          this.showThumbs();
        }, 100);
        
        //console.log("Tengo miniaturas: " + this.thumbs.length);
      }, error => {
        console.log("ERROR desde el componente!");
      }
    );
  }

  setFavorites(){

    this.thumbs.forEach(thumbInfo => {

      thumbInfo.favorite = (this.storageManager.isFavorite(thumbInfo.id, TCuadernoFicha.ENTRENAMIENTO)) ? true : false;
    });

  }

  showThumbs()
  {
    let delay = 25;
    let counter = 0;
    this.thumbsControllers.forEach(thumb => {
      setTimeout(() => {
        thumb.showThumb();
      }, delay*counter);
      counter++;
    });
  }

  eventOnClickThumb(info:BtnEntrenamientoThumbInfo){

    if(this.blocked) return;

    this.blocked = true;

    let url =  "/visor_entrenamiento/" + info.id

    this.navigateTo(url);
  }

}
