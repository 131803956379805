
import { Component, HostListener, OnInit } from '@angular/core';
import { Color } from '../../classes/color';
import { TNavBarButtons } from '../../classes/t-nav-bar-buttons';
import { AppStateService } from '../../services/app-state.service';
import { NavigationManagerService } from '../../services/navigation-manager.service';

const kAutoScaleWidth = 1365;
const kAutoScaleHeight = 971;

@Component({
  selector: 'app-base-view',
  templateUrl: './base-view.component.html',
  styleUrls: ['./base-view.component.css']
})
export class BaseViewComponent implements OnInit {

  protected autoScaleView = true;

  constructor(protected navigationManager:NavigationManagerService, protected appState: AppStateService) { 
  
    this.autoScaleView = true;
  }

  @HostListener('window:resize', ['$event']) onResize() {
    
    if(this.autoScaleView){
      this.setAutoScale(this.autoScaleView);
    }
  }

  // @HostListener('window:load', ['$event']) onLoad() {

  //   console.log("OnLoad!!!");
  //   setTimeout(function(){
  //     // This hides the address bar:
  //     console.log("Scroll");
  //     window.scrollTo(0, 1);
  //   }, 100);
  // }

  setAutoScale(enabled:boolean){
  
    if(enabled){
      
      var w = window.innerWidth;
      var h = window.innerHeight;

      var finalScale = h/kAutoScaleHeight;

      if((w/kAutoScaleWidth) > (h/kAutoScaleHeight)){
        finalScale = (h/kAutoScaleHeight);
      }else{
        finalScale = (w/kAutoScaleWidth);
      }
      //console.log("Escala final: " + finalScale);
      // (<HTMLElement>document.querySelector('#diaporama')).style.position="relative";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).className="diap_containerItbookEnabled";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.transform = "scale("+finalScale+","+finalScale+")";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.marginLeft= ((w - (kAutoScaleWidth * finalScale))/2)+"px";
      // (<HTMLElement>document.querySelector('#diaporamaContainer')).style.marginTop= ((h - (kAutoScaleHeight * finalScale))/2)+"px";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.marginTop= "0px";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.overflow="hidden";
    }
    else{
      
      (<HTMLElement>document.querySelector('#diaporamaContainer')).className="diap_containerItbook";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.transform = "none";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.marginLeft= "0";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.marginTop= "0";
      (<HTMLElement>document.querySelector('#diaporamaContainer')).style.overflow="hidden";
    }
  }

  ngOnInit(): void {

    this.setAutoScale(this.autoScaleView);
    this.setBackgroundColor(this.appState.getPrimaryColorForCurrentAge());
  }

  /* Metodos abstractos a definir en los hijos */
  protected showGeneradorTextos(){};
  protected showGeneradorTarjetas(){};

  protected setBackgroundColor(color:Color){
    
    (<HTMLElement>document.querySelector('#htmlId')).style.backgroundColor=color.rgbString;
  }

  ngAfterViewInit(): void {
    
    this.fadeIn();
    // (<HTMLElement>document.querySelector('#htmlId')).style.backgroundColor="red";
    // (<HTMLElement>document.querySelector('#htmlId')).style.backgroundImage="url('assets/selector/fondo.png')"
  }

  fadeIn(){
    (<HTMLElement>document.querySelector('#mainView')).style.opacity="1";
  }

  fadeOut(){
    (<HTMLElement>document.querySelector('#mainView')).style.opacity="0";
  }

  protected navigateTo(url:string, urlToNavigateBack:string = "", delay:number= 300){

    if(!this.navigationManager.canNavigateTo(url)) return;
    
    if(urlToNavigateBack.length > 0)
    {
      this.navigationManager.setBackUrl(urlToNavigateBack);
    }
    
    this.fadeOut();

    setTimeout(() => {
      this.navigationManager.navigateTo(url);
    }, delay);  
  }

  protected navigateBack(delay:number = 300){

    this.fadeOut();

    setTimeout(() => {
      this.navigationManager.navigateBack();
    }, delay);  
    
  }

  eventOnClickBack(){
  
    //this.navigateTo(this.appState.getLastNavigatedUrl());
    this.navigateBack();
  }

  eventOnClickNavBar(navBarButton:TNavBarButtons){

    let route:string = "";
    switch(navBarButton)
    {
      case TNavBarButtons.FRISO:
        this.navigateTo("friso");
        break;
      case TNavBarButtons.MATERIAL_COMPLEMENTARIO:
        this.navigateTo("complementario");
        break;
      case TNavBarButtons.GENERADOR_TEXTOS:
        this.showGeneradorTextos();
        break;
    }
  }

  eventOnClickHomeButton(){

    this.navigationManager.clearHistory();
    this.navigateTo("menu");
  }
}
