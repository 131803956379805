import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder,FormArray, Validators} from '@angular/forms';
import { MaxAnimadoComponent } from '../../components/login/max-animado/max-animado.component';
import { LoginService } from '../../services/login.service';
import { SoundPlayerService } from '../../modules/shared/services/sound-player.service';
import { BaseViewComponent } from '../../modules/shared/views/base-view/base-view.component';
import { AppStateService } from '../../modules/shared/services/app-state.service';
import { NavigationManagerService } from '../../modules/shared/services/navigation-manager.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent extends BaseViewComponent implements OnInit {

  // @ViewChild(MaxAnimadoComponent, {read: false, static:false}) private maxAnimado?: MaxAnimadoComponent; 
  @ViewChild(MaxAnimadoComponent) maxAnimado?:MaxAnimadoComponent;

  formLogin: FormGroup;

  maxClasses: string = "max";
  styleAlertOpacity:string = "0";

  constructor(private formBuilder: FormBuilder, 
    private loginService:LoginService, 
    private soundPlayer:SoundPlayerService, 
    protected appState: AppStateService, 
    protected navigationManager:NavigationManagerService) {
    
    super(navigationManager, appState); 

    this.autoScaleView = false;
   
    this.formLogin = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required]
    });
  }


  ngAfterViewInit(): void {
    
    // (<HTMLElement>document.querySelector('#htmlId')).style.backgroundColor="white";
    // (<HTMLElement>document.querySelector('#htmlId')).style.backgroundImage="url('/assets/login/fondocompleto.png')";

    super.ngAfterViewInit();

  }

  onSubmit(values:any) {
    console.log("Enviar formulario");

    this.soundPlayer.playSoundClick();

    if(this.loginService.login(values.user, values.password))
    {
      console.log("Puede pasar...");
      this.styleAlertOpacity = "0";

      this.navigateTo("selector", "", 500);

      // setTimeout(() =>{
        
      //   this.router.navigate(['selector']);
      // }, 500);
    }
    else
    {
      console.log("No puede pasar!");
      this.styleAlertOpacity = "1";
    }
    //console.log(`Nombre de pais: ${values.nombre}`);
    // this.submitted = true;
    // this.modelEmitter.emit(values);
    // this.formularioPaises.reset();
  }

  onBtnDemoClicked(){
    console.log("Click!");
    this.soundPlayer.playSoundClick();

    this.navigateTo("selector");
  }

  getVersionForTemplate():string{
    return this.appState.getVersion();
  }
  
  get User() {
    return this.formLogin.get('user');
  }

  get Password() {
    return this.formLogin.get('password');
  }

}
