import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GamesBaseViewComponent } from './views/games-base-view/games-base-view.component';
import { DraggableComponent } from './components/draggable/draggable.component';
import { ContainerComponent } from './components/container/container.component';
import { GamesTopBarComponent } from './components/games-top-bar/games-top-bar.component';
import { GamesStarsComponent } from './components/games-stars/games-stars.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GameItemComponent } from './components/game-item/game-item.component';
import { GameStarComponent } from './components/game-star/game-star.component';
import { GameInfoComponent } from './components/game-info/game-info.component';
import { GameExitoComponent } from './components/game-exito/game-exito.component';

@NgModule({
  declarations: [GamesBaseViewComponent, DraggableComponent, ContainerComponent, GamesTopBarComponent, GamesStarsComponent, GameItemComponent, GameStarComponent, GameInfoComponent, GameExitoComponent],
  imports: [
    CommonModule,
    DragDropModule
  ],
  exports: [
    GamesTopBarComponent,
    ContainerComponent,
    DraggableComponent,
    DragDropModule,
    GamesBaseViewComponent,
    GameInfoComponent,
    GameExitoComponent
  ]
})
export class SharedGamesModule { }
