<div class="draggable" #draggable
    [ngStyle]="{
        'background-image': 'url(' + getUrlImgBackground() + ')',
        'transform': 'scale(' + getScale() + ')',
        'opacity': getOpacity(),
        'width': getWidth() + 'px',
        'height': getHeight() + 'px',
        'transition': getTransition()}"
    cdkDrag
    [id]="info.id"
    [cdkDragData]="info"
    (cdkDragEnded)="Ended($event)"
    [cdkDragConstrainPosition]="dragConstrainPoint">
</div>
