<div class="pageWrapper" (click)="onClick()"
    [style.opacity]="getOpacityForTemplate()"
    [style.visibility]="getVisibilityForTemplate()">
    <div class="cartela" 
    [style.width]="width + 'px'"
    [style.height]="height + 'px'">
        <div class="containerText">
            <p>{{textToShowForTemplate}}</p>
        </div>
    </div>
</div>
