import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { EntrenamientoMiniaturasComponent } from './views/entrenamiento-miniaturas/entrenamiento-miniaturas.component';
import { EntrenamientoVisorComponent } from './views/entrenamiento-visor/entrenamiento-visor.component';
import { SharedModule } from '../shared/shared.module';
import { BtnEntrenamientoThumbComponent } from './components/btn-entrenamiento-thumb/btn-entrenamiento-thumb.component';


@NgModule({
  declarations: [EntrenamientoMiniaturasComponent, EntrenamientoVisorComponent, BtnEntrenamientoThumbComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],
  exports: [EntrenamientoMiniaturasComponent, EntrenamientoVisorComponent]
})
export class CuadernosEntrenamientoModule { }
