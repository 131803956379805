import { Component, OnInit } from '@angular/core';
import { Utils } from '../../../modules/shared/classes/utils';

@Component({
  selector: 'app-lisa-animada',
  templateUrl: './lisa-animada.component.html',
  styleUrls: ['./lisa-animada.component.css']
})
export class LisaAnimadaComponent implements OnInit {

  private lisaClasses:string = "lisa";
  private kTimeSaluda = 1785;

  constructor() { }

  ngOnInit(): void {

    this.playRandomAnimation();
  }

  public playRandomAnimation(){

    setTimeout(() => {
      //console.log("Hola, voy a lanzar una animacion random en Lisa...");
      this.lisaSaluda();
    }, Utils.getRandomInt(1000, 5000));
    
    
  }

  lisaSaluda(){
    this.lisaClasses = "lisa lisaSaluda";
    setTimeout(() => {
      this.lisaReset();

      //siguiente animacion
      this.playRandomAnimation();
    }, this.kTimeSaluda);
  }

  lisaReset(){
    this.lisaClasses = "lisa";
  }

  getClassForTemplate():string
  {
    return this.lisaClasses;
  }

}
