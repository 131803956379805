import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { TCuadernoFicha } from '../../shared/classes/t-cuaderno-ficha';
import { TLanguage } from '../../shared/classes/t-language';
import { StorageManagerService } from '../../shared/services/storage-manager.service';
import { BtnTematicoThumbInfo } from '../models/btn-tematico-thumb-info';
import { FichaTematicaInfo } from '../models/ficha-tematica-info';

@Injectable({
  providedIn: 'root'
})
export class TematicosConfigService {

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  private thumbsUrl = "api/tematico_thumbs";
  private fichasUrl = "api/tematico_fichas";
  private updateFavorito = "api/tematico_favorito";

  constructor(private http:HttpClient, private storageManager:StorageManagerService) { }

  // getThumbnails():Observable<ThumbnailInfo[]>
  // {
  //   return this.http.get<ThumbnailInfo[]>(this.thumbsUrl);
  // }

  getThumbnails():Observable<BtnTematicoThumbInfo[]>
  {
    return this.http.get<BtnTematicoThumbInfo[]>(this.thumbsUrl)
      .pipe(
        tap(_ => console.log('Datos miniaturas tematicos recibidos')),
        catchError(this.handleError<BtnTematicoThumbInfo[]>('getThumbnails', []))
      );
  }

  getFichas(age:number, trimestre:number, language:TLanguage):Observable<FichaTematicaInfo[]>
  {

    return this.http.get<FichaTematicaInfo[]>(this.fichasUrl)
      .pipe(
        tap(_ => console.log('Datos fichas tematicas recibidos')),
        catchError(this.handleError<FichaTematicaInfo[]>('getFichas', []))
      );
  }

  getFicha(id:string):Observable<FichaTematicaInfo>
  {
    return this.http.get<FichaTematicaInfo>(this.fichasUrl + "/" + id)
      .pipe(
        tap(_ => console.log('Ficha tematicas recibida')),
        catchError(this.handleError<FichaTematicaInfo>('getFicha'))
      );
  }

  updateFavorite(info:FichaTematicaInfo): Observable<any> {
    
    if(info.favorite){
      this.storageManager.saveFichaToFavorites(info.id, TCuadernoFicha.TEMATICO);
    }
    else{
      this.storageManager.deleteFichaFromFavorites(info.id, TCuadernoFicha.TEMATICO);
    }
    
    return this.http.put(this.updateFavorito, info, this.httpOptions)
    .pipe(
      tap(_ => console.log("updateado favorito!")),
      catchError(this.handleError<FichaTematicaInfo>('updateFavorite'))
    );
  }

  private handleError<T>(operation = 'operation', result?: T) {
    
    return (error: any): Observable<T> => {
  
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      //this.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  public downloadPdfFromUrl(url:string, name:string){
    
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = url;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  // getThumbnails():Observable<HttpResponse<ThumbnailInfo[]>>
  // {
  //   return this.http.get<ThumbnailInfo[]>(this.thumbsUrl, { observe: 'response'});
  // }
}
