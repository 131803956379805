
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SoundPlayerService } from '../../../../shared/services/sound-player.service';

const kFadeDelay = 200;

@Component({
  selector: 'app-patron',
  templateUrl: './patron.component.html',
  styleUrls: ['./patron.component.css']
})
export class PatronComponent implements OnInit {

  @Input() urlImagesPatron:string[] = [];
  @Input() backgroundColor:string = "";
  @Output() onClose = new EventEmitter();
  
  private visible = false;
  private opacity = 0;

  constructor(private soundPlayer:SoundPlayerService) { }
  
  ngOnInit(){}


  public hide(){
    this.opacity = 0;
    
    setTimeout(() => {
      this.visible = false;
    }, kFadeDelay);
  }

  public show(){
    
    this.visible = true;
    this.opacity = 1;
  }

  onClick(){

    this.soundPlayer.playSoundClick();

    this.onClose.emit();

    this.hide();
  }

  getVisibilityForTemplate():string{

    return (this.visible) ? "visible" : "hidden";
  }

  getOpacityForTemplate():number{
    return this.opacity;
  }

}
